
export default [
{
  "id": 3,
  "name": "ＣＢＴ体験版　学科試験",
  "title": "学科試験",
  "no": 1,
  "grade": 3,
  "time": 90
},
{
  "id": 4,
  "name": "ＣＢＴ体験版 実技試験（個人資産相談業務）",
  "title": "個人資産相談業務",
  "no": 2,
  "grade": 3,
  "time": 60
},
{
  "id": 5,
  "name": "ＣＢＴ体験版 実技試験（保険顧客資産相談業務）",
  "title": "保険顧客資産相談業務",
  "no": 2,
  "grade": 3,
  "time": 60
},
{
  "id": 6,
  "name": "ＣＢＴ体験版 実技試験（資産設計提案業務）",
  "title": "資産設計提案業務",
  "no": 2,
  "grade": 3,
  "time": 60
},
{
  "id": 7,
  "name": "第１回目 予想模試  学科試験",
  "title": "学科試験",
  "no": 1,
  "grade": 3,
  "time": 90
},
{
  "id": 8,
  "name": "第１回目 予想模試  実技試験（個人資産相談業務）",
  "title": "個人資産相談業務",
  "no": 2,
  "grade": 3,
  "time": 60
},
{
  "id": 9,
  "name": "第１回目 予想模試  実技試験（保険顧客資産相談業務）",
  "title": "保険顧客資産相談業務",
  "no": 2,
  "grade": 3,
  "time": 60
},
{
  "id": 10,
  "name": "第１回目 予想模試  実技試験（資産設計提案業務）",
  "title": "資産設計提案業務",
  "no": 2,
  "grade": 3,
  "time": 60
},
{
  "id": 11,
  "name": "第２回目 予想模試  学科試験",
  "title": "学科試験",
  "no": 1,
  "grade": 3,
  "time": 90
},
{
  "id": 12,
  "name": "第２回目 予想模試  実技試験（個人資産相談業務）",
  "title": "個人資産相談業務",
  "no": 2,
  "grade": 3,
  "time": 60
},
{
  "id": 13,
  "name": "第２回目 予想模試  実技試験（保険顧客資産相談業務）",
  "title": "保険顧客資産相談業務",
  "no": 2,
  "grade": 3,
  "time": 60
},
{
  "id": 14,
  "name": "第２回目 予想模試  実技試験（資産設計提案業務）",
  "title": "資産設計提案業務",
  "no": 2,
  "grade": 3,
  "time": 60
},
{
  "id": 15,
  "name": "第３回目 予想模試  学科試験",
  "title": "学科試験",
  "no": 1,
  "grade": 3,
  "time": 90
},
{
  "id": 16,
  "name": "第３回目 予想模試  実技試験（個人資産相談業務）",
  "title": "個人資産相談業務",
  "no": 2,
  "grade": 3,
  "time": 60
},
{
  "id": 17,
  "name": "第３回目 予想模試  実技試験（保険顧客資産相談業務）",
  "title": "保険顧客資産相談業務",
  "no": 2,
  "grade": 3,
  "time": 60
},
{
  "id": 18,
  "name": "第３回目 予想模試  実技試験（資産設計提案業務）",
  "title": "資産設計提案業務",
  "no": 2,
  "grade": 3,
  "time": 60
},
]