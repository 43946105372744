<template>
  <div id="app">
    <HeaderAuth  v-if="$route.name == 'home'" />
    <div class="container">
      <Header v-if="$route.name == 'start' || $route.name == 'end'" />
      <HeaderExam v-if="$route.name == 'Exam'" />
      
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import HeaderAuth from "@/components/HeaderAuth.vue";
import Footer from "@/components/Footer.vue";
import HeaderExam from "@/components/HeaderExam.vue";
import BasicUtil from "./services/basicUtil";
@Component({
  components: { Header, Footer, HeaderAuth, HeaderExam }
})
export default class App extends Vue {

  created() {
    
  }

  mounted() {
    
    // const agent = window.navigator.userAgent.toLowerCase();
    // if (agent.indexOf("msie") != -1 || agent.indexOf("trident") != -1) {
    // } else if (agent.indexOf("edg") != -1 || agent.indexOf("edge") != -1) {
    // } else if (agent.indexOf("opr") != -1 || agent.indexOf("opera") != -1) {
    // } else if (agent.indexOf("chrome") != -1) {
    // } else if (agent.indexOf("safari") != -1) {
    //   window.onpopstate = function(event: any) {
    //     window.location.href = "/?error=back";
    //   };
    // } else if (agent.indexOf("firefox") != -1) {
    // } else if (agent.indexOf("opr") != -1 || agent.indexOf("opera") != -1) {
    // }
  }
}
</script>

