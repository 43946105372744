
export default [
    {
        "id": 121,
        "shikenId": 3,
        "no": 1,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "生命保険募集人の登録を受けていないファイナンシャル・プランナーが、ライフプランの相談に来た顧客に対し、生命保険商品の一般的な商品性について説明することは、保険業法において禁止されている。[2023.01]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 122,
        "shikenId": 3,
        "no": 2,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "アルバイトやパートタイマーが、労働者災害補償保険の適用を受けるためには、1週間の所定労働時間が20時間を超えていなければならない。[2023.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 123,
        "shikenId": 3,
        "no": 3,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "老齢厚生年金の繰上げ支給の請求は、老齢基礎年金の繰上げ支給の請求と同時に行わなければならない。[2021.01]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 124,
        "shikenId": 3,
        "no": 4,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "国民年金基金は、加入員自身で掛金を運用するため、その運用実績により将来受け取ることができる年金額が増減する。[2021.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 125,
        "shikenId": 3,
        "no": 5,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "住宅ローンの一部繰上げ返済では、返済期間を変更せずに毎月の返済額を減額する返済額軽減型よりも、毎月の返済額を変更せずに返済期間を短くする期間短縮型のほうが、他の条件が同一である場合、通常、総返済額は少なくなる。[2023.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 126,
        "shikenId": 3,
        "no": 6,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "国内銀行の支店において加入した一時払終身保険は、生命保険契約者保護機構による補償の対象である。[2020.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 127,
        "shikenId": 3,
        "no": 7,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "こども保険（学資保険）において、保険期間中に契約者（＝保険料負担者）である親が死亡した場合、一般に、既払込保険料相当額の死亡保険金が支払われて契約は消滅する。[2022.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 128,
        "shikenId": 3,
        "no": 8,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "変額個人年金保険は、特別勘定の運用実績によって、将来受け取る年金額や死亡給付金額は変動するが、解約返戻金額は変動しない。[2023.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 129,
        "shikenId": 3,
        "no": 9,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "製造した食品が原因で食中毒を発生させ、顧客の身体に損害を与えたことにより、法律上の損害賠償責任を負うことによって被る損害を補償する保険として、生産物賠償責任保険（PL保険）がある。[2201.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 130,
        "shikenId": 3,
        "no": 10,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "がん保険では、一般に、責任開始日前に90日程度の免責期間が設けられており、その期間中にがんと診断されたとしても、がん診断給付金は支払われない。[2022.05]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 131,
        "shikenId": 3,
        "no": 11,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "日本銀行の金融政策の1つである公開市場操作（オペレーション）のうち、国債買入オペは、日本銀行が長期国債（利付国債）を買い入れることによって金融市場から資金を吸収するオペレーションである。[2022.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 132,
        "shikenId": 3,
        "no": 12,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "公社債投資信託は、投資対象に株式をいっさい組み入れることができない。[2021.05]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 133,
        "shikenId": 3,
        "no": 13,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "一般に、残存期間や表面利率（クーポンレート）が同一であれば、格付の高い債券ほど利回りが低く、格付の低い債券ほど利回りが高くなる。[2023.05]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 134,
        "shikenId": 3,
        "no": 14,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "日経平均株価は、東京証券取引所スタンダード市場に上場している代表的な225銘柄を対象として算出される。[2023.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 135,
        "shikenId": 3,
        "no": 15,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "国内銀行に預け入れられた外貨預金は、預金保険制度の保護の対象となる。[2019.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 136,
        "shikenId": 3,
        "no": 16,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "所得税において、交通機関を利用して通勤している給与所得者に対し、勤務先から通常の給与に加算して支払われるべき通勤手当は、最も経済的かつ合理的と認められる運賃等の額で、月額15万円を限度に非課税とされる。[2019.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 137,
        "shikenId": 3,
        "no": 17,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税において、事業的規模で行われている賃貸マンションの貸付による所得は、事業所得となる。[2022.05]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 138,
        "shikenId": 3,
        "no": 18,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税において、一時所得の金額は、その年中の一時所得に係る総収入金額からその収入を得るために直接支出した金額の合計額を控除し、その残額から特別控除額（最高50万円）を控除した金額であり、その金額が総所得金額に算入される。[2020.01]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 139,
        "shikenId": 3,
        "no": 19,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "所得税法上、控除対象扶養親族のうち、その年の12月31日現在の年齢が70歳以上の者は、老人扶養親族に該当する。[2021.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 140,
        "shikenId": 3,
        "no": 20,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "給与所得者が所得税の住宅借入金等特別控除の適用を受ける場合、その適用を受ける最初の年分については、年末調整の対象者であっても、確定申告をしなければならない。[2022.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 141,
        "shikenId": 3,
        "no": 21,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "不動産登記には公信力が認められていないため、登記記録上の権利者が真実の権利者と異なっている場合に、登記記録を信じて不動産を購入した者は、原則として、その不動産に対する権利の取得について法的に保護されない。[2023.05]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 142,
        "shikenId": 3,
        "no": 22,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "都市計画法において、市街化区域内で行う開発行為は、その規模にかかわらず、都道府県知事等の許可を受けなければならない。[2023.01]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 143,
        "shikenId": 3,
        "no": 23,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "建築基準法において、建築物が防火地域および準防火地域にわたる場合、原則として、その全部について防火地域内の建築物に関する規定が適用される。[2022.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 144,
        "shikenId": 3,
        "no": 24,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "借地借家法において、定期建物賃貸借契約（定期借家契約）では、貸主に正当の事由があると認められる場合でなければ、貸主は、借主からの契約の更新の請求を拒むことができないとされている。[2022.05]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 145,
        "shikenId": 3,
        "no": 25,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "「被相続人の居住用財産（空き家）に係る譲渡所得の特別控除の特例」の適用を受けるためには、譲渡の対価の額が5,000万円以下でなければならない。[2020.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 146,
        "shikenId": 3,
        "no": 26,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "書面によらない贈与契約は、既に履行が終わった部分を除き、各当事者が解除をすることができる。[2021.01]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 147,
        "shikenId": 3,
        "no": 27,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "贈与税の納付については、納期限までに金銭で納付することを困難とする事由があるなど、所定の要件を満たせば、延納または物納によることが認められている。[2019.01]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 148,
        "shikenId": 3,
        "no": 28,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "法定相続人である被相続人の兄が相続により財産を取得した場合、その者は相続税額の2割加算の対象となる。[2021.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 149,
        "shikenId": 3,
        "no": 29,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "2024年中に開始する相続において、相続税額の計算における遺産に係る基礎控除額は、「3,000万円＋500万円×法定相続人の数」の算式により求められる。[2019.09改題]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 150,
        "shikenId": 3,
        "no": 30,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "相続人が相続により取得した宅地が「小規模宅地等についての相続税の課税価格の計算の特例」における特定居住用宅地等に該当する場合、その宅地のうち330㎡までを限度面積として、評価額の80％相当額を減額した金額を、相続税の課税価格に算入すべき価額とすることができる。[2023.09]",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 151,
        "shikenId": 3,
        "no": 31,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "一定の利率で複利運用しながら一定期間、毎年一定金額を受け取るために必要な元本を試算する際、毎年受け取る一定金額に乗じる係数は、（ ）である。[2023.05]",
        "choice1": "減債基金係数",
        "choice2": "年金現価係数",
        "choice3": "資本回収係数",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 152,
        "shikenId": 3,
        "no": 32,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "全国健康保険協会管掌健康保険の被保険者に支給される傷病手当金の額は、1日につき、原則として、傷病手当金の支給を始める日の属する月以前の直近の継続した（ ① ）の各月の標準報酬月額の平均額を30で除した額に、（ ② ）を乗じた額である。[2021.01]",
        "choice1": "①12カ月間　　②3分の2",
        "choice2": "①12カ月間　　②4分の3",
        "choice3": "①6カ月間　　②5分の4",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 153,
        "shikenId": 3,
        "no": 33,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "雇用保険の基本手当を受給するためには、倒産、解雇および雇止めなどの場合を除き、原則として、離職の日以前（ ① ）に被保険者期間が通算して（ ② ）以上あることなどの要件を満たす必要がある。[2022.09]",
        "choice1": "①1年間　　②6カ月",
        "choice2": "①2年間　　②6カ月",
        "choice3": "①2年間　　②12カ月",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 154,
        "shikenId": 3,
        "no": 34,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "遺族厚生年金の額（中高齢寡婦加算額および経過的寡婦加算額を除く）は、原則として、死亡した者の厚生年金保険の被保険者記録を基礎として計算した老齢厚生年金の報酬比例部分の額の（ ）に相当する額である。[2022.01]",
        "choice1": "2分の1",
        "choice2": "3分の2",
        "choice3": "4分の3",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 155,
        "shikenId": 3,
        "no": 35,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "貸金業法の総量規制により、個人が貸金業者による個人向け貸付を利用する場合の借入合計額は、原則として、年収の（ ）以内でなければならない。[2023.05]",
        "choice1": "2分の1",
        "choice2": "3分の1",
        "choice3": "4分の1",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 156,
        "shikenId": 3,
        "no": 36,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "生命保険契約の契約者は、契約者貸付制度を利用することにより、契約している生命保険の（ ）の一定の範囲内で保険会社から貸付を受けることができる。[2023.01]",
        "choice1": "既払込保険料総額",
        "choice2": "解約返戻金額",
        "choice3": "死亡保険金額",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 157,
        "shikenId": 3,
        "no": 37,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "生命保険契約において、契約者（＝保険料負担者）が夫、被保険者が（ ① ）、死亡保険金受取人が（ ② ）である場合、被保険者の死亡により死亡保険金受取人が受け取る死亡保険金は、相続税の課税対象となる。[2021.09]",
        "choice1": "①妻　　②夫",
        "choice2": "①妻　　②子",
        "choice3": "①夫　　②子",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 158,
        "shikenId": 3,
        "no": 38,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "個人賠償責任保険（特約）では、被保険者が（ ）、法律上の損害賠償責任を負うことによって被る損害は、補償の対象となる。[2023.09]",
        "choice1": "業務中に自転車で歩行者に衝突してケガをさせてしまい",
        "choice2": "自動車を駐車する際に誤って隣の自動車に傷を付けてしまい",
        "choice3": "買い物中に誤って商品を落として破損させてしまい",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 159,
        "shikenId": 3,
        "no": 39,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "民法および失火の責任に関する法律（失火責任法）において、借家人が軽過失によって火事を起こし、借家と隣家を焼失させた場合、借家の家主に対して損害賠償責任を（ ① ）。また、隣家の所有者に対して損害賠償責任を（ ② ）。[2022.01]",
        "choice1": "①負う　　②負わない",
        "choice2": "①負う　　②負う",
        "choice3": "①負わない　　②負う",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 160,
        "shikenId": 3,
        "no": 40,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "所得税において、個人が支払う地震保険の保険料に係る地震保険料控除は、原則として、（ ① ）を限度として年間支払保険料の（ ② ）が控除額となる。[2022.05]",
        "choice1": "①5万円　　②全額",
        "choice2": "①5万円　　②2分の1相当額",
        "choice3": "①10万円　　②2分の1相当額",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 161,
        "shikenId": 3,
        "no": 41,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "一定期間内に国内で生産された財やサービスの付加価値の合計額から物価変動の影響を取り除いた指標を、（ ）という。[2023.09]",
        "choice1": "実質GDP",
        "choice2": "名目GDP",
        "choice3": "GDPデフレーター",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 162,
        "shikenId": 3,
        "no": 42,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "先物やオプションを利用し、ベンチマークとなる指標の上昇率に対して2倍、3倍等の投資成果を目指すファンドは、（ ）ファンドに分類される。[2022.01]",
        "choice1": "ベア型",
        "choice2": "ブル型",
        "choice3": "インバース型",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 163,
        "shikenId": 3,
        "no": 43,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "上場企業X社の下記の＜資料＞に基づいて計算したX社株式の株価収益率（PER）は（ ① ）、株価純資産倍率（PBR）は（ ② ）である。[2021.01]<br><img src='/img/3_1.jpg' style='width: 250px;'/>",
        "choice1": "①1.5倍　　②15倍",
        "choice2": "①10倍　　②1.5倍",
        "choice3": "①15倍　　②1.5倍",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 164,
        "shikenId": 3,
        "no": 44,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "為替予約を締結していない外貨定期預金において、満期時の為替レートが預入時の為替レートに比べて（ ① ）になれば、当該外貨定期預金の円換算の利回りは（ ② ）なる。[2022.09]",
        "choice1": "①円高　　②高く",
        "choice2": "①円安　　②高く",
        "choice3": "①円安　　②低く",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 165,
        "shikenId": 3,
        "no": 45,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "A資産の期待収益率が3.0％、B資産の期待収益率が5.0％の場合に、A資産を40％、B資産を60％の割合で組み入れたポートフォリオの期待収益率は、（ ）となる。[2023.05]",
        "choice1": "1.8％",
        "choice2": "4.0％",
        "choice3": "4.2％",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 166,
        "shikenId": 3,
        "no": 46,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "個人が土地を譲渡したことによる譲渡所得の金額の計算において、譲渡した土地の取得費が不明である場合、当該収入金額の（ ）相当額を取得費とすることができる。[2022.01]",
        "choice1": "5％",
        "choice2": "10％",
        "choice3": "15％",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 167,
        "shikenId": 3,
        "no": 47,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "所得税において、不動産所得、（ ）、山林所得、譲渡所得の金額の計算上生じた損失の金額は、一定の場合を除き、他の所得の金額と損益通算することができる。[2022.01]",
        "choice1": "一時所得",
        "choice2": "雑所得",
        "choice3": "事業所得",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 168,
        "shikenId": 3,
        "no": 48,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "所得税において、2023年中に取得した建物（鉱業用減価償却資産等を除く）に係る減価償却の方法は、（ ）である。[2021.01]",
        "choice1": "定額法",
        "choice2": "定率法",
        "choice3": "定額法および定率法",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 169,
        "shikenId": 3,
        "no": 49,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "所得税において、確定拠出年金の個人型年金の掛金で、加入者本人が支払ったものは、（ ）の対象となる。[2023.01]",
        "choice1": "生命保険料控除",
        "choice2": "社会保険料控除",
        "choice3": "小規模企業共済等掛金控除",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 170,
        "shikenId": 3,
        "no": 50,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税において、 納税者の合計所得金額が2,400万円以下である場合、基礎控除の額は、（ ）である。[2022.09]",
        "choice1": "38万円",
        "choice2": "48万円",
        "choice3": "63万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 171,
        "shikenId": 3,
        "no": 51,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "相続税路線価は、相続税や（ ① ）を算定する際の土地等の評価額の基準となる価格であり、地価公示法による公示価格の（ ② ）を価格水準の目安として設定される。[2023.09]",
        "choice1": "①贈与税　　②70％",
        "choice2": "①贈与税　　②80％",
        "choice3": "①固定資産税　　②80％",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 172,
        "shikenId": 3,
        "no": 52,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "宅地建物取引業法上の媒介契約のうち、（ ① ）では、依頼者は他の宅地建物取引業者に重ねて媒介の依頼をすることができるが、（ ② ）では、依頼者は他の宅地建物取引業者に重ねて媒介の依頼をすることが禁じられている。[2021.09]",
        "choice1": "①一般媒介契約　　②専任媒介契約",
        "choice2": "①専任媒介契約　　②一般媒介契約",
        "choice3": "①専任媒介契約　　②専属専任媒介契約",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 173,
        "shikenId": 3,
        "no": 53,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "都市計画区域内にある幅員4ｍ未満の道で、建築基準法第42条第2項により道路とみなされるものについては、原則として、その中心線からの水平距離で（ ）後退した線がその道路の境界線とみなされる。[2022.01]",
        "choice1": "2ｍ",
        "choice2": "3ｍ",
        "choice3": "4ｍ",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 174,
        "shikenId": 3,
        "no": 54,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "建物の区分所有等に関する法律（区分所有法）によれば、集会においては、区分所有者および議決権の各（ ）以上の多数により、区分所有建物を取り壊し、その敷地上に新たに建物を建築する旨の決議（建替え決議）をすることができる。[2023.01]",
        "choice1": "3分の2",
        "choice2": "4分の3",
        "choice3": "5分の4",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 175,
        "shikenId": 3,
        "no": 55,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "土地の有効活用において、一般に、土地所有者が入居予定の事業会社から建設資金を借り受けて、事業会社の要望に沿った店舗等を建設し、その店舗等を事業会社に賃貸する手法を、（ ）という。[2022.05]",
        "choice1": "等価交換方式",
        "choice2": "建設協力金方式",
        "choice3": "事業用定期借地権方式",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 176,
        "shikenId": 3,
        "no": 56,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "個人が死因贈与によって取得した財産は、課税の対象とならない財産を除き、（ ）の課税対象となる。[2022.05]",
        "choice1": "贈与税",
        "choice2": "相続税",
        "choice3": "所得税",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 177,
        "shikenId": 3,
        "no": 57,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "「直系尊属から結婚・子育て資金の一括贈与を受けた場合の贈与税の非課税」の適用を受ける場合、贈与税が非課税となる金額は、受贈者1人につき最大（ ）である。[2022.09]",
        "choice1": "1,000万円",
        "choice2": "1,500万円",
        "choice3": "2,000万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 178,
        "shikenId": 3,
        "no": 58,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "下記の＜親族関係図＞において、遺留分を算定するための財産の価額が2億4,000万円である場合、長女Eさんの遺留分の金額は、（ ）となる。[2022.09]<br><img src='/img/3_2.jpg' style='width: 450px;'/>",
        "choice1": "1,000万円",
        "choice2": "2,000万円",
        "choice3": "4,000万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 179,
        "shikenId": 3,
        "no": 59,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "公正証書遺言は、証人2人以上の立会いのもと、遺言者が遺言の趣旨を公証人に口授し、公証人がそれを筆記して作成される遺言であり、相続開始後に（ ① ）における検認手続が（ ② ）である。[2022.05]",
        "choice1": "①公証役場　　②必要",
        "choice2": "①家庭裁判所　　②必要",
        "choice3": "①家庭裁判所　　②不要",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 180,
        "shikenId": 3,
        "no": 60,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "202X年５月7日（火）に死亡したＡさんが所有していた上場株式Ｘを相続により取得した場合の１株当たりの相続税評価額は、下記の＜資料＞によれば、（ ）である。[2021.05]<br><br>〈資料〉上場株式Xの価格<br>202X年3月の毎日の最終価格の月平均額 540円<br>202X年4月の毎日の最終価格の月平均額 600円<br>202X年5月の毎日の最終価格の月平均額 620円<br>202X年5月7日（火）の最終価格 600円",
        "choice1": "540円",
        "choice2": "600円",
        "choice3": "620円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 181,
        "shikenId": 4,
        "no": 1,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/181_1.png'/>",
        "question2": "はじめに、Ｍさんは、《設例》の＜Ａさんに関する資料＞に基づき、Ａさんが老齢基礎年金の受給を65歳から開始した場合の年金額を試算した。Ｍさんが試算した老齢基礎年金の年金額の計算式として、次のうち最も適切なものはどれか。なお、老齢基礎年金の年金額は、2024年度価額に基づいて計算するものとする。",
        "choice1": "<img src='/img/181_2.png' style=' height: 50px;margin-top: -12px;'/>",
        "choice2": "<img src='/img/181_3.png' style=' height: 50px;margin-top: -13px;'/>",
        "choice3": "<img src='/img/181_4.png' style=' height: 55px;margin-top: -12px;'/>",
        "choice4": "",
        "choice5": "2023.01改",
        "categoryName": "ライフ",
        "rontenName": "老齢基礎年金の年金額",
        "important": ""
    },
    {
        "id": 182,
        "shikenId": 4,
        "no": 2,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/181_1.png'/>",
        "question2": "次に、Ｍさんは、国民年金基金について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。<div class='bb'>「国民年金基金は、国民年金の第１号被保険者を対象とした、老齢基礎年金に上乗せする年金を支給する任意加入の年金制度です。加入は口数制となっており、１口目は２種類の（ ① ）年金（Ａ型・Ｂ型）のうち、いずれかを選択します。掛金の額は、選択した給付の型や口数、加入時の年齢等によって決まり、掛金の拠出限度額は、月額（ ② ）です。また、支払った掛金は、所得税において（ ③ ）の対象となります」</div>",
        "choice1": "① 確定　　② 68,000円　　③ 小規模企業共済等掛金控除",
        "choice2": "① 終身　　② 30,000円　　③ 小規模企業共済等掛金控除",
        "choice3": "① 終身　　② 68,000円　　③ 社会保険料控除",
        "choice4": "",
        "choice5": "2023.01改",
        "categoryName": "ライフ",
        "rontenName": "国民年金基金",
        "important": ""
    },
    {
        "id": 183,
        "shikenId": 4,
        "no": 3,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/181_1.png'/>",
        "question2": "最後に、Ｍさんは、老後の年金収入を増やすことができる各種制度について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「国民年金の付加年金は、月額200円の付加保険料を納付することにより、老齢基礎年金と併せて受給することができる年金です」",
        "choice2": "「確定拠出年金の個人型年金は、加入者自身が掛金の運用方法を選択し、資産を形成する年金制度です。将来受け取ることができる年金額は、運用実績により増減します」",
        "choice3": "「小規模企業共済制度は、個人事業主が廃業等した場合に必要となる資金を準備しておくための制度です。毎月の掛金は、1,000円から70,000円の範囲内（500円単位）で選択することができます」",
        "choice4": "",
        "choice5": "2023.01改",
        "categoryName": "ライフ",
        "rontenName": "年金収入を増やす制度",
        "important": ""
    },
    {
        "id": 184,
        "shikenId": 4,
        "no": 4,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 4,
        "question1": "<img src='/img/184_1.png'/>",
        "question2": "はじめに、Ｍさんは、Ｘ社株式の投資指標について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「株価の相対的な割高・割安の度合いを判断する指標に、ＰＥＲやＰＢＲがあります。＜Ｘ社に関する資料＞から算出されるＸ社株式のＰＥＲは1.2倍、ＰＢＲは12倍です」",
        "choice2": "「ＰＥＲとＰＢＲは、一般に、どちらも数値が高いほうが株価は割安と判断されますが、何倍程度が妥当であるかを検討する際は、同業他社の数値や過去の傾向と比較するなど、相対的な数値として捉えることが重要です」",
        "choice3": "「株価に対する１株当たりの年間配当金の割合を示す指標を配当利回りといいます。＜Ｘ社に関する資料＞から算出されるＸ社株式の配当利回りは2.5％です」",
        "choice4": "",
        "choice5": "2022.05改",
        "categoryName": "金融",
        "rontenName": "投資指標",
        "important": ""
    },
    {
        "id": 185,
        "shikenId": 4,
        "no": 5,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/184_1.png'/>",
        "question2": "次に、Ｍさんは、Ｙ投資信託の購入に係る費用等について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「Ｙ投資信託の購入時に手数料はかかりません。ＮＩＳＡつみたて投資枠の対象とな る公募株式投資信託は、購入時手数料がゼロであることが要件の１つとなっています」",
        "choice2": "「運用管理費用（信託報酬）は、投資信託を保有する投資家が間接的に負担する費用です。一般に、Ｙ投資信託のようなインデックス型投資信託は、アクティブ型投資信託よりも運用管理費用（信託報酬）が高い傾向があります」",
        "choice3": "「信託財産留保額は、投資信託の組入資産を売却する際に発生する手数料等を、投資信託を換金する投資家に負担してもらうことを目的として設けられているものですが、Ｙ投資信託では、この費用はかかりません」",
        "choice4": "",
        "choice5": "2022.05改",
        "categoryName": "金融",
        "rontenName": "投資信託のコスト",
        "important": ""
    },
    {
        "id": 186,
        "shikenId": 4,
        "no": 6,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/184_1.png'/>",
        "question2": "最後に、Ｍさんは、ＮＩＳＡの「つみたて投資枠」についてアドバイスした。ＭさんのＡさんに対するアドバイスとして、次のうち最も適切なものはどれか。",
        "choice1": "「つみたて投資枠の対象となる金融商品は、長期の積立・分散投資に適した一定の商品性を有する公募株式投資信託やＥＴＦとされ、上場株式は対象となっていません」",
        "choice2": "「つみたて投資枠の年間の非課税投資枠は120万円です。購入は累積投資契約に基づく定期かつ継続的な買付けを行う方法に限られており、非課税期間は10年間です」",
        "choice3": "「つみたて投資枠を利用して購入した公募株式投資信託を解約した際に損失が生じた場合、その損失の金額は、特定口座で保有する上場株式等の譲渡益と通算することができます」",
        "choice4": "",
        "choice5": "2022.05改",
        "categoryName": "金融",
        "rontenName": "NISAつみたて投資枠",
        "important": ""
    },
    {
        "id": 187,
        "shikenId": 4,
        "no": 7,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/187_1.png'/>",
        "question2": "所得税における青色申告制度に関する以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/187_2.png' style='width:100%'/>",
        "choice1": "①55　　②10　　③低価法",
        "choice2": "①65　　②10　　③低価法",
        "choice3": "①65　　②55　　③定額法",
        "choice4": "",
        "choice5": "2023.09改",
        "categoryName": "タックス",
        "rontenName": "青色申告制度",
        "important": ""
    },
    {
        "id": 188,
        "shikenId": 4,
        "no": 8,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/187_1.png'/>",
        "question2": "Ａさんの2024年分の所得税の課税に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「Ａさんが受け取った一時払変額個人年金保険の解約返戻金は、源泉分離課税の対象となります」",
        "choice2": "「Ａさんは、妻Ｂさんに係る配偶者控除の適用を受けることができ、その控除額は38万円です」",
        "choice3": "「Ａさんは、母Ｄさんに係る扶養控除の適用を受けることができ、その控除額は58万円です」",
        "choice4": "",
        "choice5": "2023.09改",
        "categoryName": "タックス",
        "rontenName": "所得税の課税",
        "important": ""
    },
    {
        "id": 189,
        "shikenId": 4,
        "no": 9,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/187_1.png'/>",
        "question2": "Ａさんの2024年分の所得税における総所得金額は、次のうちどれか。",
        "choice1": "580万円",
        "choice2": "595万円",
        "choice3": "610万円",
        "choice4": "",
        "choice5": "2023.09改",
        "categoryName": "タックス",
        "rontenName": "総所得金額の計算",
        "important": ""
    },
    {
        "id": 190,
        "shikenId": 4,
        "no": 10,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/190_1.png'/>",
        "question2": "甲土地に耐火建築物を建築する場合の①建蔽率の上限となる建築面積と②容積率の上限となる延べ面積の組合せとして、次のうち最も適切なものはどれか。",
        "choice1": "①350㎡　　②1,400㎡",
        "choice2": "①400㎡　　②1,400㎡",
        "choice3": "①400㎡　　②1,500㎡",
        "choice4": "",
        "choice5": "2022.09改",
        "categoryName": "不動産",
        "rontenName": "建蔽率、容積率",
        "important": ""
    },
    {
        "id": 191,
        "shikenId": 4,
        "no": 11,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/190_1.png'/>",
        "question2": "「被相続人の居住用財産（空き家）に係る譲渡所得の特別控除の特例」（以下、「本特例」という）に関する以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/190_2.png' style='width:100%'/>",
        "choice1": "①3,000　　②１億　　③３年",
        "choice2": "①3,000　　②6,000万　　③５年",
        "choice3": "①5,000　　②１億　　③５年",
        "choice4": "",
        "choice5": "2022.09改",
        "categoryName": "不動産",
        "rontenName": "空き家の譲渡所得の特例",
        "important": ""
    },
    {
        "id": 192,
        "shikenId": 4,
        "no": 12,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/190_1.png'/>",
        "question2": "事業用定期借地権方式に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「事業用定期借地権方式は、Ｘ社が甲土地を契約で一定期間賃借し、Ｘ社が甲土地上に店舗を建築する方式です。Ａさんは、土地を手放さずに安定した地代収入を得ることができ、契約期間満了時には土地が更地で返還されます」",
        "choice2": "「Ａさんが、甲土地についてＸ社と事業用定期借地権の設定契約を締結する場合、その契約は、公正証書によってしなければなりません」",
        "choice3": "「Ａさんと事業用定期借地権の設定契約を締結したＸ社が、甲土地上に店舗を建築し、その賃貸期間中にＡさんの相続が開始した場合、相続税額の計算上、甲土地は貸家建付地として評価されます」",
        "choice4": "",
        "choice5": "2022.09改",
        "categoryName": "不動産",
        "rontenName": "事業用定期借地権方式",
        "important": ""
    },
    {
        "id": 193,
        "shikenId": 4,
        "no": 13,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/194_1.png'/>",
        "question2": "Ａさんの相続に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「相続人が自宅に保管されていたＡさんの自筆証書遺言を発見した場合、相続人は、遅滞なく、自筆証書遺言を法務局に提出して、その検認を請求しなければなりません」",
        "choice2": "「Ａさんが2024年分の所得税および復興特別所得税について確定申告書を提出しなければならない場合に該当するとき、相続人は、原則として、相続の開始があったことを知った日の翌日から４カ月以内に準確定申告書を提出しなければなりません」",
        "choice3": "「相続税の申告書は、原則として、相続の開始があったことを知った日の翌日から10カ月以内に被相続人であるＡさんの死亡時の住所地を所轄する税務署長に提出しなければなりません」",
        "choice4": "",
        "choice5": "2021.09改",
        "categoryName": "相続",
        "rontenName": "相続に関する記述",
        "important": ""
    },
    {
        "id": 194,
        "shikenId": 4,
        "no": 14,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/194_1.png'/>",
        "question2": "Ａさんの相続に関する以下の文章の空欄①～③に入る数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/194_2.png' style='width:100%'/>",
        "choice1": "①4,200　　②4,500　　③4,000",
        "choice2": "①4,800　　②1,500　　③4,000",
        "choice3": "①4,800　　②4,500　　③1,000",
        "choice4": "",
        "choice5": "2021.09改",
        "categoryName": "相続",
        "rontenName": "基礎控除等の計算",
        "important": ""
    },
    {
        "id": 195,
        "shikenId": 4,
        "no": 15,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/194_1.png'/>",
        "question2": "Ａさんの相続に係る課税遺産総額（「課税価格の合計額－遺産に係る基礎控除額」）が２億9,000万円であった場合の相続税の総額は、次のうちどれか。<br><img src='/img/194_3.png' style='width:400px'/>",
        "choice1": "7,050万円",
        "choice2": "9,050万円",
        "choice3": "１億350万円",
        "choice4": "",
        "choice5": "2021.09改",
        "categoryName": "相続",
        "rontenName": "相続税の総額の計算",
        "important": ""
    },
    {
        "id": 196,
        "shikenId": 5,
        "no": 1,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/196_1.png'/>",
        "question2": "はじめに、Ｍさんは、《設例》の＜Ａさんとその家族に関する資料＞に基づき、Ａさんが老齢基礎年金の受給を65歳から開始した場合の年金額（2024年度価額）を試算した。Ｍさんが試算した老齢基礎年金の年金額の計算式として、次のうち最も適切なものはどれか。",
        "choice1": "<img src='/img/196_2.png' style=' height: 42px;margin-top: -10px;'/>",
        "choice2": "<img src='/img/196_3.png' style=' height: 41px;margin-top: -10px;margin-left:-2px'/>",
        "choice3": "<img src='/img/196_4.png' style=' height: 42px;margin-top: -11px;'/>",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "ライフ",
        "rontenName": "老齢基礎年金の年金額",
        "important": ""
    },
    {
        "id": 197,
        "shikenId": 5,
        "no": 2,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/196_1.png'/>",
        "question2": "次に、Ｍさんは、Ａさんおよび妻Ｂさんが受給することができる公的年金制度からの老齢給付について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「Ａさんおよび妻Ｂさんには、特別支給の老齢厚生年金の支給はありません。原則として、65歳から老齢基礎年金および老齢厚生年金を受給することになります」",
        "choice2": "「Ａさんが65歳から受給することができる老齢厚生年金の額には、妻Ｂさんが65歳になるまでの間、配偶者の加給年金額が加算されます」",
        "choice3": "「Ａさんが60歳０カ月で老齢基礎年金および老齢厚生年金の繰上げ支給を請求した場合、年金の減額率は30％となります」",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "ライフ",
        "rontenName": "公的年金制度の老齢給付",
        "important": ""
    },
    {
        "id": 198,
        "shikenId": 5,
        "no": 3,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/196_1.png'/>",
        "question2": "最後に、Ｍさんは、国民年金の学生納付特例制度（以下、「本制度」という）について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<div class='bb'>「本制度は、国民年金の第１号被保険者で大学等の所定の学校に在籍する学生について、（　①　）の前年所得が一定額以下の場合、所定の申請に基づき、国民年金保険料の納付を猶予する制度です。なお、本制度の適用を受けた期間は、老齢基礎年金の（　②　）されます。本制度の適用を受けた期間の保険料は、（　③　）年以内であれば、追納することができます。ただし、本制度の承認を受けた期間の翌年度から起算して、３年度目以降に保険料を追納する場合には、承認を受けた当時の保険料額に経過期間に応じた加算額が上乗せされます」</div>",
        "choice1": "①世帯主　　② 受給資格期間に算入　　③５",
        "choice2": "①学生本人　　②受給資格期間に算入　　③10",
        "choice3": "①世帯主　　②年金額に反映　　③10",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "ライフ",
        "rontenName": "学生納付特例制度",
        "important": ""
    },
    {
        "id": 199,
        "shikenId": 5,
        "no": 4,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/199_1.png'/>",
        "question2": "はじめに、Ｍさんは、生命保険の見直しを検討するにあたって、現時点の必要保障額を試算することにした。下記の＜算式＞および＜条件＞に基づき、Ａさんが現時点で死亡した場合の必要保障額は、次のうちどれか。<br><img src='/img/199_2.png' style='width:100%'/>",
        "choice1": "200万円",
        "choice2": "300万円",
        "choice3": "700万円",
        "choice4": "",
        "choice5": "2022.09",
        "categoryName": "リスク（個人）",
        "rontenName": "必要保障額の計算",
        "important": "必要保障額の計算"
    },
    {
        "id": 200,
        "shikenId": 5,
        "no": 5,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/199_1.png'/>",
        "question2": "次に、Ｍさんは、生命保険の見直しについてアドバイスした。ＭさんのＡさんに対するアドバイスとして、次のうち最も適切なものはどれか。",
        "choice1": "「厚生労働省の患者調査等の各種データによれば、入院日数は年々長期化しており、退院後の通院時の療養に係る費用負担も大きくなっていますので、医療保障を検討する場合は、入院や退院後の通院に対する保障を充実させることが大切です」",
        "choice2": "「介護保障を検討する際には、保険金額に加え、保険金等の支払事由が保険会社独自のものか、公的介護保険等の社会保障制度と連動しているものか等、どのような場合に保険金や給付金が支払われるか、加入前に確認しておきましょう」",
        "choice3": "「現在加入している生命保険を払済終身保険に変更した場合、死亡保険金額は減少しますが、現在付加されている入院特約は残り、月々の保険料負担は軽減されます」",
        "choice4": "",
        "choice5": "2022.09",
        "categoryName": "リスク（個人）",
        "rontenName": "生命保険の見直し",
        "important": ""
    },
    {
        "id": 201,
        "shikenId": 5,
        "no": 6,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/199_1.png'/>",
        "question2": "最後に、Ｍさんは、ＡさんがＸ社を退職した後の健康保険について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「Ａさんは、退職日の翌日から最長２年間、健康保険に任意継続被保険者として加入することができますが、保険料はＡさんが全額負担することになります」",
        "choice2": "「Ａさんが健康保険の任意継続被保険者とならなかった場合は、国民健康保険に加入します。Ａさんが国民健康保険に加入した場合、妻Ｂさんを国民健康保険の被扶養者とすることができます」",
        "choice3": "「Ａさんが国民健康保険に加入した場合、高額療養費の支給はありません。健康保険の任意継続被保険者には高額療養費の支給がありますので、退職後は、健康保険の任意継続被保険者になることをお勧めします」",
        "choice4": "",
        "choice5": "2022.09",
        "categoryName": "リスク（個人）",
        "rontenName": "退職後の健康保険",
        "important": ""
    },
    {
        "id": 202,
        "shikenId": 5,
        "no": 7,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/202_1.png'/>",
        "question2": "仮に、Ｘ社がＡさんに役員退職金5,000万円を支給した場合、Ａさんが受け取る役員退職金に係る退職所得の金額として、次のうち最も適切なものはどれか。なお、Ａさんの役員在任期間（勤続年数）を30年とし、これ以外に退職手当等の収入はなく、障害者になったことが退職の直接の原因ではないものとする。",
        "choice1": "1,750万円",
        "choice2": "3,500万円",
        "choice3": "3,800万円",
        "choice4": "",
        "choice5": "2023.09",
        "categoryName": "リスク（法人）",
        "rontenName": "退職所得の計算",
        "important": ""
    },
    {
        "id": 203,
        "shikenId": 5,
        "no": 8,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/202_1.png'/>",
        "question2": "Ｍさんは、《設例》の長期平準定期保険について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「当該生命保険の単純返戻率（解約返戻金額÷払込保険料累計額）は、保険期間の途中でピーク時期を迎え、その後は低下しますが、保険期間満了時に満期保険金が支払われます」",
        "choice2": "「現時点で当該生命保険を払済終身保険に変更する場合、契約は継続するため、経理処理は必要ありません」",
        "choice3": "「当該生命保険を払済終身保険に変更し、契約者をＡさん、死亡保険金受取人をＡさんの相続人に名義を変更することで、当該払済終身保険を役員退職金の一部としてＡさんに現物支給することができます」",
        "choice4": "",
        "choice5": "2023.09",
        "categoryName": "リスク（法人）",
        "rontenName": "長期平準定期保険",
        "important": ""
    },
    {
        "id": 204,
        "shikenId": 5,
        "no": 9,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/202_1.png'/>",
        "question2": "Ｘ社が現在加入している《設例》の長期平準定期保険を下記＜条件＞にて解約した場合の経理処理（仕訳）として、次のうち最も適切なものはどれか。<br>＜条件＞<br><ul><li>Ｘ社が解約時までに支払った保険料の累計額は、5,200万円である。</li><li>解約返戻金の額は、4,200万円である。</li><li>配当等、上記以外の条件は考慮しないものとする。</li></ul>",
        "choice1": "<img src='/img/201_2.png' style=' height: 85px;margin-top: -5px;'/>",
        "choice2": "<img src='/img/201_3.png' style=' height: 85px;margin-top: -5px;'/>",
        "choice3": "<img src='/img/201_4.png' style=' height: 85px;margin-top: -5px;'/>",
        "choice4": "",
        "choice5": "2023.09",
        "categoryName": "リスク（法人）",
        "rontenName": "長期平準定期保険の経理処理",
        "important": ""
    },
    {
        "id": 205,
        "shikenId": 5,
        "no": 10,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/204_1.png'/>",
        "question2": "Ａさんの2024年分の所得税における総所得金額は、次のうちどれか。<br><img src='/img/204_2.png' style='width:500px'/>",
        "choice1": "610万円",
        "choice2": "635万円",
        "choice3": "900万円",
        "choice4": "",
        "choice5": "2022.05改",
        "categoryName": "タックス",
        "rontenName": "総所得金額の計算",
        "important": ""
    },
    {
        "id": 206,
        "shikenId": 5,
        "no": 11,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/204_1.png'/>",
        "question2": "Ａさんの2024年分の所得税における所得控除に関する以下の文章の空欄①～③に入る数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/204_3.png' style='width:100%'/>",
        "choice1": "①48　　②38　　③63",
        "choice2": "①48　　②26　　③76",
        "choice3": "①103　　②38　　③38",
        "choice4": "",
        "choice5": "2022.05改",
        "categoryName": "タックス",
        "rontenName": "所得控除",
        "important": ""
    },
    {
        "id": 207,
        "shikenId": 5,
        "no": 12,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/204_1.png'/>",
        "question2": "Ａさんの2024年分の所得税の課税等に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「Ａさんが受け取った一時払変額個人年金保険の解約返戻金は、源泉分離課税の対象となります」",
        "choice2": "「総所得金額に算入される一時所得の金額が20万円を超えるため、Ａさんは所得税の確定申告をしなければなりません」",
        "choice3": "「Ａさんは、所得税の確定申告をすることで、ふるさと納税で寄附した10万円の全額について、2024年分の所得税額から控除されます」",
        "choice4": "",
        "choice5": "2022.05改",
        "categoryName": "タックス",
        "rontenName": "所得税の課税等",
        "important": ""
    },
    {
        "id": 208,
        "shikenId": 5,
        "no": 13,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/208_1.png'/>",
        "question2": "Ａさんの相続に関する以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/208_2.png' style='width:100%'/>",
        "choice1": "①８分の１　　②6,000　　③４",
        "choice2": "①８分の１　　②5,400　　③10",
        "choice3": "①12分の１　　②6,000　　③10",
        "choice4": "",
        "choice5": "2022.01改",
        "categoryName": "相続",
        "rontenName": "相続に関する数値",
        "important": ""
    },
    {
        "id": 209,
        "shikenId": 5,
        "no": 14,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/208_1.png'/>",
        "question2": "Ａさんの相続に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「Ａさんの自宅から自筆証書遺言を発見した相続人は、相続の開始を知った後、遅滞なく、その遺言書を家庭裁判所に提出し、その検認を請求しなければなりません」",
        "choice2": "「妻Ｂさんが受け取る死亡保険金は、みなし相続財産として相続税の課税対象となりますが、死亡保険金の非課税金額の規定の適用を受けることで、相続税の課税価格に算入される金額は500万円となります」",
        "choice3": "「孫Ｆさんおよび孫Ｇさんは、相続税額の２割加算の対象となります」",
        "choice4": "",
        "choice5": "2022.01改",
        "categoryName": "相続",
        "rontenName": "相続に関する記述",
        "important": ""
    },
    {
        "id": 210,
        "shikenId": 5,
        "no": 15,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/208_1.png'/>",
        "question2": "Ａさんの相続に係る課税遺産総額（「課税価格の合計額－遺産に係る基礎控除額」）が３億円であった場合の相続税の総額は、次のうちどれか。<br><img src='/img/208_3.png' style='width:400px'/>",
        "choice1": "6,550万円",
        "choice2": "8,160万円",
        "choice3": "１億800万円",
        "choice4": "",
        "choice5": "2022.01改",
        "categoryName": "相続",
        "rontenName": "相続税の総額の計算",
        "important": ""
    },
    {
        "id": 211,
        "shikenId": 6,
        "no": 1,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "ファイナンシャル・プランニング業務を行うに当たっては、関連業法を順守することが重要である。ファイナンシャル・プランナー（以下「ＦＰ」という）の行為に関する次の記述のうち、最も不適切なものはどれか。",
        "question2": "",
        "choice1": "生命保険募集人、保険仲立人の登録を受けていないＦＰが、生命保険契約を検討している顧客のライフプランに基づき、必要保障額を具体的に試算し、相談料金を受け取った。",
        "choice2": "投資助言・代理業の登録を受けていないＦＰが、顧客と投資顧問契約を締結し、当該契約に基づいて具体的な投資銘柄と投資タイミングについて有償で助言をした。",
        "choice3": "税理士資格を有していないＦＰが、相続対策を検討している顧客に対し、一般的な相続税制度の仕組みと手順を解説し、相談料金を受け取った。",
        "choice4": "",
        "choice5": "2022.09",
        "categoryName": "ライフ",
        "rontenName": "FPと関連業法",
        "important": ""
    },
    {
        "id": 212,
        "shikenId": 6,
        "no": 2,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "下記は、山岸家のキャッシュフロー表（一部抜粋）である。このキャッシュフロー表の空欄（ア）～（ウ）にあてはまる数値として、誤っているものはどれか。なお、計算に当たっては、キャッシュフロー表中に記載の整数を使用し、計算過程においては端数処理をせず計算し、計算結果については万円未満を四捨五入すること。<br><img src='/img/212.png'/>",
        "question2": "",
        "choice1": "（ア）     ４０２",
        "choice2": "（イ）         ６１",
        "choice3": "（ウ）１,２２０",
        "choice4": "",
        "choice5": "2023.05",
        "categoryName": "ライフ",
        "rontenName": "キャッシュフロー表",
        "important": ""
    },
    {
        "id": 213,
        "shikenId": 6,
        "no": 3,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "下記＜資料＞に基づくＱＸ株式会社の投資指標に関する次の記述のうち、最も適切なものはどれか。なお、購入時の手数料および税金は考慮しないこととする。<br><img src='/img/213.png'/>",
        "question2": "",
        "choice1": "株価収益率（ＰＥＲ）で比較した場合、ＱＸ株式会社の株価は日経平均採用銘柄の平均（予想ベース）より割安である。",
        "choice2": "株価純資産倍率（ＰＢＲ）で比較した場合、ＱＸ株式会社の株価は東証プライム全銘柄の平均より割安である。",
        "choice3": "配当利回りで比較した場合、ＱＸ株式会社の配当利回りは東証スタンダード全銘柄の平均（予想ベース）より低い。",
        "choice4": "",
        "choice5": "2022.01",
        "categoryName": "金融",
        "rontenName": "投資指標",
        "important": ""
    },
    {
        "id": 214,
        "shikenId": 6,
        "no": 4,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "下記＜資料＞の外貨定期預金について、満期時の外貨ベースの元利合計額を円転した金額として、正しいものはどれか。なお、計算結果（円転した金額）について円未満の端数が生じる場合は切り捨てること。また、税金については考慮しないこととする。<br><img src='/img/214.png'/>",
        "question2": "",
        "choice1": "７８２,５０５円",
        "choice2": "７７７,４８３円",
        "choice3": "７７２,４６０円",
        "choice4": "",
        "choice5": "2022.05",
        "categoryName": "金融",
        "rontenName": "外貨預金元利金の計算",
        "important": "必要保障額の計算"
    },
    {
        "id": 215,
        "shikenId": 6,
        "no": 5,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "露木さんは、投資信託の費用についてＦＰの中井さんに質問をした。下記の空欄（ア）～（ウ）にあてはまる語句に関する次の記述のうち、最も適切なものはどれか。<br><img src='/img/215.png'/>",
        "question2": "",
        "choice1": "（ア）にあてはまる語句は、「オープン」である。",
        "choice2": "（イ）にあてはまる語句は、「口座管理料」である。",
        "choice3": "（ウ）にあてはまる語句は、「信託財産留保額」である。",
        "choice4": "",
        "choice5": "2023.05",
        "categoryName": "金融",
        "rontenName": "投資信託コスト",
        "important": ""
    },
    {
        "id": 216,
        "shikenId": 6,
        "no": 6,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "建築基準法に従い、下記＜資料＞の土地に建築物を建築する場合、その土地に対する建築物の建築面 積の最高限度として、正しいものはどれか。なお、記載のない条件については一切考慮しないこととする。<br><img src='/img/216.png'/>",
        "question2": "",
        "choice1": "２４０㎡",
        "choice2": "３６０㎡",
        "choice3": "４８０㎡",
        "choice4": "",
        "choice5": "2022.05",
        "categoryName": "不動産",
        "rontenName": "建蔽率",
        "important": ""
    },
    {
        "id": 217,
        "shikenId": 6,
        "no": 7,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "下記は、宅地建物の売買・交換において、宅地建物取引業者と交わす媒介契約の種類とその概要についてまとめた表である。下表の空欄（ア）～（ウ）にあてはまる語句または数値の組み合わせとして、正しいものはどれか。なお、自己発見取引とは、自ら発見した相手方と売買または交換の契約を締結する行為を指すものとする。<br><img src='/img/217.png'/>",
        "question2": "",
        "choice1": "（ア）可　　（イ）２週間　　（ウ）５",
        "choice2": "（ア）可　　（イ）１ヵ月　　（ウ）７",
        "choice3": "（ア）不可　　（イ）１ヵ月　　（ウ）５",
        "choice4": "",
        "choice5": "2021.09",
        "categoryName": "不動産",
        "rontenName": "媒介契約",
        "important": ""
    },
    {
        "id": 218,
        "shikenId": 6,
        "no": 8,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "飯田雅彦さんが加入している定期保険特約付終身保険（下記＜資料＞参照）の保障内容に関する次の記述の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、保険契約は有効に継続しており、特約は自動更新されているものとする。また、雅彦さんはこれまでに＜資料＞の保険から保険金および給付金を一度も受け取っていないものとする。<br><img src='/img/218.png'/>",
        "question2": "",
        "choice1": "３,５００万円",
        "choice2": " ３,９００万円",
        "choice3": "４,２００万円",
        "choice4": "",
        "choice5": "2023.09",
        "categoryName": "リスク",
        "rontenName": "生命保険証券の見方",
        "important": ""
    },
    {
        "id": 219,
        "shikenId": 6,
        "no": 9,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "大垣正臣さんが２０２４年中に支払った生命保険の保険料は下記＜資料＞のとおりである。この場合の正臣さんの２０２４年分の所得税の計算における生命保険料控除の金額として、正しいものはどれか。なお、下記＜資料＞の保険について、これまでに契約内容の変更はないものとする。また、２０２４年分の生命保険料控除額が最も多くなるように計算すること。<br><img src='/img/219.png'/>",
        "question2": "",
        "choice1": "３６,２６０円",
        "choice2": "４０,０００円",
        "choice3": "６８,８６０円",
        "choice4": "",
        "choice5": "2023.09",
        "categoryName": "リスク",
        "rontenName": "生命保険料控除の計算",
        "important": ""
    },
    {
        "id": 220,
        "shikenId": 6,
        "no": 10,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "細井英治さんが契約している自動車保険の主な内容は、下記＜資料＞のとおりである。＜資料＞に基づく次の記述のうち、自動車保険による補償の対象とならないものはどれか。なお、いずれも保険期間中に発生したものであり、被保険自動車の運転者は英治さんである。また、記載のない事項については一切考慮しないものとする。<br><img src='/img/220.png'/>",
        "question2": "",
        "choice1": "被保険自動車を運転中に、ブレーキ操作を誤り単独事故を起こし、車体が損傷した場合の修理費用",
        "choice2": "被保険自動車に追突した相手車が逃走し、相手から損害賠償金が受けられない場合の英治さんの治療費用",
        "choice3": "被保険自動車を運転中に、誤って自宅のブロック塀を損壊した場合のブロック塀の修理費用",
        "choice4": "",
        "choice5": "2022.05",
        "categoryName": "リスク",
        "rontenName": "自動車保険の補償",
        "important": ""
    },
    {
        "id": 221,
        "shikenId": 6,
        "no": 11,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "会社員の細川博さんが２０２４年中に支払った医療費等が下記＜資料＞のとおりである場合、博さんの２０２４年分の所得税の確定申告における医療費控除の金額として、正しいものはどれか。なお、博さんの２０２４年中の所得は、給与所得６００万円のみであり、支払った医療費等はすべて博さんおよび生計を一にする妻のために支払ったものである。また、保険金等により補てんされた金額はないものとし、医療費控除の金額が最も大きくなるよう計算することとする。<br><img src='/img/221.png'/>",
        "question2": "",
        "choice1": "３２０,０００円",
        "choice2": "１７０,０００円",
        "choice3": "１５０,０００円",
        "choice4": "",
        "choice5": "2022.01",
        "categoryName": "タックス",
        "rontenName": "医療費控除",
        "important": ""
    },
    {
        "id": 222,
        "shikenId": 6,
        "no": 12,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "大津さん（６６歳）の２０２４年分の収入は下記＜資料＞のとおりである。大津さんの２０２４年分の所得税における総所得金額として、正しいものはどれか。なお、記載のない事項については一切考慮しないものとする。<br><img src='/img/222.png'/>",
        "question2": "",
        "choice1": "１３２万円",
        "choice2": "１５０万円",
        "choice3": "２００万円",
        "choice4": "",
        "choice5": "2023.09",
        "categoryName": "タックス",
        "rontenName": "総所得金額の計算",
        "important": ""
    },
    {
        "id": 223,
        "shikenId": 6,
        "no": 13,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "２０２４年１月５日に相続が開始された皆川健太郎さん（被相続人）の＜親族関係図＞が下記のとおりである場合、民法上の相続人および法定相続分の組み合わせとして、正しいものはどれか。なお、記載のない条件については一切考慮しないこととする。<br><img src='/img/223.png'/>",
        "question2": "",
        "choice1": "美千子   ２／３       喜美子   １／３",
        "choice2": "美千子   １／２       喜美子   １／２",
        "choice3": "美千子   １／２       莉緒       １／２",
        "choice4": "",
        "choice5": "2023.01",
        "categoryName": "相続",
        "rontenName": "法定相続分",
        "important": ""
    },
    {
        "id": 224,
        "shikenId": 6,
        "no": 14,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "相続開始後の各種手続きにおける下記＜資料＞の空欄（ア）、（イ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。なお、記載のない事項については一切考慮しないこととする。<br><img src='/img/224.png'/>",
        "question2": "",
        "choice1": "（ア）地方裁判所 （イ） ６ヵ月",
        "choice2": "（ア）地方裁判所 （イ）１０ヵ月",
        "choice3": "（ア）家庭裁判所 （イ）１０ヵ月",
        "choice4": "",
        "choice5": "2023.01",
        "categoryName": "相続",
        "rontenName": "相続放棄、相続税の申告期限",
        "important": ""
    },
    {
        "id": 225,
        "shikenId": 6,
        "no": 15,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "福岡明雄さんは、妻から居住用不動産の贈与を受けた。明雄さんは、この居住用不動産の贈与について、贈与税の配偶者控除の適用を受けることを検討しており、ＦＰで税理士でもある木内さんに相談をした。この相談に対する木内さんの回答の空欄（ア）、（イ）にあてはまる数値の組み合わせとして、正しいものはどれか。<br><img src='/img/225.png'/>",
        "question2": "",
        "choice1": "（ア）１,０００   （イ）１０",
        "choice2": "（ア）２,０００   （イ）１０",
        "choice3": "（ア）２,０００   （イ）２０",
        "choice4": "",
        "choice5": "2022.05",
        "categoryName": "相続",
        "rontenName": "贈与税の配偶者控除",
        "important": ""
    },
    {
        "id": 226,
        "shikenId": 6,
        "no": 16,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "<img src='/img/226.png'/><br>ＦＰの馬場さんは、藤原家の２０２４年９月１日現在のバランスシートを作成した。下表の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、＜設例＞に記載のあるデータに基づいて解答することとする。<br><img src='/img/226_2.png'/>",
        "question2": "",
        "choice1": "１,２２０（万円）",
        "choice2": "１,９２０（万円）",
        "choice3": "１,９６０（万円）",
        "choice4": "",
        "choice5": "2022.09改",
        "categoryName": "総合",
        "rontenName": "バランスシート",
        "important": ""
    },
    {
        "id": 227,
        "shikenId": 6,
        "no": 17,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "健吾さんは、６０歳で定年を迎えた後、公的年金の支給が始まる６５歳までの５年間の生活資金に退職一時金の一部を充てようと考えている。仮に、退職一時金のうち６００万円を年利１.０％で複利運用しながら５年間で均等に取り崩すこととした場合、毎年の生活資金に充てることができる最大金額として、正しいものはどれか。なお、下記＜資料＞の３つの係数の中から最も適切な係数を選択して計算し、解答に当たっては万円未満を切り捨てること。また、税金や記載のない事項については一切考慮しないこととする。<br><img src='/img/227.png' style='width:550px'/>",
        "question2": "",
        "choice1": "１１４万円",
        "choice2": "１１７万円",
        "choice3": "１２３万円",
        "choice4": "",
        "choice5": "2022.09",
        "categoryName": "総合",
        "rontenName": "係数の計算",
        "important": ""
    },
    {
        "id": 228,
        "shikenId": 6,
        "no": 18,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "健吾さんは、通常６５歳から支給される老齢基礎年金および老齢厚生年金を繰り下げて受給できることを知り、ＦＰの馬場さんに質問をした。老齢基礎年金および老齢厚生年金の繰下げ受給に関する次の記述のうち、最も不適切なものはどれか。なお、老齢基礎年金および老齢厚生年金の受給要件は満たしているものとする。",
        "question2": "",
        "choice1": "老齢基礎年金および老齢厚生年金を繰り下げて受給した場合の年金額は、繰下げ年数１年当たり７％の割合で増額された額となる。",
        "choice2": "老齢基礎年金と老齢厚生年金は、どちらか一方のみを繰り下げて受給することができる。",
        "choice3": "老齢基礎年金および老齢厚生年金を繰り下げて受給した場合には、一生涯増額された年金を受給することになる。",
        "choice4": "",
        "choice5": "2022.09",
        "categoryName": "総合",
        "rontenName": "年金繰下げ受給",
        "important": ""
    },
    {
        "id": 229,
        "shikenId": 6,
        "no": 19,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "健吾さんの公的年金加入歴は下記のとおりである。仮に、健吾さんが現時点（４６歳）で死亡した場合、健吾さんの死亡時点において妻の恵子さんに支給される公的年金の遺族給付に関する次の記述のうち、最も適切なものはどれか。なお、健吾さんは、入社時（２２歳）から死亡時まで厚生年金保険に加入しているものとし、遺族給付における生計維持要件は満たされているものとする。<br><img src='/img/229.png' style='width: 600px'/>",
        "question2": "",
        "choice1": "遺族基礎年金と中高齢寡婦加算額が加算された遺族厚生年金が支給される。",
        "choice2": "遺族厚生年金と寡婦年金が支給される。",
        "choice3": "中高齢寡婦加算額が加算された遺族厚生年金が支給される。",
        "choice4": "",
        "choice5": "2022.09",
        "categoryName": "総合",
        "rontenName": "公的年金遺族給付",
        "important": ""
    },
    {
        "id": 230,
        "shikenId": 6,
        "no": 20,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "健吾さんは、今後の資産運用に、NISA（少額投資非課税制度）の成長投資枠（特定非課税管理勘定）またはつみたて投資枠（特定累積投資勘定）を利用したいと考えており、FPの馬場さんに質問をした。成長投資枠およびつみたて投資枠に関するFPの馬場さんの次の説明のうち、最も適切なものはどれか。",
        "question2": "",
        "choice1": "「現在、取引のあるＷ証券にＮＩＳＡ口座を開設し成長投資枠を利用すれば、Ｗ証券で保有している投資信託については、そのまま移管することができます。」",
        "choice2": "「成長投資枠では、上場株式を投資対象とすることができます。」",
        "choice3": "「つみたて投資枠では、個人向け国債を投資対象とすることができます。」",
        "choice4": "",
        "choice5": "2022.09改",
        "categoryName": "総合",
        "rontenName": "NISA",
        "important": ""
    },
    {
        "id": 231,
        "shikenId": 7,
        "no": 1,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "税理士資格を有しないファイナンシャル・プランナーが、顧客のために相談業務の延長として確定申告書を作成した場合、その行為が無償であれば税理士法に抵触しない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "関連法規とコンプライアンス",
        "important": "A"
    },
    {
        "id": 232,
        "shikenId": 7,
        "no": 2,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "遺族基礎年金を受給することができる遺族は、国民年金の被保険者等の死亡の当時に、その者によって生計を維持されており、かつ、所定の要件を満たす「子のある妻」または「子」である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "遺族基礎年金",
        "important": "C"
    },
    {
        "id": 233,
        "shikenId": 7,
        "no": 3,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "１週間の所定労働時間が20時間に満たない者は、労働者災害補償保険は適用されるが、原則として雇用保険の被保険者とはならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "労災保険、雇用保険の被保険者",
        "important": "B"
    },
    {
        "id": 234,
        "shikenId": 7,
        "no": 4,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "確定拠出年金の老齢給付金を60歳から受給するには、60歳到達時の通算加入者等期間が10年以上なければならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "確定拠出年金",
        "important": "A"
    },
    {
        "id": 235,
        "shikenId": 7,
        "no": 5,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "住宅金融支援機構と民間金融機関が提携した住宅ローンであるフラット35の融資金利は固定金利であるが、その利率は取扱金融機関がそれぞれ独自に決定している。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "フラット35",
        "important": "B"
    },
    {
        "id": 236,
        "shikenId": 7,
        "no": 6,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "民法および失火の責任に関する法律（失火責任法）において、借家人の軽過失による失火で、借家と隣家を焼失させた場合、借家の家主に対して損害賠償責任を負うが、隣家の所有者に対して損害賠償責任を負わない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "失火責任法",
        "important": "C"
    },
    {
        "id": 237,
        "shikenId": 7,
        "no": 7,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "生命保険の保険料のうち、保険会社が保険契約を維持・管理するための費用に当てられる付加保険料は、予定死亡率および予定利率に基づいて計算される。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生命保険料の仕組み",
        "important": "A"
    },
    {
        "id": 238,
        "shikenId": 7,
        "no": 8,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "一般に、収入保障保険の死亡保険金を年金形式で受け取る場合の受取総額は、一時金で受け取る場合の受取額よりも少なくなる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "収入保障保険",
        "important": "B"
    },
    {
        "id": 239,
        "shikenId": 7,
        "no": 9,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "生命保険契約にある入院特約に基づき、被保険者が病気で入院したことで被保険者が受け取った入院給付金は、非課税である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "給付金と税金",
        "important": "C"
    },
    {
        "id": 240,
        "shikenId": 7,
        "no": 10,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "自動車損害賠償責任保険において、被害者１人当たりの保険金の支払限度額は、死亡の場合で3,000万円、後遺障害の場合で5,000万円である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "自動車損害賠償責任保険",
        "important": "B"
    },
    {
        "id": 241,
        "shikenId": 7,
        "no": 11,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "米国の市場金利が上昇し、日本の市場金利が低下することは、米ドルと円の為替相場においては、一般に、米ドル安、円高の要因となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "為替相場の変動要因",
        "important": "C"
    },
    {
        "id": 242,
        "shikenId": 7,
        "no": 12,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "上場投資信託（ＥＴＦ）は、上場株式と同様に、指値注文や成行注文により売買することができる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "上場投資信託（ETF）",
        "important": "C"
    },
    {
        "id": 243,
        "shikenId": 7,
        "no": 13,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "債券を発行する企業の信用度が低下し、格付が引き下げられた場合、一般に、その債券の価格は下落し、利回りも低下する。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "債券のリスク",
        "important": "A"
    },
    {
        "id": 244,
        "shikenId": 7,
        "no": 14,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "Ｘ社の株価が1,200円、１株当たり純利益が24円、１株当たり年間配当金が12円である場合、Ｘ社株式の配当利回りは、１％である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "株式の投資指標の計算",
        "important": "A"
    },
    {
        "id": 245,
        "shikenId": 7,
        "no": 15,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "預金保険制度の対象金融機関に預け入れた決済用預金は、預入金額にかかわらず、その全額が預金保険制度による保護の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "預金保険制度",
        "important": "B"
    },
    {
        "id": 246,
        "shikenId": 7,
        "no": 16,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "個人が法人からの贈与により取得した財産は、原則として贈与税の課税対象となり、所得税は課されない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "贈与財産の課税",
        "important": "B"
    },
    {
        "id": 247,
        "shikenId": 7,
        "no": 17,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "電車・バス等の交通機関を利用して通勤している給与所得者が、勤務先から受ける通勤手当の所得税法上の非課税限度額は、月額10万円である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "非課税所得",
        "important": "B"
    },
    {
        "id": 248,
        "shikenId": 7,
        "no": 18,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "所得税において、事業的規模で行われている賃貸マンションの貸付で得た所得は、不動産所得となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "不動産所得",
        "important": "B"
    },
    {
        "id": 249,
        "shikenId": 7,
        "no": 19,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税における基礎控除の額は、納税者の合計所得金額の多寡にかかわらず、一律で38万円である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "基礎控除",
        "important": "B"
    },
    {
        "id": 250,
        "shikenId": 7,
        "no": 20,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "夫が生計を一にする妻の負担すべき国民年金の保険料を支払った場合、その支払った金額は、夫の所得税において、社会保険料控除の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "社会保険料控除",
        "important": "B"
    },
    {
        "id": 251,
        "shikenId": 7,
        "no": 21,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "アパートやマンションを貸して家賃収入を得ようとする場合、宅地建物取引業の免許が必要となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "宅地建物取引業法",
        "important": "A"
    },
    {
        "id": 252,
        "shikenId": 7,
        "no": 22,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "不動産取引において、買主が売主に解約手付を交付したときは、相手方が契約の履行に着手するまでは、買主はその手付を放棄することで、売主はその手付を買主に現実に提供することで、契約を解除することができる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "手付金",
        "important": "B"
    },
    {
        "id": 253,
        "shikenId": 7,
        "no": 23,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "都市計画法の規定によれば、市街化区域内で行う開発行為は、その規模にかかわらず、都道府県知事等の許可が必要である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "開発許可",
        "important": "B"
    },
    {
        "id": 254,
        "shikenId": 7,
        "no": 24,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "不動産取得税は、生前贈与や相続により不動産を取得したときには課されない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産取得税",
        "important": "B"
    },
    {
        "id": 255,
        "shikenId": 7,
        "no": 25,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "土地の譲渡所得のうち、その土地を譲渡した日の属する年の１月１日における所有期間が10年以下のものは、短期譲渡所得に区分される。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産の譲渡と所有期間",
        "important": "B"
    },
    {
        "id": 256,
        "shikenId": 7,
        "no": 26,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "相続人が相続の放棄をする場合は、自己のために相続の開始があったことを知った時から、原則として10カ月以内に、家庭裁判所にその旨を申述しなければならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続の放棄",
        "important": "C"
    },
    {
        "id": 257,
        "shikenId": 7,
        "no": 27,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "被相続人が遺言により相続分や遺産分割方法の指定をしていない場合、共同相続人は全員の協議により遺産を分割することができ、必ずしも法定相続分に従う必要はない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "遺産分割協議",
        "important": "B"
    },
    {
        "id": 258,
        "shikenId": 7,
        "no": 28,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "本年中に相続や遺贈により財産を取得した者が、相続開始前５年以内に被相続人から暦年課税方式の贈与により取得した財産は、相続税額の計算上、相続財産に加算される。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "生前贈与加算",
        "important": "C"
    },
    {
        "id": 259,
        "shikenId": 7,
        "no": 29,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "「直系尊属から教育資金の一括贈与を受けた場合の贈与税の非課税」は、贈与を受ける年の受贈者の合計所得金額が1,000万円を超える場合、適用を受けることができない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "贈与税非課税（教育資金）",
        "important": "B"
    },
    {
        "id": 260,
        "shikenId": 7,
        "no": 30,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "相続財産の評価において、相続開始時に保険事故が発生していない生命保険契約に関する権利の価額は、原則として、既払込保険料相当額により評価する。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "生命保険契約に関する権利",
        "important": "C"
    },
    {
        "id": 261,
        "shikenId": 7,
        "no": 31,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "借入金額2,000万円、利率（年率・複利）1％、返済期間15年、元利均等返済でローンを組む場合、毎年の返済額は、下記の＜資料＞の係数を使用して算出すると、（　　）である。<br>＜資料＞利率（年率）１％・期間15年の各種係数<br><table><tr><td style='border:solid 1px #333; text-align:center'>現価係数</td><td style='border:solid 1px #333; text-align:center'>資本回収係数</td><td style='border:solid 1px #333; text-align:center'>減債基金係数</td></tr><tr><td style='border:solid 1px #333; text-align:center'>0.8613</td><td style='border:solid 1px #333; text-align:center'>0.0721</td><td style='border:solid 1px #333; text-align:center'>0.0621</td></tr></table>",
        "choice1": "1,242,000円",
        "choice2": "1,442,000円",
        "choice3": "1,935,059円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "係数計算",
        "important": "A"
    },
    {
        "id": 262,
        "shikenId": 7,
        "no": 32,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "全国健康保険協会管掌健康保険の被保険者に支給される傷病手当金の額は、１日につき、原則として、傷病手当金の支給を始める日の属する月以前の直近の継続した（ ① ）の各月の標準報酬月額の平均額を30で除した額に、（ ② ）を乗じた額である。",
        "choice1": "①　12カ月間　　②　２分の１",
        "choice2": "①　12カ月間　　②　３分の２",
        "choice3": "①　６カ月間　　②　４分の３",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "傷病手当金",
        "important": "B"
    },
    {
        "id": 263,
        "shikenId": 7,
        "no": 33,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "雇用保険の基本手当を受給するためには、倒産、解雇および雇止めなどの場合を除き、原則として、離職の日以前（ ① ）に被保険者期間が通算して（ ② ）以上あることなどの要件を満たす必要がある。",
        "choice1": "①　１年間　　②　６カ月",
        "choice2": "①　１年間　　②　12カ月",
        "choice3": "①　２年間　　②　12カ月",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "雇用保険の基本手当",
        "important": "A"
    },
    {
        "id": 264,
        "shikenId": 7,
        "no": 34,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "厚生年金保険の被保険者期間が原則として（ ① ）以上ある者が、老齢厚生年金の受給権を取得した当時、当該受給権者と生計を同じくしている（ ② ）未満の配偶者が所定の要件を満たしている場合、当該受給権者が受給する老齢厚生年金に加給年金額が加算される。",
        "choice1": "①　10年　　②　60歳",
        "choice2": "①　20年　　②　65歳",
        "choice3": "①　25年　　②　65歳",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "加給年金",
        "important": "B"
    },
    {
        "id": 265,
        "shikenId": 7,
        "no": 35,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "住宅ローンの返済方法で、元利均等返済方式と元金均等返済方式とで利息を含めた総返済金額を比較すると、返済期間や金利などの他の条件が同一である場合、通常、その額は、（　　）。",
        "choice1": "元利均等返済方式のほうが多い",
        "choice2": "元金均等返済方式のほうが多い",
        "choice3": "どちらも同じ額である",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "元利均等と元金均等",
        "important": "B"
    },
    {
        "id": 266,
        "shikenId": 7,
        "no": 36,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "国内で事業を行う生命保険会社が破綻した場合、生命保険契約者保護機構による補償の対象となる保険契約は、高予定利率契約を除き、（ ① ）の（ ② ）まで補償される。",
        "choice1": "①　責任準備金等　　②　90％",
        "choice2": "①　死亡保険金額　　②　100％",
        "choice3": "①　責任準備金等　　②　80％",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "保険契約者保護",
        "important": "B"
    },
    {
        "id": 267,
        "shikenId": 7,
        "no": 37,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "定期保険特約付終身保険では、定期保険特約の保険金額を同額で自動更新すると、通常は、更新後の保険料が、更新前（　　）。",
        "choice1": "よりも安くなる",
        "choice2": "と変わらない",
        "choice3": "よりも高くなる",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "定期保険の更新",
        "important": "C"
    },
    {
        "id": 268,
        "shikenId": 7,
        "no": 38,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "生命保険契約の契約者は、契約者貸付制度を利用することで、契約している生命保険の（　　）の一定の範囲内で保険会社から貸付を受けることができる。",
        "choice1": "解約返戻金額",
        "choice2": "既払込保険料総額",
        "choice3": "死亡保険金額",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "契約者貸付",
        "important": "C"
    },
    {
        "id": 269,
        "shikenId": 7,
        "no": 39,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "所得税において、個人が本年中に締結した生命保険契約に基づく支払保険料のうち、（　　）に係る保険料は、介護医療保険料控除の対象となる。",
        "choice1": "傷害特約",
        "choice2": "定期保険特約",
        "choice3": "先進医療特約",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生命保険料控除",
        "important": "B"
    },
    {
        "id": 270,
        "shikenId": 7,
        "no": 40,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "家族傷害保険に付帯された個人賠償責任補償特約においては、（　　）により損害賠償責任を負った場合は補償の対象とならない。",
        "choice1": "同居する家族に誤ってけがを負わせた事故",
        "choice2": "別居の未婚の子が自転車で走行中に起こした事故",
        "choice3": "飼い犬が他人を噛んでけがを負わせた事故",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "家族傷害保険",
        "important": "C"
    },
    {
        "id": 271,
        "shikenId": 7,
        "no": 41,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "一定期間内に国内で生産された財やサービスの付加価値の合計額を（ ① ）といい、その統計は（ ② ）が作成し、公表する。",
        "choice1": "①　国民総生産（ＧＮＰ）　　②　内閣府",
        "choice2": "①　国内総生産（ＧＤＰ）　　②　日本銀行",
        "choice3": "①　国内総生産（ＧＤＰ）　　②　内閣府",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "国内総生産（GDP）",
        "important": "B"
    },
    {
        "id": 272,
        "shikenId": 7,
        "no": 42,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "追加型株式投資信託を基準価額１万3,000円で１万口購入した後、最初の決算時に１万口当たり400円の収益分配金が支払われ、分配落ち後の基準価額が１万2,800円となった場合、その収益分配金のうち、普通分配金は（ ① ）であり、元本払戻金（特別分配金）は（ ② ）である。",
        "choice1": "①　400円　　②　0円",
        "choice2": "①　100円　　②　300円",
        "choice3": "①　200円　　②　200円",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "投資信託の分配金の計算",
        "important": "A"
    },
    {
        "id": 273,
        "shikenId": 7,
        "no": 43,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "表面利率（クーポンレート）２％、残存期間４年の固定利付債券を、額面100円当たり104円で購入し、２年後に額面100円当たり103円で売却した場合の所有期間利回り（年率・単利）は、（　　）％である。なお、税金や手数料等は考慮しないものとし、答は表示単位の小数点以下第３位を四捨五入している。",
        "choice1": "0.96",
        "choice2": "1.44",
        "choice3": "2.40",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "債券の利回りの計算",
        "important": "A"
    },
    {
        "id": 274,
        "shikenId": 7,
        "no": 44,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "Ａ資産の期待収益率が2.0％、Ｂ資産の期待収益率が4.0％の場合に、Ａ資産を60％、Ｂ資産を40％の割合で組み入れたポートフォリオの期待収益率は、（　　）となる。",
        "choice1": "1.6％",
        "choice2": "2.4％",
        "choice3": "2.8％",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "期待収益率の計算",
        "important": "C"
    },
    {
        "id": 275,
        "shikenId": 7,
        "no": 45,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "為替予約を締結していない外貨定期預金の場合、満期時の為替レートが預入時の為替レートに比べて（ ① ）になれば、円換算の利回りは（ ② ）なる。",
        "choice1": "①　円安　　②　高く",
        "choice2": "①　円安　　②　低く",
        "choice3": "①　円高　　②　高く",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "外貨預金",
        "important": "B"
    },
    {
        "id": 276,
        "shikenId": 7,
        "no": 46,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "国内において支払を受ける預貯金の利子は、原則として、国税（復興特別所得税を含む）と地方税を合わせて（ ① ）の税率による（ ② ）分離課税の対象となる。",
        "choice1": "①　15.315％　　②　申告",
        "choice2": "①　20.315％　　②　申告",
        "choice3": "①　20.315％　　②　源泉",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "利子所得",
        "important": "B"
    },
    {
        "id": 277,
        "shikenId": 7,
        "no": 47,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "給与所得者が、30年間勤務した会社を定年退職し、退職金3,000万円の支払を受けた。この場合、所得税の退職所得の金額を計算する際の退職所得控除額は、（　　）となる。",
        "choice1": "800万円＋70万円×（30年－20年）×1/2=1,150万円",
        "choice2": "800万円＋40万円×（30年－20年）＝1,200万円",
        "choice3": "800万円＋70万円×（30年－20年）＝1,500万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "退職所得の計算",
        "important": "A"
    },
    {
        "id": 278,
        "shikenId": 7,
        "no": 48,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "Ａさんの本年分の各種所得の金額が下記の＜資料＞のとおりであった場合、損益通算後の総所得金額は（　　）となる。なお、各種所得の金額に付されている「▲」は、その所得に損失が生じていることを表すものとする。<br>＜資料＞Aさんの本年分の各種所得の金額<br><table><tr><td style='border:solid 1px #333;'>不動産所得の金額</td><td style='border:solid 1px #333;text-align:right'>850万円</td></tr><tr><td style='border:solid 1px #333;'>雑所得の金額</td><td style='border:solid 1px #333;text-align:right'>▲50万円</td></tr><tr><td style='border:solid 1px #333;'>事業所得の金額（株式等に係るものを除く）</td><td style='border:solid 1px #333;text-align:right'>▲200万円</td></tr></table>",
        "choice1": "600万円",
        "choice2": "650万円",
        "choice3": "800万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "総所得金額の計算",
        "important": "A"
    },
    {
        "id": 279,
        "shikenId": 7,
        "no": 49,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税の確定申告をしなければならない者は、原則として、所得が生じた年の翌年の（ ① ）から（ ② ）までの間に、納税地の所轄税務署長に対して確定申告書の提出が必要となる。",
        "choice1": "①　２月１日　　②　３月15日",
        "choice2": "①　２月16日　　②　３月15日",
        "choice3": "①　２月１日　　②　３月31日",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "所得税の確定申告の期限",
        "important": "C"
    },
    {
        "id": 280,
        "shikenId": 7,
        "no": 50,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "年末調整の対象となる給与所得者は、年末調整により、（　　）の適用を受けることができる。",
        "choice1": "雑損控除",
        "choice2": "医療費控除",
        "choice3": "生命保険料控除",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "年末調整",
        "important": "B"
    },
    {
        "id": 281,
        "shikenId": 7,
        "no": 51,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "都道府県地価調査の基準地の標準価格は、毎年（ ① ）を価格判定の基準日として調査され、都道府県知事により毎年（ ② ）頃に公表される。",
        "choice1": "①　１月１日　　②　３月",
        "choice2": "①　１月１日　　②　７月",
        "choice3": "①　７月１日　　②　９月",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "土地の価格",
        "important": "A"
    },
    {
        "id": 282,
        "shikenId": 7,
        "no": 52,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "建築基準法上、都市計画区域および準都市計画区域内において、建築物の敷地は、原則として、幅員（ ① ）以上の道路に（ ② ）以上接していなければならない。",
        "choice1": "①　４ｍ　　②　１ｍ",
        "choice2": "①　４ｍ　　②　２ｍ",
        "choice3": "①　６ｍ　　②　３ｍ",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "接道義務",
        "important": "A"
    },
    {
        "id": 283,
        "shikenId": 7,
        "no": 53,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "建築物が防火地域および準防火地域にわたる場合においては、原則として、その全部について（ ① ）内の建築物に関する規定が適用される。また、建築物の敷地が２つの異なる用途地域にまたがる場合、（ ② ）の用途制限が敷地全体に適用される。",
        "choice1": "①　準防火地域　　②　より厳しい地域",
        "choice2": "①　防火地域　　②　過半の属する地域",
        "choice3": "①　過半の属する地域　　②　より緩い地域",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "防火地域、用途地域",
        "important": "B"
    },
    {
        "id": 284,
        "shikenId": 7,
        "no": 54,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "建物の区分所有等に関する法律（区分所有法）において、集会において、区分所有者および議決権の各（　　）以上の多数により、区分所有建物を取り壊し、その敷地上に新たに建物を建築する旨の決議（建替え決議）をすることができる。",
        "choice1": "２分の１",
        "choice2": "４分の３",
        "choice3": "５分の４",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "区分所有法",
        "important": "A"
    },
    {
        "id": 285,
        "shikenId": 7,
        "no": 55,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "個人が「特定の居住用財産の買換えの場合の長期譲渡所得の課税の特例」の適用を受けるための要件には、譲渡した年の１月１日時点の所有期間が（ ① ）超であること、譲渡対価が（ ② ）以下であることなどがある。",
        "choice1": "①　10 年　　②　１億円",
        "choice2": "①　５年　　②　１億円",
        "choice3": "①　10 年　　②　6 , 000 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "特定居住用財産の買換えの特例",
        "important": "C"
    },
    {
        "id": 286,
        "shikenId": 7,
        "no": 56,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "宅地が「小規模宅地等についての相続税の課税価格の計算の特例」における特定居住用宅地等に該当する場合、（ ① ）を限度面積として、評価額の（ ② ）が減額される。",
        "choice1": "①　200㎡　　②　50％",
        "choice2": "①　400㎡　　②　80％",
        "choice3": "①　330㎡　　②　80％",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "小規模宅地等の特例",
        "important": "A"
    },
    {
        "id": 287,
        "shikenId": 7,
        "no": 57,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "下記の〈親族関係図〉において、Ａさんが亡くなった場合の相続における、妻Ｂさんの法定相続分は、（　　）である。<br>＜親族関係図＞<br><img src=\"/img/1_1.png\" style='width:400px'/>",
        "choice1": "２分の１",
        "choice2": "３分の２",
        "choice3": "４分の３",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "法定相続分",
        "important": "A"
    },
    {
        "id": 288,
        "shikenId": 7,
        "no": 58,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "相続税の計算においては、相続人が受け取った死亡保険金の非課税限度額を、「（ ① ）×法定相続人の数」の算式により算出するが、相続人のうち相続の放棄をした者がいる場合、当該法定相続人の数は、相続を放棄した者を（ ② ）人数とされる。",
        "choice1": "①　500万円　　②　含む",
        "choice2": "①　500万円　　②　含まない",
        "choice3": "①　600万円　　②　含まない",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "死亡保険金の非課税限度額",
        "important": "B"
    },
    {
        "id": 289,
        "shikenId": 7,
        "no": 59,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "本年中に相続時精算課税の適用を受けて贈与を受けた場合、暦年課税とは別枠で適用できる基礎控除および特別控除（累計（ ① ））を差し引いた後の金額に対して一律（ ② ）％の税率により計算した贈与税が課税される。",
        "choice1": "①　1,500万円　　②　15",
        "choice2": "①　1,500万円　　②　20",
        "choice3": "①　2,500万円　　②　20",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続時精算課税制度",
        "important": "B"
    },
    {
        "id": 290,
        "shikenId": 7,
        "no": 60,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "自用地としての価額が6,000万円、借地権割合が60％、借家権割合が30％、賃貸割合が100％の貸家建付地の相続税評価額は、（　　）である。",
        "choice1": "2,400万円",
        "choice2": "3,600万円",
        "choice3": "4,920万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "貸家建付地の計算",
        "important": "A"
    },
    {
        "id": 291,
        "shikenId": 11,
        "no": 1,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "ファイナンシャル・プランナーは、顧客からの依頼であっても、公正証書遺言の作成時に証人となることはできない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "関連法規とコンプライアンス",
        "important": "A"
    },
    {
        "id": 292,
        "shikenId": 11,
        "no": 2,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "後期高齢者医療広域連合の区域内に住所を有する70歳以上の者または60歳以上の者で一定の障害認定を受けた者は、後期高齢者医療制度の被保険者となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "後期高齢者医療制度",
        "important": "B"
    },
    {
        "id": 293,
        "shikenId": 11,
        "no": 3,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "老齢厚生年金の繰下げ支給の申出は、老齢基礎年金の繰下げ支給の申出と同時に行う必要がある。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "老齢厚生年金の繰下げ支給",
        "important": "B"
    },
    {
        "id": 294,
        "shikenId": 11,
        "no": 4,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "確定拠出年金の個人型年金における老齢給付金を一時金で受け取った場合、当該老齢給付金は、一時所得として所得税の課税対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "確定拠出年金",
        "important": "A"
    },
    {
        "id": 295,
        "shikenId": 11,
        "no": 5,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "住宅ローンの一部繰上げ返済には、一般に、返済期間を変更せずに毎月の返済額を減額する返済額軽減型と、毎月の返済額を変更せずに残りの返済期間を短くする期間短縮型がある。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "住宅ローンの繰上げ返済",
        "important": "C"
    },
    {
        "id": 296,
        "shikenId": 11,
        "no": 6,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "国内で事業を行う少額短期保険業者と結んだ保険契約は、生命保険契約者保護機構および損害保険契約者保護機構による補償の対象とならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "保険契約者保護",
        "important": "B"
    },
    {
        "id": 297,
        "shikenId": 11,
        "no": 7,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "学資（こども）保険は、保険期間中に契約者が死亡した場合、死亡時点における解約返戻金相当額が支払われることで保険契約が消滅する。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "学資（こども）保険",
        "important": "B"
    },
    {
        "id": 298,
        "shikenId": 11,
        "no": 8,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "リビング・ニーズ特約は、被保険者の余命が６カ月以内と判断された場合に、所定の範囲内で死亡保険金の一部または全部を生前に受け取ることができる特約である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "リビング・ニーズ特約",
        "important": "C"
    },
    {
        "id": 299,
        "shikenId": 11,
        "no": 9,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "逓減定期保険や逓増定期保険は、保険期間の経過に伴い保険料が所定の割合で減少または増加するが、死亡保険金額は保険期間を通じて一定である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "逓減定期保険、逓増定期保険",
        "important": "C"
    },
    {
        "id": 300,
        "shikenId": 11,
        "no": 10,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "自動車保険の車両保険は、一般に、被保険自動車が台風や大雨により水没したことによる損害は、補償の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "任意の自動車保険（車両保険）",
        "important": "A"
    },
    {
        "id": 301,
        "shikenId": 11,
        "no": 11,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "一般に、景気動向指数のコンポジット・インデックス（ＣＩ）の一致指数が上昇しているときは、景気の拡張局面とされる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "景気動向指数",
        "important": "B"
    },
    {
        "id": 302,
        "shikenId": 11,
        "no": 12,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "債券の信用格付においてトリプルＢＢＢ格相当以下である場合には、一般に、投機的格付とされる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "債券のリスク",
        "important": "A"
    },
    {
        "id": 303,
        "shikenId": 11,
        "no": 13,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "上場株式の売買において、普通取引では約定日の翌営業日（約定当日を含め２営業日目）に決済が行われる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "株式の売買",
        "important": "B"
    },
    {
        "id": 304,
        "shikenId": 11,
        "no": 14,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "外貨預金の預入時に、預金者が円貨を外貨に換える場合の適用為替レートは、預入金融機関が提示するＴＴＢである。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "外貨預金",
        "important": "B"
    },
    {
        "id": 305,
        "shikenId": 11,
        "no": 15,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "国内銀行に預け入れられた外貨預金は、預金保険制度における保護の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "預金保険制度",
        "important": "B"
    },
    {
        "id": 306,
        "shikenId": 11,
        "no": 16,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "所得税において、納税者本人の合計所得金額が1,000万円を超える場合や生計を一にする配偶者の合計所得金額が48万円を超える場合は配偶者控除の適用を受けることはできない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "配偶者控除",
        "important": "B"
    },
    {
        "id": 307,
        "shikenId": 11,
        "no": 17,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税において、老齢基礎年金や老齢厚生年金は非課税所得となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "所得の分類",
        "important": "B"
    },
    {
        "id": 308,
        "shikenId": 11,
        "no": 18,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "土地は減価償却資産ではない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "減価償却資産",
        "important": "B"
    },
    {
        "id": 309,
        "shikenId": 11,
        "no": 19,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税における医療費控除の控除額は、その年中に支払った医療費の金額（保険金等により補てんされる金額を除く）の合計額から、その年分の総所得金額等の合計額の10％相当額または５万円のいずれか低いほうの金額を控除して算出される。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "医療費控除",
        "important": "B"
    },
    {
        "id": 310,
        "shikenId": 11,
        "no": 20,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "納税者が生計を一にする配偶者に係る確定拠出年金の個人型年金の掛金を支払う場合、その負担した掛金は、納税者本人に係る所得税の小規模企業共済等掛金控除の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "小規模企業共済等掛金控除",
        "important": "B"
    },
    {
        "id": 311,
        "shikenId": 11,
        "no": 21,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "不動産登記には公信力が認められていないため、登記記録上の権利者が真実の権利者と異なっている場合に登記記録を信頼して取引をしたとしても、原則として法的に保護されない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産登記",
        "important": "A"
    },
    {
        "id": 312,
        "shikenId": 11,
        "no": 22,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "定期建物賃貸借契約（定期借家契約）では、貸主に正当事由がなければ、貸主は、借主からの契約の更新の請求を拒むことができない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "借家契約の更新",
        "important": "B"
    },
    {
        "id": 313,
        "shikenId": 11,
        "no": 23,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "都市計画法において、市街化調整区域とは、おおむね10年以内に計画的に市街化を図るべき区域をいう。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "都市計画区域",
        "important": "B"
    },
    {
        "id": 314,
        "shikenId": 11,
        "no": 24,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "新築の戸建て住宅の取得に対する不動産取得税の課税標準の算定上、「不動産取得税の課税標準の特例」の適用を受けることにより、固定資産税評価額から最高で1,000万円を控除することができる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産取得税",
        "important": "B"
    },
    {
        "id": 315,
        "shikenId": 11,
        "no": 25,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "「居住用財産を譲渡した場合の3,000万円の特別控除」は、自己が居住していた家屋を配偶者や子に譲渡した場合では、適用を受けることができない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "居住用財産の譲渡の特例（3,000万円特別控除）",
        "important": "B"
    },
    {
        "id": 316,
        "shikenId": 11,
        "no": 26,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "特別養子縁組によって養子となった者については、原則として、養子縁組の成立によって、実方の父母との法律上の親族関係が終了する。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "養子縁組",
        "important": "C"
    },
    {
        "id": 317,
        "shikenId": 11,
        "no": 27,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "相続税の課税価格の計算上、相続人が負担した葬式の際の香典返戻費用は、相続財産の価額から債務控除できる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "債務控除",
        "important": "B"
    },
    {
        "id": 318,
        "shikenId": 11,
        "no": 28,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "相続税額の計算において、「配偶者に対する相続税額の軽減」の適用を受けることにより、納付すべき相続税額が全くなかった場合には、相続税の申告書を提出する必要はない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "配偶者の相続税額の軽減",
        "important": "B"
    },
    {
        "id": 319,
        "shikenId": 11,
        "no": 29,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "被相続人の相続開始前に死亡している被相続人の子を代襲して相続人となった被相続人の孫が、相続により財産を取得した場合には、相続税額の計算上、相続税額の２割加算の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続税額の2割加算",
        "important": "B"
    },
    {
        "id": 320,
        "shikenId": 11,
        "no": 30,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "書面によらない贈与契約は、その履行前であれば、各当事者は契約の解除をすることができる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "贈与契約",
        "important": "C"
    },
    {
        "id": 321,
        "shikenId": 11,
        "no": 31,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "500万円を準備するために、５年間、毎年均等に積み立て、利率（年率）３％で複利運用する場合、必要となる毎年の積立金額は、下記の＜資料＞の係数を使用して算出すると（　　）である。<br><img src=\"/img/11_1.png\" style='width:400px'/>",
        "choice1": "862,589円",
        "choice2": "942,000円",
        "choice3": "1,092,000円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "係数計算",
        "important": "A"
    },
    {
        "id": 322,
        "shikenId": 11,
        "no": 32,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "健康保険の任意継続被保険者となるためには、健康保険の被保険者資格を喪失した日の前日まで継続して（ ① ）以上被保険者であった者が、原則として、資格喪失の日から（ ② ）以内に任意継続被保険者の資格取得手続を行う必要がある。",
        "choice1": "①　２カ月　　②　14日",
        "choice2": "①　２カ月　　②　20日",
        "choice3": "①　１年　　②　21日",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "任意継続被保険者",
        "important": "B"
    },
    {
        "id": 323,
        "shikenId": 11,
        "no": 33,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "雇用保険の教育訓練給付金のうち、一般教育訓練に係る教育訓練給付金の額は、教育訓練施設に支払った教育訓練経費の（ ① ）であり、（ ② ）が限度となる。",
        "choice1": "①　10％　　②　20万円",
        "choice2": "①　20％　　②　20万円",
        "choice3": "①　20％　　②　10万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "教育訓練給付",
        "important": "C"
    },
    {
        "id": 324,
        "shikenId": 11,
        "no": 34,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "障害基礎年金の保険料納付要件は、原則として、初診日の前日において、初診日の属する月の前々月までの国民年金の被保険者期間のうち、保険料納付済期間（保険料免除期間を含む）が（　　）以上あることである。",
        "choice1": "２分の１",
        "choice2": "３分の２",
        "choice3": "４分の３",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "障害基礎年金",
        "important": "B"
    },
    {
        "id": 325,
        "shikenId": 11,
        "no": 35,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "日本学生支援機構が扱う貸与型奨学金には、（ ① ）第一種奨学金と（ ② ）第二種奨学金がある。",
        "choice1": "①　利子付（在学中は無利子）の　　②　利子付（在学中も有利子）の",
        "choice2": "①　無利子の　　②　利子付（在学中は無利子）の",
        "choice3": "①　返済義務のない　　②　無利子の",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "日本学生支援機構の奨学金",
        "important": "C"
    },
    {
        "id": 326,
        "shikenId": 11,
        "no": 36,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "保険業法で定められた保険会社の健全性を示す（ ① ）は、保険金等の支払余力をどの程度有するかを示す指標であり、この値が（ ② ）を下回った場合、監督当局による早期是正措置の対象となる。",
        "choice1": "①　自己資本比率　　②　100％",
        "choice2": "①　ソルベンシー・マージン比率　　②　300％",
        "choice3": "①　ソルベンシー・マージン比率　　②　200％",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "ソルベンシー・マージン",
        "important": "C"
    },
    {
        "id": 327,
        "shikenId": 11,
        "no": 37,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "生命保険契約を、現在加入しているものから契約転換制度を利用して新たな契約に転換する場合、転換後の保険料は（ ① ）の保険料率が適用され、一般に、転換する際には（ ② ）である。",
        "choice1": "①　転換時　　②　告知または診査が必要",
        "choice2": "①　転換時　　②　告知および診査は不要",
        "choice3": "①　転換前の契約時　　②　告知および診査は不要",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "契約転換",
        "important": "C"
    },
    {
        "id": 328,
        "shikenId": 11,
        "no": 38,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "医療保険等に付加される先進医療特約で対象となるのは、（　　）時点において厚生労働大臣により定められている先進医療である。",
        "choice1": "申込日",
        "choice2": "責任開始日",
        "choice3": "療養を受けた日",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "先進医療特約",
        "important": "B"
    },
    {
        "id": 329,
        "shikenId": 11,
        "no": 39,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "生命保険契約において、契約者（＝保険料負担者）が夫、被保険者が（ ① ）、死亡保険金受取人が（ ② ）である場合、被保険者の死亡により死亡保険金受取人が受け取る死亡保険金は、相続税の課税対象となる。",
        "choice1": "①　妻　　②　夫",
        "choice2": "①　夫　　②　子",
        "choice3": "①　妻　　②　子",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生命保険金の課税",
        "important": "B"
    },
    {
        "id": 330,
        "shikenId": 11,
        "no": 40,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "建物に係る地震保険の保険金額は、火災保険の保険金額の（ ① ）の範囲内で、（ ② ）が上限となる。",
        "choice1": "①　30％から50％まで　　②　5,000万円",
        "choice2": "①　30％から50％まで　　②　3,000万円",
        "choice3": "①　50％から80％まで　　②　3,000万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "地震保険",
        "important": "A"
    },
    {
        "id": 331,
        "shikenId": 11,
        "no": 41,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "全国の世帯が購入する家計に関する財およびサービスの価格等を総合した物価の変動を時系列的に測定する（　　）は、総務省が公表している。",
        "choice1": "景気動向指数",
        "choice2": "消費者態度指数",
        "choice3": "消費者物価指数",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "消費者物価指数",
        "important": "C"
    },
    {
        "id": 332,
        "shikenId": 11,
        "no": 42,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "投資信託での（　　）運用は、企業の成長性が市場平均よりも高いと見込まれる銘柄に投資する運用手法とされる。",
        "choice1": "グロース",
        "choice2": "パッシブ",
        "choice3": "バリュー",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "投資信託のタイプ",
        "important": "A"
    },
    {
        "id": 333,
        "shikenId": 11,
        "no": 43,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "上場企業Ｘ社の下記の＜資料＞に基づいて計算したＸ社株式の株価収益率（ＰＥＲ）は（ ① ）、株価純資産倍率（ＰＢＲ）は（ ② ）である。<br><img src=\"/img/11_2.png\" style='width:400px'/>",
        "choice1": "①　15倍　　②　1.5倍",
        "choice2": "①　10倍　　②　1.5倍",
        "choice3": "①　1.5倍　　②　15倍",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "株式の投資指標の計算",
        "important": "A"
    },
    {
        "id": 334,
        "shikenId": 11,
        "no": 44,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税において、新ＮＩＳＡ口座内で生じた上場株式の譲渡損失の金額は、特定口座内の上場株式の譲渡益の金額と損益を通算することが（ ① ）。なお、譲渡益が発生している場合に、非課税とするためには、確定申告は（ ② ）。",
        "choice1": "①　できる　　②　必要である",
        "choice2": "①　できない　　②　不要である",
        "choice3": "①　できない　　②　必要である",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "新NISA",
        "important": "A"
    },
    {
        "id": 335,
        "shikenId": 11,
        "no": 45,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "日本投資者保護基金は、会員である金融商品取引業者が破綻し、分別管理の義務に違反したことによって、一般顧客から預託を受けていた有価証券・金銭を返還することができない場合、一定の範囲の取引を対象に一般顧客１人につき（　　）を上限に金銭による補償を行う。",
        "choice1": "1,000万円",
        "choice2": "2,000万円",
        "choice3": "3,000万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "日本投資者保護基金",
        "important": "C"
    },
    {
        "id": 336,
        "shikenId": 11,
        "no": 46,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "上場株式等の配当所得につき申告分離課税を選択した場合、その税率は、所得税および復興特別所得税と住民税の合計で（ ① ）であり、上場株式等の譲渡損失の金額と損益通算することができる。この場合、配当控除の適用を受けることが（ ② ）。",
        "choice1": "①　15.315％　　②　できる",
        "choice2": "①　20.315％　　②　できない",
        "choice3": "①　20.42％　　②　できない",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "配当所得",
        "important": "C"
    },
    {
        "id": 337,
        "shikenId": 11,
        "no": 47,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "所得税において、本年中に取得した建物（鉱業用減価償却資産等を除く）における減価償却の方法は、（　　）である。",
        "choice1": "定額法",
        "choice2": "定率法",
        "choice3": "定額法または定率法",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "減価償却方法",
        "important": "B"
    },
    {
        "id": 338,
        "shikenId": 11,
        "no": 48,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "課税総所得金額300万円に対する所得税額（復興特別所得税額を含まない）は、下記の＜資料＞を使用して算出すると、（　　）である。<br><img src=\"/img/11_3.png\" style='width:400px'/>",
        "choice1": "97,500円",
        "choice2": "202,500円",
        "choice3": "300,000円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "所得税の計算",
        "important": "C"
    },
    {
        "id": 339,
        "shikenId": 11,
        "no": 49,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "確定申告を要する納税者Ａさんが本年９月25日に死亡した。Ａさんの相続人は、同日にＡさんの相続の開始があったことを知ったため、本年分のＡさんの所得について（　　）（休業日の場合は翌営業日）までにＡさんの死亡当時の納税地の所轄税務署長に対して所得税の準確定申告書を提出しなければならない。",
        "choice1": "本年12月25日",
        "choice2": "翌年１月25日",
        "choice3": "翌年３月25日",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "準確定申告",
        "important": "C"
    },
    {
        "id": 340,
        "shikenId": 11,
        "no": 50,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "青色申告者の所得税の計算において、損益通算してもなお控除しきれない損失の金額（純損失の金額）が生じた場合、所定の要件を満たすことで、その損失の金額を翌年以後（　　）にわたって繰り越して、各年分の所得金額から控除することができる。",
        "choice1": "３年間",
        "choice2": "５年間",
        "choice3": "10年間",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "青色申告",
        "important": "B"
    },
    {
        "id": 341,
        "shikenId": 11,
        "no": 51,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "土地・家屋における固定資産税の課税標準となる価格は、原則として、（　　）ごとの基準年度において評価替えが行われる。",
        "choice1": "２年",
        "choice2": "３年",
        "choice3": "５年",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "固定資産税評価額",
        "important": "A"
    },
    {
        "id": 342,
        "shikenId": 11,
        "no": 52,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "土地の有効活用方式のうち、一般に、土地所有者が土地を拠出し、デベロッパーが建設資金を負担してマンション等を建設し、それぞれの出資比率に応じて土地や建物に係る権利を取得する方式を、（　　）という。",
        "choice1": "事業受託方式",
        "choice2": "建設協力金方式",
        "choice3": "等価交換方式",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "土地の有効活用方式",
        "important": "B"
    },
    {
        "id": 343,
        "shikenId": 11,
        "no": 53,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "農地法によれば、所有する農地を自宅の建築を目的として宅地に転用する場合、原則として（ ① ）の許可を受けなければならないが、市街化区域内にある農地において、あらかじめ（ ② ）に届出のある場合は、この限りでない。",
        "choice1": "①　農業委員会　　②　市町村長",
        "choice2": "①　都道府県知事等　　②　市町村長",
        "choice3": "①　都道府県知事等　　②　農業委員会",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "農地法",
        "important": "B"
    },
    {
        "id": 344,
        "shikenId": 11,
        "no": 54,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "個人が土地・建物を譲渡した場合の譲渡所得の金額の計算において、譲渡した土地・建物の取得費が不明である場合、譲渡収入金額の（　　）相当額を取得費とすることができる。",
        "choice1": "５％",
        "choice2": "10％",
        "choice3": "20％",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "概算取得費",
        "important": "B"
    },
    {
        "id": 345,
        "shikenId": 11,
        "no": 55,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "賃貸用不動産を投資総額１億円で購入した場合、年間収入の合計額が1,000万円、年間費用の合計額が400万円であれば、この投資の純利回り（ＮＯＩ利回り）は、（　　）である。",
        "choice1": "4.0％",
        "choice2": "6.0％",
        "choice3": "10.0％",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "NOI利回りの計算",
        "important": "B"
    },
    {
        "id": 346,
        "shikenId": 11,
        "no": 56,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "死因贈与によって個人が取得した財産は、課税の対象とならない財産を除き、（　　）の課税対象となる。",
        "choice1": "贈与税",
        "choice2": "相続税",
        "choice3": "所得税",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "死因贈与",
        "important": "B"
    },
    {
        "id": 347,
        "shikenId": 11,
        "no": 57,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "贈与税の申告書は、原則として、贈与を受けた年の翌年の（ ① ）から３月15日までの間に、（ ② ）の住所地の所轄税務署長に提出しなければならない。",
        "choice1": "①　２月１日　　②　受贈者",
        "choice2": "①　２月16日　　②　贈与者",
        "choice3": "①　２月16日　　②　受贈者",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "贈与税の申告",
        "important": "C"
    },
    {
        "id": 348,
        "shikenId": 11,
        "no": 58,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "下記の＜親族関係図＞において、Ａさんの相続における弟Ｃさんの法定相続分は、（　　）である。<br><img src=\"/img/11_4.png\" style='width:400px'/>",
        "choice1": "２分の１",
        "choice2": "３分の１",
        "choice3": "４分の１",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "法定相続分",
        "important": "A"
    },
    {
        "id": 349,
        "shikenId": 11,
        "no": 59,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "本年中に相続または遺贈により財産を取得した者が、その相続開始前（ ① ）以内に暦年課税方式により、被相続人から贈与により取得した財産があるときは、その財産の（ ② ）における時価により評価した金額を、原則として相続税の課税価格に加算する。",
        "choice1": "①　３年　　②　相続時",
        "choice2": "①　３年　　②　贈与時",
        "choice3": "①　５年　　②　贈与時",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "生前贈与加算",
        "important": "C"
    },
    {
        "id": 350,
        "shikenId": 11,
        "no": 60,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "相続税の計算において、宅地が「小規模宅地等についての相続税の課税価格の計算の特例」における貸付事業用宅地等に該当する場合、その宅地のうち（ ① ）までを限度面積として、評価額の（ ② ）相当額を減額した金額を、相続税の課税価格に算入すべき価額とすることができる。",
        "choice1": "①　200㎡　　②　50％",
        "choice2": "①　330㎡　　②　50％",
        "choice3": "①　330㎡　　②　80％",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "小規模宅地等の特例",
        "important": "A"
    },
    {
        "id": 351,
        "shikenId": 15,
        "no": 1,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "生命保険募集人の登録を受けていないＦＰが顧客に対し、必要保障額を試算したり、生命保険商品の一般的な商品性について説明することは、保険業法により禁止されている。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "関連法規とコンプライアンス",
        "important": "C"
    },
    {
        "id": 352,
        "shikenId": 15,
        "no": 2,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "国民年金の保険料免除期間における保険料のうち、追納することができる保険料は、追納に係る厚生労働大臣の承認を受けた日の属する月前10年以内の期間に係るものに限られる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "国民年金保険料の追納",
        "important": "B"
    },
    {
        "id": 353,
        "shikenId": 15,
        "no": 3,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "子のいない障害等級１級に該当する者に支給される障害基礎年金の額は、子のいない障害等級２級に該当する者に支給される障害基礎年金の額の1.25倍である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "障害基礎年金",
        "important": "B"
    },
    {
        "id": 354,
        "shikenId": 15,
        "no": 4,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "国民年金基金の掛金は、加入員の選択した給付の型や加入口数のほか、加入時の年齢や性別によって異なる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "国民年金基金",
        "important": "C"
    },
    {
        "id": 355,
        "shikenId": 15,
        "no": 5,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "日本政策金融公庫の教育一般貸付（国の教育ローン）の融資限度額は、所定の海外留学資金として利用する場合等を除き、入学・在学する学生・生徒１人につき350万円である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "国の教育ローン",
        "important": "A"
    },
    {
        "id": 356,
        "shikenId": 15,
        "no": 6,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "自動車保険の人身傷害補償保険では、被保険者が被保険自動車を運転中、自動車事故により負傷した場合、自己の過失割合にかかわらず、保険金額を限度に損害額の全額が補償対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "人身傷害補償保険",
        "important": "A"
    },
    {
        "id": 357,
        "shikenId": 15,
        "no": 7,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "変額個人年金保険は、運用実績により、将来受け取る年金額や解約返戻金額、死亡保険金額が変動するが、一般に年金受取開始前に被保険者が死亡した場合の死亡給付金には最低保証がある。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "変額個人年金保険",
        "important": "B"
    },
    {
        "id": 358,
        "shikenId": 15,
        "no": 8,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "がん保険では、一般に、責任開始日前に120日程度の免責期間が設けられており、その期間中にがんと診断されてもがん診断給付金は支払われない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "がん保険",
        "important": "B"
    },
    {
        "id": 359,
        "shikenId": 15,
        "no": 9,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "スーパーマーケットを経営する企業が、店舗内で調理・販売した食品を原因として食中毒が発生し、顧客に対して法律上の損害賠償責任を負うことによって被る損害を補償する保険として、施設所有（管理）者賠償責任保険がある。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生産物賠償責任保険",
        "important": "C"
    },
    {
        "id": 360,
        "shikenId": 15,
        "no": 10,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税において、個人が支払う地震保険の保険料は、５万円を限度として年間支払保険料の２分の１にあたる金額が地震保険料控除の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "地震保険料控除",
        "important": "B"
    },
    {
        "id": 361,
        "shikenId": 15,
        "no": 11,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "1,000,000円を年利１％（１年複利）で４年間運用した場合の４年後の元利合計額は、税金や手数料等を考慮しない場合、1,040,604円となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "複利計算",
        "important": "C"
    },
    {
        "id": 362,
        "shikenId": 15,
        "no": 12,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "証券取引所での株式の売買において、１つの銘柄の株式に価格の異なる複数の買い指値注文がある場合は、指値の低い注文から優先して売買が成立する。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "株式の売買",
        "important": "B"
    },
    {
        "id": 363,
        "shikenId": 15,
        "no": 13,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "一般法人、個人、国、地方公共団体などの通貨保有主体が保有する通貨量の残高を集計したものをマネーストックといい、日本銀行が作成・公表している。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "マネーストック",
        "important": "C"
    },
    {
        "id": 364,
        "shikenId": 15,
        "no": 14,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "２資産で構成するポートフォリオにおいて、２資産間の相関係数が－１である場合、ポートフォリオのリスク低減効果は最大となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "相関係数",
        "important": "A"
    },
    {
        "id": 365,
        "shikenId": 15,
        "no": 15,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "日経平均株価は、東京証券取引所プライム市場に上場する全銘柄を対象として算出される株価指標である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "日経平均株価",
        "important": "B"
    },
    {
        "id": 366,
        "shikenId": 15,
        "no": 16,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "所得税において、上場株式の配当について配当控除の適用を受けるには、総合課税を選択して確定申告をする必要がある。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "配当控除",
        "important": "A"
    },
    {
        "id": 367,
        "shikenId": 15,
        "no": 17,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税における一時所得に係る総収入金額が600万円で、その収入を得るために支出した金額が400万円である場合、総所得金額に算入される一時所得の金額は、100万円である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "一時所得",
        "important": "B"
    },
    {
        "id": 368,
        "shikenId": 15,
        "no": 18,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "セルフメディケーション税制（医療費控除の特例）に係るスイッチＯＴＣ医薬品の購入費（特定一般用医薬品等購入費）を支払った場合、所定の要件を満たせば、通常の医療費控除との選択により、最高10万円の医療費控除の適用を受けることができる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "セルフメディケーション税制",
        "important": "C"
    },
    {
        "id": 369,
        "shikenId": 15,
        "no": 19,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "確定拠出年金の個人型年金において加入者が拠出した掛金は、その２分の１にあたる金額が小規模企業共済等掛金控除として所得控除の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "小規模企業共済等掛金控除",
        "important": "B"
    },
    {
        "id": 370,
        "shikenId": 15,
        "no": 20,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "「ふるさと納税ワンストップ特例制度」の適用を受けるためには、同一年中の寄附金の額の合計額が10万円以下でなければならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "ふるさと納税（ワンストップ特例制度）",
        "important": "C"
    },
    {
        "id": 371,
        "shikenId": 15,
        "no": 21,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "個人が自宅の土地および建物を譲渡し、「居住用財産を譲渡した場合の長期譲渡所得の課税の特例」（軽減税率の特例）の適用を受ける場合、課税長期譲渡所得金額のうち、１億円以下の部分は、所得税および復興特別所得税10.21％、住民税４％の税率で課税される。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "居住用財産の譲渡の特例（軽減税率）",
        "important": "C"
    },
    {
        "id": 372,
        "shikenId": 15,
        "no": 22,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "借地借家法第23条に規定される「事業用定期借地権等」は、専ら事業の用に供する建物の所有を目的とするもので、居住の用に供する建物の所有を目的として設定することはできない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "定期借地権",
        "important": "B"
    },
    {
        "id": 373,
        "shikenId": 15,
        "no": 23,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "建築基準法の規定によれば、第一種低層住居専用地域内における建築物の高さは、原則として10ｍまたは15ｍのうち当該地域に関する都市計画において定められた建築物の高さの限度を超えてはならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "建築物の高さ制限",
        "important": "C"
    },
    {
        "id": 374,
        "shikenId": 15,
        "no": 24,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "固定資産税における小規模住宅用地（住宅用地で住宅１戸当たり200㎡以下の部分）の課税標準については、当該住宅用地に係る固定資産税の課税標準となるべき価格の３分の１の額とする特例がある。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "固定資産税",
        "important": "B"
    },
    {
        "id": 375,
        "shikenId": 15,
        "no": 25,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "「被相続人の居住用財産（空き家）に係る譲渡所得の特別控除の特例」の適用を受けるためには、譲渡の対価の額が3,000万円以下でなければならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "空き家の譲渡",
        "important": "C"
    },
    {
        "id": 376,
        "shikenId": 15,
        "no": 26,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "贈与税の納付については、納期限までに金銭で納付することを困難とする事由があるなど、所定の要件を満たせば、延納あるいは物納によることが認められている。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "贈与税の納付",
        "important": "C"
    },
    {
        "id": 377,
        "shikenId": 15,
        "no": 27,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "自筆証書遺言を作成する場合において、自筆証書に添付する財産目録については、自書によらずにパソコンで作成してもよい。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "自筆証書遺言",
        "important": "A"
    },
    {
        "id": 378,
        "shikenId": 15,
        "no": 28,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "初七日や四十九日などの法会に要した費用は、相続税の課税価格の計算上、債務控除の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "債務控除",
        "important": "B"
    },
    {
        "id": 379,
        "shikenId": 15,
        "no": 29,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "個人間で時価よりも著しく低い価額で土地の譲渡が行われた場合、原則として、時価と支払った対価との差額が、贈与税の課税対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "低額譲渡",
        "important": "C"
    },
    {
        "id": 380,
        "shikenId": 15,
        "no": 30,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "取引相場のない株式の相続税評価において、純資産価額方式とは、評価会社の株式の価額を、評価会社と事業内容が類似した上場会社の株価および配当金額、利益金額、純資産価額を基準にして算出する方式である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "取引相場のない株式の評価",
        "important": "C"
    },
    {
        "id": 381,
        "shikenId": 15,
        "no": 31,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "Ａさんの本年分の可処分所得の金額は、下記の＜資料＞によれば、（　　）である。<br>＜資料＞本年分のＡさんの収入等<br><img src=\"/img/15_1.png\" style='width:400px'/>",
        "choice1": "430万円",
        "choice2": "530万円",
        "choice3": "620万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "可処分所得の計算",
        "important": "C"
    },
    {
        "id": 382,
        "shikenId": 15,
        "no": 32,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "公的介護保険の第２号被保険者は、市町村または特別区の区域内に住所を有する（ ① ）以上（ ② ）未満の医療保険加入者である。",
        "choice1": "①　40歳　　②　60歳",
        "choice2": "①　40歳　　②　65歳",
        "choice3": "①　45歳　　②　65歳",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "介護保険",
        "important": "C"
    },
    {
        "id": 383,
        "shikenId": 15,
        "no": 33,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "国民年金の第１号被保険者が、国民年金の定額保険料に加えて月額（ ① ）の付加保険料を納付し、65歳から老齢基礎年金を受け取る場合、（ ② ）に付加保険料納付済期間の月数を乗じて得た額が付加年金として支給される。",
        "choice1": "①　200円　　②　400円",
        "choice2": "①　200円　　②　200円",
        "choice3": "①　400円　　②　200円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "付加年金",
        "important": "B"
    },
    {
        "id": 384,
        "shikenId": 15,
        "no": 34,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "遺族厚生年金の中高齢寡婦加算の支給に係る妻の年齢要件は、夫の死亡の当時、子のない妻の場合、（　　）である。",
        "choice1": "40歳以上60歳未満",
        "choice2": "40歳以上65歳未満",
        "choice3": "45歳以上60歳未満",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "中高齢寡婦加算",
        "important": "B"
    },
    {
        "id": 385,
        "shikenId": 15,
        "no": 35,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "貸金業法の総量規制により、個人が貸金業者による個人向け貸付を利用する場合の借入合計額は、原則として、年収の（　　）以内でなければならない。",
        "choice1": "３分の１",
        "choice2": "２分の１",
        "choice3": "５分の１",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "貸金業法の総量規制",
        "important": "C"
    },
    {
        "id": 386,
        "shikenId": 15,
        "no": 36,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "生命保険契約を申し込んだ者がその撤回を希望する場合、保険業法上、原則として、契約の申込日または契約の申込みの撤回等に関する事項を記載した書面の交付日のいずれか遅い日を含めて（ ① ）以内であれば、（ ② ）により申込みの撤回ができる。",
        "choice1": "①　８日　　②　書面（電磁的記録を含む）",
        "choice2": "①　14日　　②　書面（電磁的記録を含む）",
        "choice3": "①　８日　　②　書面（電磁的記録を含む）または口頭",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "クーリング・オフ",
        "important": "C"
    },
    {
        "id": 387,
        "shikenId": 15,
        "no": 37,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "生命保険の保険料の払込みが困難になった場合等に契約を有効に継続するための方法のうち、（　　）は、保険料の払込みを中止して、その時点での解約返戻金相当額をもとに、保険金額を変えないで、一時払いの定期保険に切り換えるものをいう。",
        "choice1": "払済保険",
        "choice2": "延長保険",
        "choice3": "継続保険",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "延長保険",
        "important": "B"
    },
    {
        "id": 388,
        "shikenId": 15,
        "no": 38,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "普通傷害保険（特約付帯なし）において、一般に、（　　）は補償の対象とならない。",
        "choice1": "料理中に油がはねて火傷した場合",
        "choice2": "海外旅行中の転倒により骨折した場合",
        "choice3": "国内旅行中の飲食で細菌性食中毒になった場合",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "普通傷害保険",
        "important": "B"
    },
    {
        "id": 389,
        "shikenId": 15,
        "no": 39,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "スーパーマーケットを経営する企業が、火災により店舗が全焼し、休業した場合の利益損失を補償するためには、（　　）への加入が考えられる。",
        "choice1": "企業費用・利益総合保険",
        "choice2": "施設所有（管理）者賠償責任保険",
        "choice3": "生産物賠償責任保険",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "法人の損害保険",
        "important": "A"
    },
    {
        "id": 390,
        "shikenId": 15,
        "no": 40,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "自動車事故でケガを負い、相手方が加入していた自動車保険の対人賠償保険金を受け取った場合、当該保険金は（　　）とされる。",
        "choice1": "一時所得",
        "choice2": "雑所得",
        "choice3": "非課税",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "損害保険金の課税",
        "important": "B"
    },
    {
        "id": 391,
        "shikenId": 15,
        "no": 41,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "日本銀行が売りオペレーションを行った場合には、市場の資金量が（ ① ）することから、市場金利は（ ② ）する。",
        "choice1": "①　減少　　②　低下",
        "choice2": "①　増加　　②　低下",
        "choice3": "①　減少　　②　上昇",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "金融政策",
        "important": "B"
    },
    {
        "id": 392,
        "shikenId": 15,
        "no": 42,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "個人向け国債は、適用利率の下限が年（ ① ）％とされ、購入単価は最低（ ② ）から（ ② ）単位である。",
        "choice1": "①　0.03　　②　１万円",
        "choice2": "①　0.05　　②　１万円",
        "choice3": "①　0.05　　②　10万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "個人向け国債",
        "important": "C"
    },
    {
        "id": 393,
        "shikenId": 15,
        "no": 43,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "下記の＜Ｘ社のデータ＞に基づいて計算したＸ社株式の配当性向は（ ① ）、自己資本利益率は（ ② ）である（純資産＝自己資本とします）。<br>＜Ｘ社のデータ＞<br><img src=\"/img/15_2.png\" style='width:300px'/>",
        "choice1": "①　60％　　②　12.5％",
        "choice2": "①　3.75％　　②　6.25％",
        "choice3": "①　12.5％　　②　7.5％",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "株式の投資指標の計算",
        "important": "A"
    },
    {
        "id": 394,
        "shikenId": 15,
        "no": 44,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "オプション取引において、特定の商品を将来の一定期日にあらかじめ決められた価格で買う権利のことを（ ① ）・オプションといい、他の条件が同じであれば、一般に、満期までの残存期間が長いほど、プレミアム（オプション料）は（ ② ）なる。",
        "choice1": "①　プット　　②　低く",
        "choice2": "①　コール　　②　低く",
        "choice3": "①　コール　　②　高く",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "オプション取引",
        "important": "B"
    },
    {
        "id": 395,
        "shikenId": 15,
        "no": 45,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税において、為替予約をしていない外貨定期預金の満期時に生じた為替差益は（　　）として総合課税の対象となる。",
        "choice1": "一時所得",
        "choice2": "雑所得",
        "choice3": "利子所得",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "為替差益の税金",
        "important": "B"
    },
    {
        "id": 396,
        "shikenId": 15,
        "no": 46,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "所得税において、国債や地方債等の特定公社債の利子は、（　　）の対象となる。",
        "choice1": "源泉分離課税",
        "choice2": "総合課税",
        "choice3": "申告分離課税",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "利子所得",
        "important": "B"
    },
    {
        "id": 397,
        "shikenId": 15,
        "no": 47,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "下記の＜資料＞において、所得税における不動産所得（国内建物の貸付）の金額の計算上生じた損失の金額のうち、他の所得の金額と損益通算が可能な金額は、（　　）である。<br>＜資料＞不動産所得に関する資料<br><img src=\"/img/15_3.png\" style='width:400px'/>",
        "choice1": "20万円",
        "choice2": "30万円",
        "choice3": "50万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "総所得金額の計算",
        "important": "A"
    },
    {
        "id": 398,
        "shikenId": 15,
        "no": 48,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "所得税の控除対象扶養親族のうち、19歳以上23歳未満である特定扶養親族に係る扶養控除の額は、（　　）である。",
        "choice1": "38万円",
        "choice2": "58万円",
        "choice3": "63万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "扶養控除",
        "important": "A"
    },
    {
        "id": 399,
        "shikenId": 15,
        "no": 49,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "本年中に取得・入居する場合、住宅に係る新築・中古の別や床面積にかかわらず、所得税における住宅借入金等特別控除の要件では、適用を受けようとする者の合計所得金額が（　　）を超える年分は、対象外としている。",
        "choice1": "1,000万円",
        "choice2": "2,000万円",
        "choice3": "3,000万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "住宅借入金等特別控除",
        "important": "B"
    },
    {
        "id": 400,
        "shikenId": 15,
        "no": 50,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "給与所得者のうち、（　　）は、所得税の確定申告をする必要がある。",
        "choice1": "医療費控除の適用を受けようとする者",
        "choice2": "給与所得以外の所得の金額の合計額が10万円を超える者",
        "choice3": "給与の年間収入金額が1,500万円を超える者",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "確定申告が必要なケース",
        "important": "B"
    },
    {
        "id": 401,
        "shikenId": 15,
        "no": 51,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "不動産の登記記録において、所有権に関する登記事項は（ ① ）に記録され、抵当権に関する登記事項は（ ② ）に記録される。",
        "choice1": "①　権利部（乙区）　　②　権利部（甲区）",
        "choice2": "①　権利部（甲区）　　②　表題部",
        "choice3": "①　権利部（甲区）　　②　権利部（乙区）",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産登記",
        "important": "A"
    },
    {
        "id": 402,
        "shikenId": 15,
        "no": 52,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "建物の区分所有等に関する法律（区分所有法）によれば、規約の設定、変更、廃止には、区分所有者および議決権の各（　　）の多数による集会の決議が必要となる。",
        "choice1": "過半数",
        "choice2": "４分の３",
        "choice3": "５分の４",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "区分所有法",
        "important": "A"
    },
    {
        "id": 403,
        "shikenId": 15,
        "no": 53,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "宅地建物取引業者と締結する宅地または建物の売買の専任媒介契約および専属専任媒介契約の有効期間は、最長（　　）である。",
        "choice1": "３カ月",
        "choice2": "６カ月",
        "choice3": "１年",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "宅地建物取引業法",
        "important": "A"
    },
    {
        "id": 404,
        "shikenId": 15,
        "no": 54,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "自己が居住していた家屋を譲渡する場合、その家屋に自己が居住しなくなった日から（ ① ）を経過する日の属する年の（ ② ）までの譲渡でなければ、「居住用財産を譲渡した場合の3,000万円の特別控除」の適用を受けることができない。",
        "choice1": "①　１年　　②　３月31日",
        "choice2": "①　２年　　②　12月31日",
        "choice3": "①　３年　　②　12月31日",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "居住用財産の譲渡の特例（3,000万円特別控除）",
        "important": "B"
    },
    {
        "id": 405,
        "shikenId": 15,
        "no": 55,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "土地の有効活用のうち、一般に、土地所有者が入居予定の事業会社から建設資金を借り受けて、事業会社の要望に沿った店舗等を建設し、その店舗等を事業会社に賃貸する手法を、（　　）という。",
        "choice1": "等価交換方式",
        "choice2": "事業受託方式",
        "choice3": "建設協力金方式",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "土地の有効活用",
        "important": "B"
    },
    {
        "id": 406,
        "shikenId": 15,
        "no": 56,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "遺留分算定の基礎となる財産の価額が１億8,000万円で、相続人が被相続人の妻、長女、二女、三女の合計４人である場合、妻の遺留分の金額は（　　）となる。",
        "choice1": "1,500万円",
        "choice2": "4,500万円",
        "choice3": "9,000万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "遺留分の計算",
        "important": "B"
    },
    {
        "id": 407,
        "shikenId": 15,
        "no": 57,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "贈与税の配偶者控除は、婚姻期間が（ ① ）以上である配偶者から居住用不動産の贈与または居住用不動産を取得するための金銭の贈与を受け、所定の要件を満たす場合、贈与税の課税価格から基礎控除額とは別に（ ② ）を限度として控除することができるものである。",
        "choice1": "①　15年　　②　2,000万円",
        "choice2": "①　20年　　②　2,000万円",
        "choice3": "①　20年　　②　3,000万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "贈与税の配偶者控除",
        "important": "B"
    },
    {
        "id": 408,
        "shikenId": 15,
        "no": 58,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "下記の＜親族関係図＞において、被相続人Ａさんの相続における相続税額の計算上、遺産に係る基礎控除額は、（　　）である。<br>＜親族関係図＞<br><img src=\"/img/15_4.png\" style='width:500px'/>",
        "choice1": "1,800万円",
        "choice2": "3,000万円",
        "choice3": "4,800万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "遺産に係る基礎控除の計算",
        "important": "B"
    },
    {
        "id": 409,
        "shikenId": 15,
        "no": 59,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "賃貸アパート等の貸家の用に供されている家屋の相続税評価額は、（　　）の算式により算出される。",
        "choice1": "自用家屋としての評価額×（１－借地権割合×賃貸割合）",
        "choice2": "自用家屋としての評価額×（１－借家権割合×賃貸割合）",
        "choice3": "自用家屋としての評価額×（１－借地権割合×借家権割合×賃貸割合）",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "家屋の評価",
        "important": "C"
    },
    {
        "id": 410,
        "shikenId": 15,
        "no": 60,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "本年10月11日に死亡したＡさんが所有していた上場株式Ｘを相続により取得した場合の１株当たりの相続税評価額は、下記の＜資料＞によれば、（　　）である。<br>＜資料＞上場株式Xの価格<br><img src=\"/img/15_5.png\" style='width:420px'/>",
        "choice1": "690円",
        "choice2": "720円",
        "choice3": "730円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "上場株式の評価",
        "important": "B"
    },
    {
        "id": 411,
        "shikenId": 10,
        "no": 1,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "ファイナンシャル・プランニング業務を行うに当たっては、関連業法を順守することが重要である。ファイナンシャル・プランナー（以下「ＦＰ」という）の行為に関する次の記述のうち、最も不適切なものはどれか。",
        "question2": "",
        "choice1": "税理士資格を有していないＦＰが、無料相談会において、相談者が資料を持参した場合に、その資料をもとに相談者が納付すべき税額計算を行った。",
        "choice2": "生命保険募集人、生命保険仲立人、金融サービス仲介業の登録をしていないＦＰが、生命保険契約を検討している顧客につきライフプランを聞きとった上で、必要保障額を具体的に試算した。",
        "choice3": "投資助言・代理業、投資運用業の登録をしていないＦＰが、顧客が保有する投資信託の運用報告書について、その記載内容を説明した。",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "関連法規とコンプライアンス",
        "important": ""
    },
    {
        "id": 412,
        "shikenId": 10,
        "no": 2,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "下記は、Ａ家のキャッシュフロー表（一部抜粋）である。このキャッシュフロー表の空欄（ア）～（ウ）にあてはまる数値として、最も不適切なものはどれか。なお、計算に当たっては、キャッシュフロー表中に記載の整数を使用し、計算結果については万円未満を四捨五入すること。<br><img src='/img/10_2.png'/>",
        "question2": "",
        "choice1": "空欄（ア）：６２８",
        "choice2": "空欄（イ）：１４８",
        "choice3": "空欄（ウ）：１,１２７",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "キャッシュフロー表の計算",
        "important": ""
    },
    {
        "id": 413,
        "shikenId": 10,
        "no": 3,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "下記＜資料＞に基づくＭＮ株式会社の投資指標に関する次の記述のうち、最も不適切なものはどれか。なお、購入時の手数料および税金は考慮しないこととし、計算結果については表示単位の小数点以下第３位を四捨五入すること。<br><img src='/img/10_3.png'/>",
        "question2": "",
        "choice1": "株価収益率（ＰＥＲ）で比較した場合、日経平均採用銘柄の平均より割安である。",
        "choice2": "株価純資産倍率（ＰＢＲ）で比較した場合、ＪＰＸ日経４００採用銘柄より割高である。",
        "choice3": "配当利回りで比較した場合、東証プライム全銘柄の単純平均より高い。",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "株式投資指標の計算",
        "important": ""
    },
    {
        "id": 414,
        "shikenId": 10,
        "no": 4,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "下記は、投資信託の費用についてまとめた表である。下表の空欄（ア）～（ウ）に入る語句として、最も不適切なものはどれか。<br><img src='/img/10_4.png'/>",
        "question2": "",
        "choice1": "空欄（ア）：ノーロード型",
        "choice2": "空欄（イ）：日々",
        "choice3": "空欄（ウ）：口座管理料",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "投資信託のコスト",
        "important": "必要保障額の計算"
    },
    {
        "id": 415,
        "shikenId": 10,
        "no": 5,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "下記＜資料＞の外貨定期預金について、満期時の外貨ベースの元利合計額を円転した金額として、正しいものはどれか。なお、計算結果（円転した金額）について円未満の端数が生じる場合は切り捨てること。また、税金については考慮しないこととする。<br><img src='/img/10_5.png'/>",
        "question2": "",
        "choice1": "１,３８６,９００円",
        "choice2": "１,３９３,８００円",
        "choice3": "１,４０７,０００円",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "外貨預金の計算",
        "important": ""
    },
    {
        "id": 416,
        "shikenId": 10,
        "no": 6,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "下記＜資料＞の建築基準法に定める道路およびそれに接する建築物の敷地に関する次の記述の空欄（ア）～（ウ）にあてはまる数値の組み合わせとして、正しいものはどれか。なお、記載のない条件については一切考慮しないこととする。<br><img src='/img/10_6.png'/>",
        "question2": "",
        "choice1": "（ア）６　（イ）３　（ウ）３",
        "choice2": "（ア）４　（イ）２　（ウ）２",
        "choice3": "（ア）４　（イ）２　（ウ）４",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "建築基準法上の道路",
        "important": ""
    },
    {
        "id": 417,
        "shikenId": 10,
        "no": 7,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "田上さんは、別荘として利用中の土地および建物を売却する予定である。売却に係る状況が下記＜資料＞のとおりである場合、所得税における次の記述の空欄（ア）、（イ）にあてはまる数値または語句の組み合わせとして、最も適切なものはどれか。<br><img src='/img/10_7.png'/>",
        "question2": "",
        "choice1": "（ア）５５０　　（イ）長期",
        "choice2": "（ア）６５０　　（イ）短期",
        "choice3": "（ア）６５０　　（イ）長期",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "譲渡所得の計算等",
        "important": ""
    },
    {
        "id": 418,
        "shikenId": 10,
        "no": 8,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "大津翔太さんが加入しているガン保険（下記＜資料＞参照）の保障内容に関する次の記述の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、保険契約は有効に継続しているものとし、翔太さんはこれまでに＜資料＞の保険から保険金および給付金を一度も受け取っていないものとする。<br><img src='/img/10_8.png'/>",
        "question2": "",
        "choice1": "１２０万円",
        "choice2": "１３０万円",
        "choice3": "１５０万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生命保険の証券分析",
        "important": ""
    },
    {
        "id": 419,
        "shikenId": 10,
        "no": 9,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "西山さんは、地震保険についてＦＰの並木さんに質問をした。地震保険に関する次の記述のうち、最も不適切なものはどれか。",
        "question2": "",
        "choice1": "地震保険は、住宅総合保険などの火災保険契約に付帯して契約する必要があり、単独で契約することはできない。",
        "choice2": "地震により、居住用の建物に収容されている家財のうち、１個または１組の価額が３０万円を超える骨とう品が損害を受けた場合、地震保険の補償の対象となる。",
        "choice3": "噴火や津波により、居住用の建物が大半損や一部損となった場合、地震保険の補償の対象となる。",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "地震保険",
        "important": ""
    },
    {
        "id": 420,
        "shikenId": 10,
        "no": 10,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "大森洋さんが契約している自動車保険の主な内容は、下記＜資料＞のとおりである。＜資料＞に基づく次の記述のうち、自動車保険による補償の対象とならないものはどれか。なお、いずれも保険期間中に発生したものであり、運転者は洋さんである。また、記載のない事項については一切考慮しないこととする。<br><img src='/img/10_10.png' style='width:350px'/>",
        "question2": "",
        "choice1": "被保険自動車を運転中に、横断歩道の歩行者に接触し、ケガを負わせた場合の損害賠償。",
        "choice2": "被保険自動車を駐車場に駐車する際に、誘導中の妻に誤って車が接触し、ケガを負わせた場合の治療費用。",
        "choice3": "被保険自動車を運転中に、単独事故を起こし、車体が損傷した場合の修理費用。",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "自動車保険",
        "important": ""
    },
    {
        "id": 421,
        "shikenId": 10,
        "no": 11,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "会社員の北村さんは、本年中に勤務先を定年退職した。北村さんの退職に係るデータが下記＜資料＞のとおりである場合、北村さんの所得税に係る退職所得の金額として、正しいものはどれか。なお、北村さんは役員であったことはなく、退職は障害者になったことに基因するものではない。また、前年以前に受け取った退職金はないものとする。<br><img src='/img/10_11.png'/>",
        "question2": "",
        "choice1": "４７０万円",
        "choice2": "５０５万円",
        "choice3": "９４０万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "退職所得の計算",
        "important": ""
    },
    {
        "id": 422,
        "shikenId": 10,
        "no": 12,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "山際さん（６８歳）の本年分の収入等は下記＜資料＞のとおりである。山際さんの本年分の所得税における総所得金額として、正しいものはどれか。なお、記載のない事項については一切考慮しないこととする。<br><img src='/img/10_12.png'/>",
        "question2": "",
        "choice1": "１６８万円",
        "choice2": "１８８万円",
        "choice3": "２５２万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "総所得金額の計算",
        "important": ""
    },
    {
        "id": 423,
        "shikenId": 10,
        "no": 13,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "本年１１月２０日に相続が開始された太田花子さん（被相続人）の＜親族関係図＞が下記のとおりである場合、民法上の相続人および法定相続分の組み合わせとして、正しいものはどれか。なお、記載のない条件については一切考慮しないこととする。<br><img src='/img/10_13.png'/>",
        "question2": "",
        "choice1": "和則　１／２　　一男　１／４　　洋子　１／４",
        "choice2": "和則　１／２　　一男　１／６　　洋子　１／６　　優奈　１／６",
        "choice3": "和則　２／３　　一男　１／６　　洋子　１／６",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続人と法定相続分",
        "important": ""
    },
    {
        "id": 424,
        "shikenId": 10,
        "no": 14,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "今年８５歳になる片山さんは、遺産分割等でのトラブルを防ぐために遺言書の作成を検討しており、ＦＰの佐治さんに相談をした。遺言書に関する佐治さんの次の説明のうち、最も適切なものはどれか。",
        "question2": "",
        "choice1": "「自宅に保管されている自筆証書遺言を発見した相続人は、遅滞なく遺言書を公証役場に提出して、その検認を請求しなければなりません。」",
        "choice2": "「自筆証書遺言を作成する場合、遺言者と２人以上の証人の署名と押印が必要です。」",
        "choice3": "「公正証書遺言を作成した後、自筆証書遺言によって、先に作成した公正証書遺言を撤回することができます。」",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "遺言の種類と特徴",
        "important": ""
    },
    {
        "id": 425,
        "shikenId": 10,
        "no": 15,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "<img src='/img/10_15_1.png'/><br>ＦＰの福田さんは、資金計画どおりにマンションを購入した後の大橋家のバランスシートを作成した。下表の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、＜設例＞に記載のあるデータに基づいて解答することとし、記載のないデータについては一切考慮しないこととする。<br><img src='/img/10_15_2.png'/>",
        "question2": "",
        "choice1": "６５０（万円）",
        "choice2": "８５０（万円）",
        "choice3": "１，０５０（万円）",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "バランスシートの計算",
        "important": ""
    },
    {
        "id": 426,
        "shikenId": 10,
        "no": 16,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "大橋さんは、今後１５年間で毎年３０万円ずつ積立貯蓄をし、将来の生活費の準備をしたいと考えている。積立期間中に年利２.０％で複利運用できるものとした場合、１５年後の積立金額として、正しいものはどれか。なお、下記＜資料＞の３つの係数の中から最も適切な係数を選択して計算し、解答に当たっては、千円未満を四捨五入すること。また、税金や記載のない事項については一切考慮しないこととする。<br><img src='/img/10_16.png'/>",
        "question2": "",
        "choice1": "４,８４６,０００円",
        "choice2": "５,１８８,０００円",
        "choice3": "３,８５５,０００円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "係数計算",
        "important": ""
    },
    {
        "id": 427,
        "shikenId": 10,
        "no": 17,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "会社員の大橋さんは、将来親の介護が必要になり仕事を休んだ場合、雇用保険からどのような給付が受けられるのか、ＦＰの福田さんに質問をした。福田さんが行った公的介護保険および雇用保険の介護休業給付金に関する次の説明の空欄（ア）～（ウ）にあてはまる数値の組み合わせとして、最も適切なものはどれか。<br><img src='/img/10_17.png'/>",
        "question2": "",
        "choice1": "（ア）６７％　　（イ）  ９３日   （ウ）３回",
        "choice2": "（ア）６７％　　（イ）１８０日   （ウ）２回",
        "choice3": "（ア）８０％　　（イ）  ９３日   （ウ）３回",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "介護休業給付",
        "important": ""
    },
    {
        "id": 428,
        "shikenId": 10,
        "no": 18,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "大橋さんは、個人型確定拠出年金（以下「ｉＤｅＣｏ」という）について、ＦＰの福田さんに質問をした。ｉＤｅＣｏに関する福田さんの次の説明のうち、最も不適切なものはどれか。",
        "question2": "",
        "choice1": "「ｉＤｅＣｏに加入した場合、支払った掛金は、小規模企業共済等掛金控除として税額控除の対象となります。」",
        "choice2": "「妻の倫子さんが国民年金の第３号被保険者となった場合も、ｉＤｅＣｏの加入対象者となります。」",
        "choice3": "「ｉＤｅＣｏの年金資産は、原則として６０歳になるまで引き出すことができません。」",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "個人型確定拠出年金（iDeCo）",
        "important": ""
    },
    {
        "id": 429,
        "shikenId": 10,
        "no": 19,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "大橋健太さん（３５歳）は、会社の定期健康診断で異常を指摘され、本年４月に２週間ほど入院をして治療を受けた。その際の病院への支払いが高額であったため、健太さんは健康保険の高額療養費制度によって払戻しを受けたいと考え、ＦＰの福田さんに相談をした。健太さんの本年４月の保険診療に係る総医療費が８０万円であった場合、高額療養費制度により払戻しを受けることができる金額として、正しいものはどれか。なお、健太さんは全国健康保険協会管掌健康保険（協会けんぽ）の被保険者で、標準報酬月額は「３４万円」である。また、健太さんは限度額適用認定証を病院に提出していないものとする。<br><img src='/img/10_19.png'/>",
        "question2": "",
        "choice1": "７０,１８０円",
        "choice2": "８５,４３０円",
        "choice3": "１５４,５７０円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "高額療養費の計算",
        "important": ""
    },
    {
        "id": 430,
        "shikenId": 10,
        "no": 20,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "会社員の大橋倫子さんは、出産を考えており、出産後は子が１歳になるまで育児休業を取得しようと考えている。育児休業期間中の健康保険および厚生年金保険の保険料の免除に関する次の記述のうち、最も適切なものはどれか。なお、倫子さんは全国健康保険協会管掌健康保険（協会けんぽ）および厚生年金保険の被保険者である。",
        "question2": "",
        "choice1": "事業主が申出を行った場合、事業主負担分のみ免除される。",
        "choice2": "事業主が申出を行った場合、被保険者負担分および事業主負担分が免除される。",
        "choice3": "事業主が申出を行った場合、被保険者負担分のみ免除される。",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "健康保険および厚生年金保険の保険料免除",
        "important": ""
    },
    {
        "id": 431,
        "shikenId": 14,
        "no": 1,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "ファイナンシャル・プランニング業務を行うに当たっては、関連業法を順守することが重要である。ファイナンシャル・プランナー（以下「ＦＰ」という）の行為に関する次の記述のうち、最も適切なものはどれか。",
        "question2": "",
        "choice1": "生命保険募集人・生命保険仲立人、金融サービス仲介業の登録をしていないＦＰが、生命保険契約を検討している顧客から相談を受け、顧客が死亡した場合における遺族にとっての必要保障額の計算を有償で行った。",
        "choice2": "弁護士資格を有していないＦＰが、離婚に伴う財産分与について係争中の顧客から相談を受け、報酬を得る目的でその顧客を代理して離婚協議書の作成に係る法律事務を取り扱った。",
        "choice3": "税理士資格を有していないＦＰが、参加費無料の相談会において、相談者が資料を持参したため、相談者が納付すべき相続税の具体的な税額計算を行った。",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "関連法規とコンプライアンス",
        "important": ""
    },
    {
        "id": 432,
        "shikenId": 14,
        "no": 2,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "下記は、A家のキャッシュフロー表（一部抜粋）である。このキャッシュフロー表の空欄（ア）～（ウ）にあてはまる数値の組み合わせとして、正しいものはどれか。なお、計算過程においては端数処理をせず計算し、計算結果については万円未満を四捨五入すること。<br><img src='/img/14_2.png'/>",
        "question2": "",
        "choice1": "（ア）３５０　（イ）　４３　（ウ）８８３",
        "choice2": "（ア）３５７　（イ）▲４３　（ウ）８８３",
        "choice3": "（ア）３５７　（イ）▲４３　（ウ）８９２",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "キャッシュフロー表の計算",
        "important": ""
    },
    {
        "id": 433,
        "shikenId": 14,
        "no": 3,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "大田さんは、預金保険制度の対象となるＧＹ銀行の国内支店に下記＜資料＞の金融資産を預け入れている。仮に、ＧＹ銀行が破綻した場合、預金保険制度によって保護される金額として、正しいものはどれか。<br><img src='/img/14_3.png'/>",
        "question2": "",
        "choice1": "５００万円",
        "choice2": "８００万円",
        "choice3": "１,０００万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "預金保険の計算",
        "important": ""
    },
    {
        "id": 434,
        "shikenId": 14,
        "no": 4,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "下記＜資料＞は、ＡＢファンドの販売用資料（一部抜粋）である。この投資信託に関する次の記述のうち、最も適切なものはどれか。<br><img src='/img/14_4.png'/>",
        "question2": "",
        "choice1": "ＡＢファンドは、新ＮＩＳＡ口座で購入することができる。",
        "choice2": "ＡＢファンドは、国内および海外の資産を投資対象としている。",
        "choice3": "ＡＢファンドを購入する際、投資家が支払う購入代金は「<img src='/img/14_4_1.png' class='img500'/>×購入口数＋購入時手数料（税込）＋運用管理費用（税込）」である。",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "投資信託の資料読み取り",
        "important": "必要保障額の計算"
    },
    {
        "id": 435,
        "shikenId": 14,
        "no": 5,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "広川さんはＳＴ投資信託を新規募集時に５００万口購入し、特定口座（源泉徴収口座）で保有して収益分配金を受け取っている。下記＜資料＞に基づき、広川さんが保有するＳＴ投資信託に関する次の記述の空欄（ア）、（イ）にあてはまる語句の組み合わせとして、正しいものはどれか。<br><img src='/img/14_5.png'/>",
        "question2": "",
        "choice1": "（ア）５５,０００円　　　（イ）普通分配金",
        "choice2": "（ア）５５,０００円　　　（イ）元本払戻金（特別分配金）",
        "choice3": "（ア）１６,５００円　　　（イ）普通分配金",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "投資信託の手数料と分配金の計算",
        "important": ""
    },
    {
        "id": 436,
        "shikenId": 14,
        "no": 6,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "建物の登記記録に関する下表の空欄（ア）～（ウ）にあてはまる記録事項の組み合わせとして、正しいものはどれか。<br><img src='/img/14_6.png'/>",
        "question2": "",
        "choice1": "（ア）所有権保存登記　　（イ）所有権移転登記　（ウ）抵当権設定登記",
        "choice2": "（ア）建物の所在や構造　（イ）所有権移転登記　（ウ）抵当権設定登記",
        "choice3": "（ア）建物の所在や構造　（イ）所有権保存登記　（ウ）所有権移転登記",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産登記",
        "important": ""
    },
    {
        "id": 437,
        "shikenId": 14,
        "no": 7,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "建築基準法に従い、下記＜資料＞の土地に建築物を建築する場合の延べ面積（床面積の合計）の最高限度として、正しいものはどれか。なお、記載のない条件については一切考慮しないこととする。<br><img src='/img/14_7.png'/>",
        "question2": "",
        "choice1": "４２０㎡",
        "choice2": "２,５２０㎡",
        "choice3": "２,８００㎡",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "建築物の延べ面積の計算",
        "important": ""
    },
    {
        "id": 438,
        "shikenId": 14,
        "no": 8,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "西田幸二さんが加入している生命保険（下記＜資料＞参照）の保障内容に関する次の記述の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、保険契約は有効に継続しているものとし、幸二さんはこれまでに＜資料＞の保険から保険金および給付金を一度も受け取っていないものとする。<br><img src='/img/14_8.png'/>",
        "question2": "",
        "choice1": "１，０６２万円",
        "choice2": "１，０７８万円",
        "choice3": "１，０８２万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生命保険の証券分析",
        "important": ""
    },
    {
        "id": 439,
        "shikenId": 14,
        "no": 9,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "内藤和夫さんが本年中に支払った生命保険の保険料は下記＜資料＞のとおりである。この場合の和夫さんの本年分の所得税の計算における生命保険料控除の金額として、正しいものはどれか。なお、下記＜資料＞の保険について、これまでに契約内容の変更はないものとする。また、本年分の生命保険料控除額が最も多くなるように計算すること。<br><img src='/img/14_9.png'/>",
        "question2": "",
        "choice1": "４０，０００円",
        "choice2": "５５，０００円",
        "choice3": "６５，０００円",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生命保険料控除の計算",
        "important": ""
    },
    {
        "id": 440,
        "shikenId": 14,
        "no": 10,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "損害保険の用語に関する次の記述のうち、最も不適切なものはどれか。",
        "question2": "",
        "choice1": "再調達価額とは、保険の対象と同等のものを新たに建築または購入するのに必要な金額から、使用による消耗分を差し引いた金額のことである。",
        "choice2": "超過保険とは、保険金額が保険の対象の価額（保険価額）を超えている保険 のことである。",
        "choice3": "保険金とは、保険事故によって損害が生じたとき、保険会社から被保険者または保険金受取人に支払われる金銭のことである。",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "損害保険の用語",
        "important": ""
    },
    {
        "id": 441,
        "shikenId": 14,
        "no": 11,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "桜田さんは本年中に勤務先を退職し、個人事業主として活動を始めた。桜田さんの本年分の各種所得の金額が下記＜資料＞のとおりである場合、桜田さんの本年分の所得税における総所得金額として正しいものはどれか。なお、桜田さんの本年中の所得は＜資料＞に記載されている所得以外にはないものとする。<br><img src='/img/14_11.png'/>",
        "question2": "",
        "choice1": "７００万円",
        "choice2": "９００万円",
        "choice3": "１,１００万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "総所得金額の計算",
        "important": ""
    },
    {
        "id": 442,
        "shikenId": 14,
        "no": 12,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "山野さんは、本年６月に新築のアパートを購入し、新たに不動産賃貸業を開始した。購入したアパートの建物部分の情報は下記＜資料＞のとおりである。山野さんの本年分の所得税における不動産所得の金額の計算上、必要経費に算入する減価償却費の金額として、正しいものはどれか。<br><img src='/img/14_12.png'/>",
        "question2": "",
        "choice1": "６６０,０００円",
        "choice2": "７７０,０００円",
        "choice3": "１,３２０,０００円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "減価償却費の計算",
        "important": ""
    },
    {
        "id": 443,
        "shikenId": 14,
        "no": 13,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "坂本さんは、マンション賃貸業を営む個人事業主で青色申告者である。坂本さんの本年分の所得および所得控除が下記＜資料＞のとおりである場合、坂本さんの本年分の所得税額として、正しいものはどれか。なお、坂本さんに＜資料＞以外の所得はなく、復興特別所得税や税額控除、源泉徴収税額、予定納税等については一切考慮しないこととする。<br><img src='/img/14_13.png'/>",
        "question2": "",
        "choice1": "７７２,５００円",
        "choice2": "１,１０１,５００円",
        "choice3": "１,２０４,０００円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "所得税額の計算",
        "important": ""
    },
    {
        "id": 444,
        "shikenId": 14,
        "no": 14,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "本年１１月１５日に相続が開始された松本二郎さん（被相続人）の＜親族関係図＞が下記のとおりである場合、民法上の相続人および法定相続分の組み合わせとして、正しいものはどれか。なお、記載のない条件については一切考慮しないこととする。<br><img src='/img/14_14.png'/>",
        "question2": "",
        "choice1": "幸子　１／２　　花子　１／４　　一郎　１／４",
        "choice2": "幸子　２／３　　花子　１／６　　一郎　１／６",
        "choice3": "幸子　３／４　　花子　１／８　　一郎　１／８",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続人と法定相続分",
        "important": ""
    },
    {
        "id": 445,
        "shikenId": 14,
        "no": 15,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "渡辺清高さん（３２歳）が本年中に贈与を受けた財産の価額と贈与者は以下のとおりである。清高さんの本年分の贈与税額として、正しいものはどれか。なお、本年中において、清高さんはこれ以外の財産の贈与を受けておらず、相続時精算課税制度は選択していないものとする。<br><img src='/img/14_15.png'/>",
        "question2": "",
        "choice1": "６８万円",
        "choice2": "９０万円",
        "choice3": "１２３万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "贈与税の計算",
        "important": ""
    },
    {
        "id": 446,
        "shikenId": 14,
        "no": 16,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "路線価方式による相続税評価額を算定する場合、下記＜資料＞の宅地の借地権（普通借地権）の金額として、正しいものはどれか。なお、奥行価格補正率は１.０とし、記載のない条件については一切考慮しないこととする。<br><img src='/img/14_16.png'/>",
        "question2": "",
        "choice1": "２１,６００千円",
        "choice2": "５０,４００千円",
        "choice3": "７２,０００千円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "借地権の計算",
        "important": ""
    },
    {
        "id": 447,
        "shikenId": 14,
        "no": 17,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "<img src='/img/14_17_1.png'/><br>ＦＰの山井さんは、加藤家のバランスシートを作成した。下表の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、＜設例＞に記載のあるデータに基づいて解答することとする。<br><img src='/img/14_17_2.png'/>",
        "question2": "",
        "choice1": "１,７１０（万円）",
        "choice2": "１,７９０（万円）",
        "choice3": "４,７９０（万円）",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "バランスシートの計算",
        "important": ""
    },
    {
        "id": 448,
        "shikenId": 14,
        "no": 18,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "加藤さんは、６０歳で定年となり、その後公的年金の支給が始まる６５歳までの５年間の生活資金は、退職一時金の一部を充てようと考えている。仮に退職一時金のうち６００万円を年利２.０％で複利運用しながら５年間で均等に取り崩すこととした場合、年間で取り崩すことができる最大金額として、正しいものはどれか。なお、下記＜資料＞の３つの係数の中から最も適切な係数を選択して計算し、円単位で解答すること。また、税金や記載のない事項については一切考慮しないこととする。<br><img src='/img/14_18.png'/>",
        "question2": "",
        "choice1": "５６５,８００円",
        "choice2": "１,１５２,９６０円",
        "choice3": "１,２７２,９６０円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "係数計算",
        "important": ""
    },
    {
        "id": 449,
        "shikenId": 14,
        "no": 19,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "加藤さんは、今後、高齢の親の介護が必要になった場合を考え、公的介護保険制度（以下、介護保険）について、ＦＰの山井さんに質問をした。山井さんが行った介護保険に関する次の記述のうち、最も適切なものはどれか。",
        "question2": "",
        "choice1": "介護保険は、６０歳以上の者を第１号被保険者、４０歳以上６０歳未満の公的医療保険加入者を第２号被保険者とする。",
        "choice2": "第１号被保険者の介護保険料は、公的年金の受給額が年額１８万円以上の場合にはその年金から天引きされ、第２号被保険者の介護保険料は、公的医療保険の保険料と同時に徴収される。",
        "choice3": "介護保険の給付を受けるためには、都道府県の認定を受ける必要があり、認定審査の判定結果は、『要介護１～５』『要支援１・２』『非該当』と区分され、要介護と認定されると居宅サービス、施設サービスのどちらも利用できる。",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "介護保険",
        "important": ""
    },
    {
        "id": 450,
        "shikenId": 14,
        "no": 20,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "加藤さん（４８歳）は、通常６５歳から支給される老齢基礎年金を繰り上げて受給できることを知り、ＦＰの山井さんに質問をした。老齢基礎年金の繰上げ受給に関する次の記述のうち、最も適切なものはどれか。なお、老齢基礎年金の受給要件は満たしているものとする。",
        "question2": "",
        "choice1": "老齢基礎年金を繰上げ受給した場合の年金額の減額は、一生涯続く。",
        "choice2": "老齢基礎年金を６０歳から繰上げ受給した場合でも、老齢厚生年金を同時に繰上げ受給する必要はない。",
        "choice3": "老齢基礎年金を繰上げ受給した場合の年金額は、繰上げ月数１月当たり０.７％の割合で減額される。",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "繰上げ支給",
        "important": ""
    },
    {
        "id": 451,
        "shikenId": 18,
        "no": 1,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "公表された他人の著作物を自分の著作物に引用する場合に気をつけるべき事項に関する次の記述のうち、最も不適切なものはどれか。",
        "question2": "",
        "choice1": "自らが作成する部分と引用する部分を区別して記述し、自らが作成する部分が「主」で、引用する部分が「従」となる内容にした。",
        "choice2": "引用する著作物のタイトルと著作者名を明記した。",
        "choice3": "民間の新聞社が発行する記事を自らが主催する無料のセミナーや勉強会で配付する場合であれば、新聞社の許諾を得る必要はない。",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "著作権",
        "important": ""
    },
    {
        "id": 452,
        "shikenId": 18,
        "no": 2,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "下記は、A家のキャッシュフロー表（一部抜粋）である。このキャッシュフロー表の空欄（ア）～（ウ）に入る数値とその求め方として、最も適切なものはどれか。なお、計算に当たっては、キャッシュフロー表中に記載の整数を使用し、計算結果は万円未満を四捨五入すること。<br><img src='/img/18_2.png'/>",
        "question2": "",
        "choice1": "空欄（ア）： 「１８２ × (１＋０.０１)<sup>2</sup> ≒１８６」",
        "choice2": "空欄（イ）： 「３８３－４６０＝▲７７」",
        "choice3": "空欄（ウ）： 「（３３５＋５４）×（１＋０.０１）≒３９３」",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "キャッシュフロー表の計算",
        "important": ""
    },
    {
        "id": 453,
        "shikenId": 18,
        "no": 3,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "下記＜資料＞に基づくＭＮ株式会社の投資指標に関する次の記述のうち、最も適切なものはどれか。なお、購入時の手数料および税金は考慮しないこととし、計算結果については表示単位の小数点以下第３位を四捨五入すること。<br><img src='/img/18_3.png'/>",
        "question2": "",
        "choice1": "株価純資産倍率（ＰＢＲ）は、０.８倍である。",
        "choice2": "配当性向は、３０％である。",
        "choice3": "配当利回りは、３.７５％である。",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "株式投資指標の計算",
        "important": ""
    },
    {
        "id": 454,
        "shikenId": 18,
        "no": 4,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "下記は、一般的な証券取引所に上場しているＥＴＦ（上場投資信託）とＪ－ＲＥＩＴ（上場不動産投資信託）および公募株式投資信託（非上場）の特徴についてまとめた表である。下表の空欄（ア）～（ウ）にあてはまる語句に関する次の記述のうち、最も適切なものはどれか。<br><img src='/img/18_4.png'/>",
        "question2": "",
        "choice1": "空欄（ア）に入る語句は、「信託銀行」である。",
        "choice2": "空欄（イ）に入る語句は、「基準価額」である。",
        "choice3": "空欄（ウ）に入る語句は、「証券取引所が定めた手数料率を一律に適用」である。",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "投資信託の比較",
        "important": "必要保障額の計算"
    },
    {
        "id": 455,
        "shikenId": 18,
        "no": 5,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "会社員の吉田さんは、ＦＰの清水さんに、新ＮＩＳＡの成長投資枠およびつみたて投資枠について質問をした。次の清水さんの回答のうち、最も不適切なものはどれか。",
        "question2": "",
        "choice1": "「新ＮＩＳＡの成長投資枠やつみたて投資枠で売却損が出た場合、特定口座で生じた売却益などと相殺することができません。」",
        "choice2": "「新ＮＩＳＡの成長投資枠は、上場株式を投資対象とすることができます。」",
        "choice3": "「新ＮＩＳＡの成長投資枠やつみたて投資枠は、個人向け国債を投資対象とすることができます。」",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "新NISA",
        "important": ""
    },
    {
        "id": 456,
        "shikenId": 18,
        "no": 6,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "不動産の取得・保有に係る税金について、下表の空欄（ア）～（ウ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。<br><img src='/img/18_6.png'/>",
        "question2": "",
        "choice1": "（ア）都道府県　　（イ）４月１日　　（ウ）取得価額",
        "choice2": "（ア）市町村　　　（イ）１月１日　　（ウ）固定資産税評価額",
        "choice3": "（ア）国　　（イ）１月１日　　（ウ）固定資産税評価額",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "取得と保有の税金",
        "important": ""
    },
    {
        "id": 457,
        "shikenId": 18,
        "no": 7,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "建築基準法に従い、下記＜資料＞の土地に建築物を建築する場合、その土地に対する建築物の建築面積の最高限度として、正しいものはどれか。なお、記載のない条件については一切考慮しないこととする。<br><img src='/img/18_7.png'/>",
        "question2": "",
        "choice1": "３００㎡",
        "choice2": "１,５００㎡",
        "choice3": "１,８００㎡",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "建築面積の計算",
        "important": ""
    },
    {
        "id": 458,
        "shikenId": 18,
        "no": 8,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "中田さんは、下記＜資料＞の新築分譲物件の購入を検討している。この物件の購入金額（消費税を含んだ金額）として、正しいものはどれか。なお、＜資料＞に記載されている金額は消費税を除いた金額であり、消費税率は１０％として計算すること。また、売買に係る諸経費については一切考慮しないこととする。<br><img src='/img/18_8.png'/>",
        "question2": "",
        "choice1": "５,７５０万円",
        "choice2": "５,８００万円",
        "choice3": "６,０５０万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産の消費税の計算",
        "important": ""
    },
    {
        "id": 459,
        "shikenId": 18,
        "no": 9,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "古賀秀紀さんが加入している生命保険（下記＜資料＞参照）の保障内容に関する次の記述の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、保険契約は有効に継続しているものとし、特約は自動更新されているものとする。また、秀紀さんはこれまでに＜資料＞の保険から保険金および給付金を一度も受け取っていないものとする。<br><img src='/img/18_9.png'/>",
        "question2": "",
        "choice1": "１,３００万円",
        "choice2": "１,６００万円",
        "choice3": "２,６００万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生命保険の証券分析",
        "important": ""
    },
    {
        "id": 460,
        "shikenId": 18,
        "no": 10,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "損害保険の保険種類と事故の内容について記述した下表１～３のうち、対応する保険で補償の対象となるものはどれか。なお、記載のない事項については一切考慮しないこととする。<br><img src='/img/18_10.png'/>",
        "question2": "",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "損害保険",
        "important": ""
    },
    {
        "id": 461,
        "shikenId": 18,
        "no": 11,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "２人とも会社員である瀬古さん夫妻が加入している生命保険は下表のとおりである。下表の契約Ａ～Ｃについて、保険金が支払われた場合の課税関係に関する次の記述のうち、最も適切なものはどれか。<br><img src='/img/18_11.png'/>",
        "question2": "",
        "choice1": "契約Ａについて、被保険者である妻が受け取った特定疾病保険金は一時所得として所得税の課税対象となる。",
        "choice2": "契約Ｂについて、子が受け取った死亡保険金は相続税の課税対象となる。",
        "choice3": "契約Ｃについて、夫が受け取った年金は雑所得として所得税の課税対象となる。",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "個人契約の生命保険の税務",
        "important": ""
    },
    {
        "id": 462,
        "shikenId": 18,
        "no": 12,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "会社員の内田純さんが本年中に支払った医療費等が下記＜資料＞のとおりである場合、純さんの本年分の所得税の確定申告における医療費控除の金額として、正しいものはどれか。なお、純さんの本年分の所得は給与所得６５０万円のみであり、支払った医療費等はすべて純さんおよび生計を一にする妻のために支払ったものである。また、医療費控除の金額が最も大きくなるよう計算することとし、「特定一般用医薬品等購入費を支払った場合の医療費控除の特例」は考慮しないこととする。<br><img src='/img/18_12.png'/>",
        "question2": "",
        "choice1": "１７３,０００円",
        "choice2": "２５３,０００円",
        "choice3": "２７３,０００円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "医療費控除の計算",
        "important": ""
    },
    {
        "id": 463,
        "shikenId": 18,
        "no": 13,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "菊地さんは、２２年前に購入し、現在居住している自宅の土地および建物を売却する予定である。売却に係る状況が下記＜資料＞のとおりである場合、所得税における課税長期譲渡所得の金額として、正しいものはどれか。<br><img src='/img/18_13.png'/>",
        "question2": "",
        "choice1": "２,２００万円",
        "choice2": "２,５００万円",
        "choice3": "２,８００万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "居住用財産の譲渡所得の計算",
        "important": ""
    },
    {
        "id": 464,
        "shikenId": 18,
        "no": 14,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "相続開始後の各種手続きにおいて、下記＜資料＞の空欄（ア）、（イ）にあてはまる語句の組み合わせとして、正しいものはどれか。なお、記載のない事項については一切考慮しないこととする。<br><img src='/img/18_14.png'/>",
        "question2": "",
        "choice1": "（ア）１ヵ月 （イ） ４ヵ月",
        "choice2": "（ア）３ヵ月 （イ） ４ヵ月",
        "choice3": "（ア）３ヵ月 （イ） １０ヵ月",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続手続きの期限",
        "important": ""
    },
    {
        "id": 465,
        "shikenId": 18,
        "no": 15,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "本年１０月２４日に相続が開始された高山大介さん（被相続人）の＜親族関係図＞が下記のとおりである場合、民法上の相続人および法定相続分の組み合わせとして、正しいものはどれか。なお、記載のない条件については一切考慮しないこととする。<br><img src='/img/18_15.png'/>",
        "question2": "",
        "choice1": "幸子　１／２　　一郎　１／２",
        "choice2": "幸子　１／２　　一郎　１／４　　健　１／８　　芳江　１／８",
        "choice3": "幸子　１／２　　一郎　１／６　　健　１／６　　芳江　１／６",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続人と法定相続分",
        "important": ""
    },
    {
        "id": 466,
        "shikenId": 18,
        "no": 16,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "香川新太郎さん（５５歳）は、夫婦間で居住用不動産の贈与、父（８０歳）からは相続時精算課税制度による贈与を考えている。居住用不動産の贈与については贈与税の配偶者控除、父からの贈与については相続時精算課税制度の活用について、ＦＰで税理士でもある徳島さんの回答の空欄（ア）～（オ）に当てはまる数値に関する記述として、最も不適切なものはどれか。<br><img src='/img/18_16.png'/>",
        "question2": "",
        "choice1": "（ア）と（オ）は「２０」が入る。",
        "choice2": "（ウ）と（エ）は「２，０００」が入る。",
        "choice3": "（イ）は「１１０」が入る。",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "贈与税の配偶者控除、相続時精算課税制度",
        "important": ""
    },
    {
        "id": 467,
        "shikenId": 18,
        "no": 17,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "<img src='/img/18_17_1.png'/><br>ＦＰの中村さんは、資金計画のとおりマンションを購入した後の福井家のバランスシートを試算してみた。下表の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、＜設例＞に記載のあるデータに基づいて解答することとする。<br><img src='/img/18_17_2.png'/>",
        "question2": "",
        "choice1": "８００（万円）",
        "choice2": "８５０（万円）",
        "choice3": "９００（万円）",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "バランスシートの計算",
        "important": ""
    },
    {
        "id": 468,
        "shikenId": 18,
        "no": 18,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "福井さんは、今後１５年間で積立貯蓄をして、長女の教育資金として５００万円を準備したいと考えている。積立期間中に年利２.０％で複利運用できるものとした場合、５００万円を準備するために必要な毎年の積立金額として、正しいものはどれか。なお、下記＜資料＞の３つの係数の中から最も適切な係数を選択して計算し、解答に当たっては、千円未満を切り上げること。また、税金や記載のない事項については一切考慮しないこととする。<br><img src='/img/18_18.png'/>",
        "question2": "",
        "choice1": "２８９,０００円",
        "choice2": "２９０,０００円",
        "choice3": "３９０,０００円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "係数計算",
        "important": ""
    },
    {
        "id": 469,
        "shikenId": 18,
        "no": 19,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "福井さんは、病気やケガで働けなくなった場合を考え、健康保険の傷病手当金についてＦＰの中村さんに質問をした。健康保険（全国健康保険協会管掌健康保険）の傷病手当金に関する中村さんの次の説明のうち、最も不適切なものはどれか。",
        "question2": "",
        "choice1": "「傷病手当金は、休業１日につき『直近の継続した被保険者期間１２カ月の標準報酬月額の平均額÷３０』の６０％相当額を受け取ることができます。」",
        "choice2": "「傷病手当金は、療養のため労務に服することができないことが支給の要件とされ、入院に限らず自宅療養であっても受け取ることができます。」",
        "choice3": "「傷病手当金は、療養のため連続して３日間休業した場合に、４日目以降の休業した日について受け取ることができます。」",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "傷病手当金",
        "important": ""
    },
    {
        "id": 470,
        "shikenId": 18,
        "no": 20,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "福井大介さんの公的年金加入歴は下記のとおりである。万一、大介さんが現時点（３６歳）で死亡した場合、大介さんの死亡時点において妻の千秋さんに支給される公的年金の遺族給付に関する次の記述のうち、最も適切なものはどれか。なお、大介さんは、入社時（２３歳）から死亡時まで厚生年金保険に加入しているものとし、遺族給付における生計維持要件は満たされているものとする。<br><img src='/img/18_20.png'/>",
        "question2": "",
        "choice1": "遺族厚生年金と寡婦年金が支給される。",
        "choice2": "遺族基礎年金と死亡一時金が支給される。",
        "choice3": "遺族基礎年金と遺族厚生年金が支給される。",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "遺族給付",
        "important": ""
    },
    {
        "id": 471,
        "shikenId": 8,
        "no": 1,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/471_1.png'/>",
        "question2": "はじめに、Ｍさんは、Ａさんが老齢基礎年金の受給を65歳から開始した場合の年金額を試算した。Ｍさんが試算した老齢基礎年金の年金額の計算式として、次のうち最も適切なものはどれか。なお、老齢基礎年金の年金額は、本年度価額に基づいて計算するものとする。",
        "choice1": "<img src='/img/471_2.png' style=' height: 45px;margin-top: -10px;'/>",
        "choice2": "<img src='/img/471_3.png' style=' height: 41px;margin-top: -10px'/>",
        "choice3": "<img src='/img/471_4.png' style=' height: 42px;margin-top: -11px;'/>",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 472,
        "shikenId": 8,
        "no": 2,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/471_1.png'/>",
        "question2": "次に、Ｍさんは、会社員の老齢厚生年金について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「Ａさんには、特別支給の老齢厚生年金は支給されず、原則として、65歳から老齢厚生年金を受給することになります」",
        "choice2": "「Ａさんは厚生年金保険の被保険者期間が20年以上ありますので、Ａさんが65歳から受給する老齢厚生年金の額には、加給年金額は加算されます」",
        "choice3": "「Ａさんが70歳０カ月で老齢厚生年金の繰下げ支給の申出をする場合、当該年金額の増額率は42％になります」",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 473,
        "shikenId": 8,
        "no": 3,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/471_1.png'/>",
        "question2": "最後に、Ｍさんは、確定拠出年金の個人型年金について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「Ａさんが個人型年金に加入する場合、最長 65 歳に達するまで加入できます」",
        "choice2": "「Ａさんは、国民年金基金と個人型年金の両方に加入することができ、掛金の限度額は合わせて月額68,000円が限度となります」",
        "choice3": "「通算加入者等期間が10年以上ある場合、60歳到達時に老齢給付金を受給できますが、Ａさんが現時点から加入する場合、60歳から受給することはできません」",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 474,
        "shikenId": 8,
        "no": 4,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/474_1.png'/>",
        "question2": "Ｍさんは、《設例》の米ドル建定期預金について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「Ｘ銀行の米ドル建定期預金の場合、Ａさんが満期時に受け取ることができる利息額は300米ドル（税引前）になります」",
        "choice2": "「Ａさんが預入時に円を米ドルに換える際に適用される為替レートは、１米ドル＝150.00円になります」",
        "choice3": "「ＴＴＭとＴＴＳ（ＴＴＢ）の差分は為替スプレッドと呼ばれるもので、取引金融機関による差異はありません」",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 475,
        "shikenId": 8,
        "no": 5,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/474_1.png'/>",
        "question2": "Ａさんが、《設例》の条件のとおり、10,000米ドルを外貨預金に預け入れ、満期時に円貨で受け取った場合における元利金の合計額として、次のうち最も適切なものはどれか。なお、計算にあたっては税金等を考慮せず、預入期間３カ月は0.25年として計算すること。",
        "choice1": "1,491,100円",
        "choice2": "1,541,475円",
        "choice3": "1,575,900円",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 476,
        "shikenId": 8,
        "no": 6,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/474_1.png'/>",
        "question2": "Ｍさんは、《設例》の米ドル建定期預金の課税関係について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「満期時に為替差益が生じた場合、当該金額は雑所得として総合課税の対象となります」",
        "choice2": "「満期時に為替差損が生じた場合、所得税の確定申告をすることにより、当該損失の金額をＡさんの役員給与として得た所得と損益通算することができます」",
        "choice3": "「Ａさんが受け取る利子は、利子所得として源泉分離課税の対象となり、20.315％相当額が源泉徴収等されます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 477,
        "shikenId": 8,
        "no": 7,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/477_1.png'/>",
        "question2": "Ａさんの本年分の所得税における総所得金額は、次のうちどれか。<br><img src='/img/477_2.png'/>",
        "choice1": "436 万円",
        "choice2": "461 万円",
        "choice3": "486 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 478,
        "shikenId": 8,
        "no": 8,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/477_1.png'/>",
        "question2": "Ａさんの本年分の所得税における所得控除に関する以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/478_1.png'/>",
        "choice1": "①　小規模企業共済等掛金控除　　②　26 万円　　　③　63 万円",
        "choice2": "①　社会保険料控除　　　　　　　②　38 万円　　　③　63 万円",
        "choice3": "①　小規模企業共済等掛金控除　　②　38 万円　　　③　38 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 479,
        "shikenId": 8,
        "no": 9,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/477_1.png'/>",
        "question2": "Ａさんの本年分の所得税の確定申告に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「Ａさんは、ふるさと納税に係る寄附金控除について、年末調整では適用を受けることができませんので、所得税の確定申告が必要となります」",
        "choice2": "「一時払養老保険の満期保険金に係る保険差益は、雑所得として総合課税の対象となります。Ａさんは所得税の確定申告をする義務が生じます」",
        "choice3": "「確定申告書は、原則として、所得が生じた年の翌年2月1日から３月15日までの間にＡさんの勤務地を所轄する税務署長に提出してください」",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 480,
        "shikenId": 8,
        "no": 10,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/480_1.png'/>",
        "question2": "甲土地に賃貸マンション（準耐火建築物）を建築する場合の①建蔽率の上限となる建築面積と②容積率の上限となる延べ面積の組合せとして、次のうち最も適切なものはどれか。",
        "choice1": "①　240㎡　　②　　960㎡",
        "choice2": "①　300㎡　　②　1 , 080㎡",
        "choice3": "①　300㎡　　②　1 , 200㎡",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 481,
        "shikenId": 8,
        "no": 11,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/480_1.png'/>",
        "question2": "甲土地の有効活用に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「自己建設方式は、Ａさんがマンション等の建築資金の調達や建築工事の発注、建物の管理・運営を自ら行う方式です。Ａさん自らが貸主となってマンションの賃貸を行うには、宅地建物取引業の免許が必要です」",
        "choice2": "「建設協力金方式とは、テナント入居を希望する事業者等が建設資金をＡさんに貸し付け、この資金を利用してＡさんは建設した店舗を賃貸する手法です。建設資金は、契約期間中に賃料と相殺する形で返済するのが一般的です」",
        "choice3": "「事業用定期借地権方式は、事業者が土地を契約で一定期間賃借し、事業者が土地上に事業用建物等を建築する方式です。Ａさんは、土地を手放さずに安定した地代収入を得ることができ、契約期間満了時には土地が更地で返還されます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 482,
        "shikenId": 8,
        "no": 12,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/480_1.png'/>",
        "question2": "甲土地の有効活用に関する以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/482_1.png'/>",
        "choice1": "①　貸宅地　　　　　②　80％　　　③　３分の１",
        "choice2": "①　貸宅地　　　　　②　50％　　　③　６分の１",
        "choice3": "①　貸家建付地　　　②　50％　　　③　６分の１",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 483,
        "shikenId": 8,
        "no": 13,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/483_1.png'/>",
        "question2": "生前贈与に関する以下の文章の空欄①～③に入る数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/483_2.png'/>",
        "choice1": "①　2,500　　　　②　20　　　　③　1,500",
        "choice2": "①　2,500　　　　②　20　　　　③　1,000",
        "choice3": "①　2,000　　　　②　10　　　　③　1,500",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 484,
        "shikenId": 8,
        "no": 14,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/483_1.png'/>",
        "question2": "現時点において、Ａさんの相続が開始した場合に関する次の記述のうち、最も不適切なものはどれか。なお、Ａさんの相続税の課税価格は5億円以下であるものとする。",
        "choice1": "「孫であるＥさん、Ｆさん、Ｇさん、ＨさんがＡさんからの遺贈により財産を取得した場合、相続税額の２割加算の対象となります」",
        "choice2": "「 『配偶者に対する相続税額の軽減』の適用を受けた場合、妻Ｂさんが相続により取得した財産の金額が、配偶者の法定相続分相当額と１億6,000万円とのいずれか多い金額までであれば、納付すべき相続税額は算出されません」",
        "choice3": "「本年中に孫であるＥさん、Ｆさん、Ｇさん、Ｈさんの教育資金のために、直系尊属から教育資金の一括贈与を受けた場合の贈与税の非課税の適用を受けた場合、その後Ａさんが死亡すると、非課税拠出額から教育資金支出額を控除した残額があるときは、当該残額は、原則として相続税の課税価格に加算されます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 485,
        "shikenId": 8,
        "no": 15,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/483_1.png'/>",
        "question2": "仮に、長男Ｃさんが暦年課税（各種非課税制度の適用はない）により、本年中にＡさんから現金700万円の贈与を受けた場合の贈与税額は、次のうちどれか。<br><img src='/img/485_1.png'/>",
        "choice1": "88万円",
        "choice2": "112 万円",
        "choice3": " 120 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 486,
        "shikenId": 9,
        "no": 1,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/486_1.png'/><br><img src='/img/486_2.png'/>",
        "question2": "はじめに、Ｍさんは、《設例》の＜Ａさん夫妻に関する資料＞に基づき、Ａさんおよび妻Ｂさんが老齢基礎年金の受給を65歳から開始した場合の年金額（本年度価額）を試算した。Ｍさんが試算した老齢基礎年金の年金額の計算式の組合せとして、次のうち最も適切なものはどれか。",
        "choice1": "<img src='/img/486_3.png' style=' height: 45px;margin-top: -10px;'/>",
        "choice2": "<img src='/img/486_4.png' style=' height: 41px;margin-top: -10px'/>",
        "choice3": "<img src='/img/486_5.png' style=' height: 42px;margin-top: -11px;'/>",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 487,
        "shikenId": 9,
        "no": 2,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/486_1.png'/><br><img src='/img/486_2.png'/>",
        "question2": "次に、Ｍさんは、老齢基礎年金の繰上げ支給および繰下げ支給について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/487_1.png'/>",
        "choice1": "①　24　　　②　するか否か選択できます　　　③　42",
        "choice2": "①　24　　　②　しなければなりません　　　　③　84",
        "choice3": "①　30　　　②　しなければなりません　　　　③　84",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 488,
        "shikenId": 9,
        "no": 3,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/486_1.png'/><br><img src='/img/486_2.png'/>",
        "question2": "最後に、Ｍさんは、Ａさんおよび妻Ｂさんが受給することができる公的年金制度からの老齢給付について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「Ａさんが65歳から受給することができる老齢厚生年金には、配偶者の加給年金額が加算されます」",
        "choice2": "「Ａさんが現在の勤務先で引き続き厚生年金保険の被保険者として65歳になるまで勤務した場合、65歳から支給される老齢厚生年金は、65歳到達時における厚生年金保険の被保険者記録を基に計算されます」",
        "choice3": "「Ａさんおよび妻Ｂさんは、いずれも特別支給の老齢厚生年金の支給はなく、原則として、65歳から老齢基礎年金および老齢厚生年金が支給されます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 489,
        "shikenId": 9,
        "no": 4,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 4,
        "question1": "<img src='/img/489_1.png'/>",
        "question2": "まず、Ｍさんは、生命保険の見直しを検討する前に、現時点の必要保障額を試算することにした。下記の＜算式＞および＜条件＞に基づき、Ａさんが現時点で死亡した場合の必要保障額は、次のうちどれか。なお、金額の前の「▲」は、マイナスであることを示している。<br><img src='/img/489_2.png'/>",
        "choice1": "▲ 200 万円",
        "choice2": "▲1,000万円",
        "choice3": "▲1,200万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 490,
        "shikenId": 9,
        "no": 5,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/489_1.png'/>",
        "question2": "次に、Ｍさんは、ＡさんがＸ社を退職した後の公的医療保険について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「Ａさんの退職後、健康保険の任意継続被保険者とならなかった場合、国民健康保険に加入します。Ａさんが国民健康保険に加入した場合、妻Ｂさんを国民健康保険の被扶養者とすることができます」",
        "choice2": "「Ａさんが退職後、国民健康保険に加入した場合、高額療養費の支給はありませんが、健康保険の任意継続被保険者には高額療養費の支給があります」",
        "choice3": "「Ａさんは、退職日の翌日から最長２年間、健康保険に任意継続被保険者として加入することができます。ただし、在職中とは異なり、保険料はＡさんが全額負担することになります」",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 491,
        "shikenId": 9,
        "no": 6,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/489_1.png'/>",
        "question2": "さらに、Ｍさんは、生命保険の見直しについてアドバイスした。ＭさんのＡさんに対するアドバイスとして、次のうち最も適切なものはどれか。",
        "choice1": "「厚生労働省の各種データによれば、入院日数が年々長くなる傾向があり、退院後の通院時の療養に係る費用負担も大きくなっていますので、医療保障を検討する場合は、入院中および退院後の通院に対する保障を充実させることが大切です」",
        "choice2": "「先進医療の治療を受けた場合、先進医療にかかる技術料は公的医療保険の対象外で全額自己負担となります。一部の先進医療については費用が高額となるケースもありますので、先進医療特約の付加をお勧めします」",
        "choice3": "「現在加入している生命保険を払済保険に変更した場合、変更時点の解約返戻金をもとに、終身保険に変更されます。死亡保険金額は減少しますが、現在付加されている入院特約は残り、月々の保険料負担は軽減されます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 492,
        "shikenId": 9,
        "no": 7,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/492_1.png'/>",
        "question2": "仮に、将来Ｘ社がＡさんに役員退職金5,000万円を支給した場合、Ａさんが受け取る役員退職金に係る退職所得の金額として、次のうち最も適切なものはどれか。なお、Ａさんの役員在任期間（勤続年数）を40年とし、これ以外に退職手当等の収入はなく、障害者になったことが退職の直接の原因ではないものとする。",
        "choice1": "1,100 万円",
        "choice2": "1,400 万円",
        "choice3": "2,800 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 493,
        "shikenId": 9,
        "no": 8,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/492_1.png'/>",
        "question2": "次に、Ｍさんは、《設例》の＜資料＞の終身保険について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「Ａさんの勇退時に、役員退職金の一部または全部として終身保険の契約者をＡさん、死亡保険金受取人をＡさんの相続人に名義を変更することで、Ａさんの個人の保険として継続することができます」",
        "choice2": "「Ｘ社が契約者貸付制度を利用し、契約者貸付金を受け取った場合や返済する場合、経理処理は必要ありません」",
        "choice3": "「この終身保険は、保険料払込期間中の解約返戻金額を抑えることで、低解約返戻金型ではない終身保険と比較して保険料が割安となっています」",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 494,
        "shikenId": 9,
        "no": 9,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/492_1.png'/>",
        "question2": "Ｍさんは、総合福祉団体定期保険の一般的な商品性について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/494_1.png'/>",
        "choice1": "①  ができます　　②  全額　　　③  契約者である法人",
        "choice2": "①  はできません　②  ２分の１　③  契約者である法人",
        "choice3": "①  はできません　②  全額　　　③  被保険者である従業員またはその遺族",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 495,
        "shikenId": 9,
        "no": 10,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/495_1.png'/><br><img src='/img/495_2.png'/>",
        "question2": "Ａさんの本年分の所得税における所得控除に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「Ａさんが支払っている長男Ｃさんの国民年金の保険料は、社会保険料控除の対象となりません」",
        "choice2": "「会社員であるＡさんは、勤務先の年末調整においてセルフメディケーション税制の適用を受けることができます」",
        "choice3": "「セルフメディケーション税制の適用を受ける場合、特定一般用医薬品等購入費の総額（保険金などで補てんされる金額を除く）が12,000円を超えるときに、その超える部分の金額（最高88,000円）を総所得金額等から控除することができます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 496,
        "shikenId": 9,
        "no": 11,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/495_1.png'/><br><img src='/img/495_2.png'/>",
        "question2": "Ａさんの本年分の所得税における総所得金額は、次のうちどれか。<br><img src='/img/496_1.png'/>",
        "choice1": "695 万円",
        "choice2": "715 万円",
        "choice3": "720 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 497,
        "shikenId": 9,
        "no": 12,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/495_1.png'/><br><img src='/img/495_2.png'/>",
        "question2": "Ａさんの本年分の所得税における所得控除に関する以下の文章の空欄①～ ③に入る数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/497_1.png'/>",
        "choice1": "①　26　　　②　38　　　③　48",
        "choice2": "①　38　　　②　63　　　③　58",
        "choice3": "①　38　　　②　58　　　③　63",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 498,
        "shikenId": 9,
        "no": 13,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/498_1.png'/>",
        "question2": "Ａさんの相続に関する以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/498_2.png'/>",
        "choice1": "①　８分の１　　②　6 , 000　　③　４",
        "choice2": "①　12 分の１　　②　6 , 000　　③　10",
        "choice3": "①　12 分の１　　②　5 , 400　　③　４",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 499,
        "shikenId": 9,
        "no": 14,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/498_1.png'/>",
        "question2": "Ａさんの相続に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "妻Ｂさんが『配偶者に対する相続税額の軽減』の適用を受けた場合、原則として、妻Ｂさんの相続税の課税価格が、相続税の課税価格の合計額に対する配偶者の法定相続分相当額と１億6,000万円とのいずれか多い金額までであれば、納付すべき相続税額は算出されません」",
        "choice2": "「長女Ｃさんが受け取った死亡保険金は、みなし相続財産として相続税の課税対象となりますが、死亡保険金の非課税金額の規定の適用を受けることで、相続税の課税価格に算入される金額は2,500万円となります」",
        "choice3": "「孫Ｆさん、孫Ｇさんおよび孫Ｈさんは、相続税額の２割加算の対象となります」",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 500,
        "shikenId": 9,
        "no": 15,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/498_1.png'/>",
        "question2": "Ａさんの相続に係る課税遺産総額（課税価格の合計額－遺産に係る基礎控 除額）が8,400 万円であった場合の相続税の総額は、次のうちどれか。<br><img src='/img/500_1.png'/>",
        "choice1": "1,070 万円",
        "choice2": "1,100 万円",
        "choice3": "1,820 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 501,
        "shikenId": 12,
        "no": 1,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/501_1.png'/>",
        "question2": "本年度現時点においてＡさんが死亡した場合、妻Ｂさんに支給される遺族基礎年金の年金額（本年度価額）は、次のうちどれか。",
        "choice1": "816,000円",
        "choice2": "816,000円＋234,800円＋234,800円",
        "choice3": "816,000円＋234,800円＋78,300円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 502,
        "shikenId": 12,
        "no": 2,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/501_1.png'/>",
        "question2": "Ｍさんは、本年度現時点においてＡさんが死亡した場合に妻Ｂさんに支給される遺族厚生年金の金額等について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/502_1.png'/>",
        "choice1": "①　３分の２　　　②　240 月　　　③　加給年金額",
        "choice2": "①　４分の３　　　②　300 月　　　③　中高齢寡婦加算",
        "choice3": "①　４分の３　　　②　240 月　　　③　加給年金額",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 503,
        "shikenId": 12,
        "no": 3,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/501_1.png'/>",
        "question2": "Ｍさんは、公的介護保険（以下、「介護保険」という）の保険給付について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「介護保険の被保険者は、65歳以上の第１号被保険者と40歳以上65歳未満の医療保険加入者である第２号被保険者に区分されます」",
        "choice2": "「第１号被保険者は、要介護状態となった原因が特定疾病であるか否かにかかわらず、介護給付を受けることができます」",
        "choice3": "「第２号被保険者が介護給付を受けた場合、実際にかかった費用（食費、居住費等を除く）の３割を自己負担する必要があります」",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 504,
        "shikenId": 12,
        "no": 4,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/504_1.png'/>",
        "question2": "まず、Ｍさんは、Ｘ社株式を売買する場合の留意点等について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「その他の取引や手数料等を考慮しない場合、仮に、特定口座（源泉徴収あり）でＸ社株式を株価1,500円で100株購入し、同年中に株価1,800円で全株を売却した場合、譲渡益３万円に対して20.315％が源泉徴収等されます」",
        "choice2": "「2025年2月27日（木）までに、Ｘ社株式を購入すれば、Ｘ社株式の次回の期末配当を受け取ることができます」",
        "choice3": "「証券取引所における株式の売買注文の方法のうち、成行注文は、指値注文よりも優先して売買が成立します」",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 505,
        "shikenId": 12,
        "no": 5,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/504_1.png'/>",
        "question2": "Ｍさんは、Ｙ社債に投資する場合の留意点等について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「通常は、ＢＢＢ（トリプルＢ）格相当以上の格付がついていれば、投資適格債とされます」",
        "choice2": "Ｙ社債を設例の条件で購入した場合の最終利回りは、2.45%（小数点第３位四捨五入）である。",
        "choice3": "「Ｙ社債の利子は、申告分離課税の対象となり、利子の支払時において所得税および復興特別所得税と住民税の合計で15.315％相当額が源泉徴収等されます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 506,
        "shikenId": 12,
        "no": 6,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/504_1.png'/>",
        "question2": "最後に、Ｍさんは、上場不動産投資信託（J-REIT）について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「上場不動産投資信託（J-REIT）は、新NISAの成長投資枠を利用して購入することができますが、新NISAのつみたて投資枠を利用して購入することはできません」",
        "choice2": "「上場不動産投資信託（J-REIT）の分配金は、不動産所得として課税の対象となります」",
        "choice3": "「上場不動産投資信託（J-REIT）は、投資家から集めた資金を不動産や不動産会社の株式に投資し、不動産の賃貸収入や売買益、不動産会社の株式への投資による配当金や売買益を投資家に分配する投資信託です」",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 507,
        "shikenId": 12,
        "no": 7,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/507_1.png'/>",
        "question2": "所得税における青色申告制度に関する以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/507_2.png'/>",
        "choice1": "①　55　　　②　10　　　③　雑損失",
        "choice2": "①　65　　　②　10　　　③　純損失",
        "choice3": "①　65　　　②　20　　　③　雑損失",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 508,
        "shikenId": 12,
        "no": 8,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/507_1.png'/>",
        "question2": "Ａさんの本年分の所得税の課税に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「Ａさんは公的年金等の収入金額の合計額が108万円であるため、公的年金等に係る雑所得の金額は算出されません」",
        "choice2": "「Ａさんが適用を受けることができる配偶者控除の金額は、38万円です」",
        "choice3": "「一時払養老保険の満期保険金に係る保険差益は、源泉分離課税の対象となります」",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 509,
        "shikenId": 12,
        "no": 9,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/507_1.png'/>",
        "question2": "Ａさんの本年分の所得税における総所得金額は、次のうちどれか。",
        "choice1": "525 万円",
        "choice2": "550 万円",
        "choice3": "575 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 510,
        "shikenId": 12,
        "no": 10,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/511_1.png'/>",
        "question2": "甲土地に耐火建築物を建築する場合の①建蔽率の上限となる建築面積と②容積率の上限となる延べ面積の組合せとして、次のうち最も適切なものはどれか。",
        "choice1": "①　500㎡　     ②　2 , 000㎡",
        "choice2": "①　500㎡　     ②　1 , 500㎡",
        "choice3": "①　450㎡　     ②　2 , 400㎡",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 511,
        "shikenId": 12,
        "no": 11,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/511_1.png'/>",
        "question2": "自宅（建物およびその敷地である甲土地）の譲渡に関する以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/512_1.png'/>",
        "choice1": "①　３年　 ②　 6 , 000 万円　 ③　10 年",
        "choice2": "①　３年　 ②　 １億円　 ③　10 年",
        "choice3": "①　５年　 ②　 １億円　 ③　５年",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 512,
        "shikenId": 12,
        "no": 12,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/511_1.png'/>",
        "question2": "甲土地の有効活用と宅地の評価に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「甲土地の前面道路（幅員６ｍ）の相続税路線価は１㎡当たりの価額が30万円、借地権割合が70％であることを示しています」",
        "choice2": "「Ａさんが甲土地に賃貸マンションを建築した場合、相続税の課税価格の計算上、甲土地は貸宅地として評価され、自用地価額×（１－借地権割合×借家権割合×賃貸割合）により計算されます」",
        "choice3": "「Ａさんが甲土地に賃貸マンションを建築するための資金を金融機関からの借入金で調達した場合、Ａさんの相続における相続税額の計算上、当該借入金の残高は債務控除の対象となります」",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 513,
        "shikenId": 12,
        "no": 13,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/513_1.png'/>",
        "question2": "Ａさんの相続等に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「遺言により妻Ｂさんおよび長女Ｃさんが相続財産の大半を取得した場合、長男Ｄさんの遺留分を侵害する可能性があります。仮に、遺留分を算定するための財産の価額が２億4,000万円である場合、長男Ｄさんの遺留分の金額は6,000万円となります」",
        "choice2": "「遺産分割を少しでも円滑に行うために、公正証書遺言の作成をお勧めします。公正証書遺言は証人２人以上の立会いのもと、遺言者が遺言の趣旨を公証人に口授し、公証人がこれを筆記して作成します」",
        "choice3": "「Ａさんは、自身が作成した自筆証書遺言を法務局（遺言書保管所）に預けることができます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 514,
        "shikenId": 12,
        "no": 14,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/513_1.png'/>",
        "question2": "仮に、Ａさんの相続が本年現時点で開始し、Ａさんの相続に係る課税遺産総額（課税価格の合計額－遺産に係る基礎控除額）が1億8,000万であった場合の相続税の総額は、次のうちどれか。<br><img src='/img/514_1.png'/>",
        "choice1": "3,300 万円",
        "choice2": "3,400 万円",
        "choice3": "5,500 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 515,
        "shikenId": 12,
        "no": 15,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/513_1.png'/>",
        "question2": "Ａさんの相続に係る小規模宅地等についての相続税の課税価格の計算の特例（以下、「本特例」という）に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「自宅の敷地と賃貸ビルの敷地について、本特例の適用を受けようとする場合、適用対象面積の調整はせず、それぞれの宅地等の適用対象の限度面積まで適用を受けることができます」",
        "choice2": "「妻Ｂさんが自宅の敷地を相続により取得し、相続税の申告期限までに自宅の敷地を売却した場合、当該敷地は特定居住用宅地等として本特例の適用を受けることができなくなります」",
        "choice3": "「妻Ｂさんが自宅の敷地を相続により取得し、特定居住用宅地等として本特例の適用を受けた場合、自宅350㎡のうち330㎡までの部分について80％の減額が受けられます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 516,
        "shikenId": 13,
        "no": 1,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 4,
        "question1": "<img src='/img/516_1.png'/><br><img src='/img/516_2.png'/>",
        "question2": "はじめに、Ｍさんは、Ａさんが本年度の現時点において死亡した場合に妻Ｂさんが受給することができる遺族基礎年金の年金額（本年度価額）を試算した。Ｍさんが試算した遺族基礎年金の年金額の計算式として、次のうち最も適切なものはどれか。",
        "choice1": "816,000円＋78,300円＋78,300円＋78,300円",
        "choice2": "816,000円＋234,800円＋78,300円＋78,300円",
        "choice3": "816,000円＋234,800円＋234,800円＋78,300円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 517,
        "shikenId": 13,
        "no": 2,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/516_1.png'/><br><img src='/img/516_2.png'/>",
        "question2": "次に、Ｍさんは、Ａさんが現時点において死亡した場合に妻Ｂさんに支給される遺族厚生年金について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「妻Ｂさんに支給される遺族厚生年金の額は、原則として、Ａさんの厚生年金保険の被保険者記録を基礎として計算した老齢厚生年金の報酬比例部分の額の２分の１に相当する額になります」",
        "choice2": "「二女Ｅさんの18歳到達年度の末日が終了し、妻Ｂさんの有する遺族基礎年金の受給権が消滅したときは、妻Ｂさんが受給する遺族厚生年金に中高齢寡婦加算が加算されます」",
        "choice3": "「妻Ｂさんに支給される遺族厚生年金の額は、その年金額の計算の基礎となる被保険者期間の月数が360月に満たないため、360月として計算した額になります」",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 518,
        "shikenId": 13,
        "no": 3,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/516_1.png'/><br><img src='/img/516_2.png'/>",
        "question2": "最後に、Ｍさんは、公的介護保険（以下、「介護保険」という）について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「介護保険の介護給付を受けようとする場合は、要介護者に該当することおよびその該当する要介護状態区分について、都道府県の認定を受ける必要があります」",
        "choice2": "「介護保険の被保険者は、65歳以上の第１号被保険者と40歳以上65歳未満の医療保険加入者である第２号被保険者に分けられます」",
        "choice3": "「介護保険の第２号被保険者については、要介護状態となった原因が、初老期における認知症や脳血管疾患などの特定疾病によって生じたものでなければ介護給付を受けられません」",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 519,
        "shikenId": 13,
        "no": 4,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/519_1.png'/>",
        "question2": "はじめに、Ｍさんは、生命保険の加入等についてアドバイスした。ＭさんのＡさんに対するアドバイスとして、次のうち最も適切なものはどれか。",
        "choice1": "「生命保険の申込みをした後、保険会社指定の医師による診査を受けた場合には、保険業法に定める保険契約の申込みの撤回等（クーリング・オフ）はできません」",
        "choice2": "「生命保険は、一般的に、契約の申込みまたは申込みの撤回等（クーリング・オフ）に関する事項を記載した書面の交付日のいずれか遅い日から起算して8日以内であれば、書面（電磁的記録を含む）または口頭により申込みの撤回等をすることができます」",
        "choice3": "「生命保険に加入する際、Ａさんの過去の傷病歴や現在の健康状態など、事実をありのままに正しく告知しなければなりません。なお、告知受領権は生命保険募集人が有していますので、当該募集人に対して、口頭で告知すれば正しく告知したことになります」",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 520,
        "shikenId": 13,
        "no": 5,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/519_1.png'/>",
        "question2": "次に、Ｍさんは、Ａさんが提案を受けた生命保険の保障内容等について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「先進医療特約では、契約日時点において厚生労働大臣により先進医療として定められたものであれば、療養を受けた時点において先進医療としての承認を取り消されたものであっても給付の対象となります」",
        "choice2": "「引受基準緩和型の医療保険は、一般に、他の契約条件が同一で、引受基準緩和型ではない通常の医療保険と比べて、保険料が高く設定されています」",
        "choice3": "「Ａさんが、がんに罹患し、三大疾病一時金特約から一時金を受け取った場合、当該一時金の額は一時所得として総合課税の対象となります」",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 521,
        "shikenId": 13,
        "no": 6,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/519_1.png'/>",
        "question2": "最後に、Ｍさんは、Ａさんが加入する健康保険の給付について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「Ａさんが医師の診察を受けた場合、外来・入院を問わず、医療費の一部負担割合は､70歳に達するまでは原則３割です」",
        "choice2": "「Ａさんが一医療機関の窓口で支払う同一月内の一部負担金が所得金額に応じた自己負担限度額を超える場合、所得区分に応じて高額療養費が支給されます」",
        "choice3": "「Ａさんが業務外の事由による負傷や疾病のために連続して３日間休業した場合、休業４日目以降の休業した日について、通算２年を限度に傷病手当金が支給されます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 522,
        "shikenId": 13,
        "no": 7,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/522_1.png'/>",
        "question2": "Ｍさんは、中小企業退職金共済制度（以下、「中退共」という）の特徴について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/522_2.png'/>",
        "choice1": "①　２分の１　　　②　１　　　③　法人を経由して従業員に",
        "choice2": "①　全額　　　　　②　１　　　③　従業員本人に直接",
        "choice3": "①　全額　　　　　②　２　　　③　法人を経由して従業員に",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 523,
        "shikenId": 13,
        "no": 8,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/522_1.png'/>",
        "question2": "Ｘ社が現在加入している《設例》の長期平準定期保険を下記＜条件＞にて解約した場合の経理処理（仕訳）として、次のうち最も適切なものはどれか。<br>＜条件＞<br>・Ｘ社が解約時までに支払った保険料の累計額は、6,000万円である。<br>・解約返戻金の額は、5,500万円である。<br>・配当等、上記以外の条件は考慮しないものとする。",
        "choice1": "<img src='/img/523_1.png' style=' height: 80px;'/>",
        "choice2": "<img src='/img/523_2.png' style=' height: 80px;'/>",
        "choice3": "<img src='/img/523_3.png' style=' height: 80px;'/>",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 524,
        "shikenId": 13,
        "no": 9,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/522_1.png'/>",
        "question2": "次にＭさんは、《設例》の長期平準定期保険について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「契約者貸付制度を利用すると、既払込保険料相当額を限度として資金を調達できます。なお、契約者貸付金には、保険会社所定の利息が発生します」",
        "choice2": "「Ｘ社が生命保険を解約した場合にＸ社が受け取る解約返戻金は、Ａさんに支給する役員退職金の原資として活用するほか、事業資金としても活用できます」",
        "choice3": "「現時点で当該生命保険を払済終身保険に変更する場合、契約は継続するため、経理処理は必要ありません」",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 525,
        "shikenId": 13,
        "no": 10,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/525_1.png'/><br><img src='/img/525_2.png'/>",
        "question2": "所得税における青色申告制度に関する以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/525_3.png'/>",
        "choice1": "①　譲渡所得　　　　②　10　　　③　３",
        "choice2": "①　不動産所得　　　②　10　　　③　３",
        "choice3": "①　不動産所得　　　②　55　　　③　５",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 526,
        "shikenId": 13,
        "no": 11,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/525_1.png'/><br><img src='/img/525_2.png'/>",
        "question2": "Ａさんの本年分の所得税における所得控除に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「長男Ｃさんの合計所得金額は48万円以下であるため、Ａさんは長男Ｃさんに係る扶養控除の適用を受けることができます。長男Ｃさんに係る扶養控除の額は63万円となります」",
        "choice2": "「Ａさんは長女Ｄさんに係る扶養控除の適用を受けることができます。長女Ｄさんに係る扶養控除の額は38万円となります」",
        "choice3": "「妻Ｂさんは青色事業専従者として給与の支払を受けていますが、妻Ｂさんの合計所得金額は48万円以下であるため、Ａさんは、配偶者控除の適用を受けることができます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 527,
        "shikenId": 13,
        "no": 12,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/525_1.png'/><br><img src='/img/525_2.png'/>",
        "question2": "甲土地の有効活用と宅地の評価に関する次の記述のうち、最も不適切なものはどれか。<br><img src='/img/527_1.png'/>",
        "choice1": "①　1.0　　　②　10　　　③　できません",
        "choice2": "①　0.7　　　②　10　　　③　できます",
        "choice3": "①　0.7　　　②　13　　　③　できません",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 528,
        "shikenId": 13,
        "no": 13,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/528_1.png'/>",
        "question2": "遺言に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「自筆証書遺言は、遺言者が、その遺言の全文、日付および氏名を自書し、これに押印して作成するものですが、自筆証書遺言に添付する財産目録については、パソコン等で作成することも認められています」",
        "choice2": "「公正証書遺言は、証人２人以上の立会いのもと、遺言者が遺言の趣旨を公証人に口授し、公証人がこれを筆記して作成するものです。相続開始後に円滑に手続を進めるために、妻Ｂさんと長女Ｃさんの２人を証人にすることをお勧めします」",
        "choice3": "「自筆証書遺言は、所定の手続により、法務局（遺言書保管所）に保管することができます。法務局（遺言書保管所）に保管された自筆証書遺言は、相続開始時、家庭裁判所での検認が不要となります」",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 529,
        "shikenId": 13,
        "no": 14,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/528_1.png'/>",
        "question2": "Ａさんの相続等に関する以下の文章の空欄①～③に入る数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/529_1.png'/>",
        "choice1": "①　3,000　　　②　4,500　　　③　1,000",
        "choice2": "①　6,000　　　②　4,500　　　③　4,000",
        "choice3": "①　3,000　　　②　1,500　　　③　4,000",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 530,
        "shikenId": 13,
        "no": 15,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/528_1.png'/>",
        "question2": "仮に、Ａさんの相続が本年現時点で開始し、Ａさんの相続に係る課税遺産総額（課税価格の合計額－遺産に係る基礎控除額）が２億4,000万円であった場合の相続税の総額は、次のうちどれか。<br><img src='/img/530_1.png'/>",
        "choice1": "5,100万円",
        "choice2": "5,300万円",
        "choice3": "8,100万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 531,
        "shikenId": 16,
        "no": 1,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/531_1.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、Ａさんが40歳でＸ社を退職した後の公的医療保険制度について説明した。Ｍさんが説明した以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/531_2.png'/>",
        "choice1": "①　14 日　　　②　１年　　　③　全額自己負担",
        "choice2": "①　20 日　　　②　２年　　　③　全額自己負担",
        "choice3": "①　14 日　　　②　２年　　　③　労使折半",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 532,
        "shikenId": 16,
        "no": 2,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/531_1.png'/>",
        "question2": "次に、Ｍさんは、Ａさんが退職後、老後の年金収入を増やすための各種制度について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「国民年金基金は、国民年金の第１号被保険者や任意加入被保険者が利用できる年金制度です。加入は口数制であり、１口目は２種類の終身年金（Ａ型・Ｂ型）のいずれかを選択します」",
        "choice2": "「付加保険料を納付することで、将来の年金を増やすことができます。仮に、Ａさんが付加保険料を240月納付し、65歳から老齢基礎年金を受給する場合は、年額96,000円の付加年金を受給することができます」",
        "choice3": "「Ａさんが確定拠出年金の個人型年金に加入する場合、国民年金基金と合わせて加入することができます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 533,
        "shikenId": 16,
        "no": 3,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/531_1.png'/>",
        "question2": "最後に、Ｍさんは、Ａさんに対して、Aさんの老齢給付について説明した。Mさんの説明に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「Ａさんが老齢基礎年金を受給するためには、原則として、受給資格期間が25年以上必要です」",
        "choice2": "「Ａさんが受給する老齢厚生年金は、総報酬月額相当額と基本月額との合計額が50万円（本年度の支給停止調整額）を超えると、超える部分の２分の１の額が支給停止となります」",
        "choice3": "「Ａさんが老齢厚生年金の繰下げ支給の申出をするには、老齢基礎年金も同時に繰下げ支給の申出を行わなければなりません」",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 534,
        "shikenId": 16,
        "no": 4,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/534_1.png'/>",
        "question2": "まず、Ｍさんは、Ｘ社株式の投資指標について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「＜Ｘ社に関する資料＞から算出されるＸ社のＰＥＲ（株価収益率）は、8倍となります｡一般に、ＰＥＲが高いほうが株価は割高、低いほうが株価は割安と判断されます」",
        "choice2": "「＜Ｘ社に関する資料＞から算出されるＸ社の配当利回りは20%となります。一般に、配当利回りが高い方が株主への利益の還元率が高いと判断されます」",
        "choice3": "「＜Ｘ社に関する資料＞から算出されるＸ社のＰＢＲ（株価純資産倍率）は、1.6倍となります｡一般に、ＰＢＲが高いほうが株価は割安、低いほうが株価は割高と判断されます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 535,
        "shikenId": 16,
        "no": 5,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/534_1.png'/>",
        "question2": "次に、Ｍさんは、Ｙ投資信託の費用について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「信託財産留保額は、投資信託の資産を売却する際に発生する手数料等を、投資信託を換金する投資家に負担してもらうことを目的として設定されているものですが、Ｙ投資信託ではかかりません」",
        "choice2": "「Ｙ投資信託のように購入時手数料を徴収しない投資信託は、一般に、ノーロードファンドと呼ばれ、新ＮＩＳＡのつみたて投資枠の対象となる公募株式投資信託の要件の１つとなっています」",
        "choice3": "「運用管理費用（信託報酬）は、投資信託を保有する投資家が間接的に負担する費用であり、日々差し引かれます。一般に、Ｙ投資信託のようなインデックス型投資信託は、アクティブ型投資信託よりも高めに設定される傾向があります」",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 536,
        "shikenId": 16,
        "no": 6,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/534_1.png'/>",
        "question2": "最後に、Ｍさんは、「新ＮＩＳＡのつみたて投資枠」についてアドバイスした。ＭさんのＡさんに対するアドバイスとして、次のうち最も不適切なものはどれか。",
        "choice1": "「新ＮＩＳＡのつみたて投資枠の年間投資上限額は120万円で、購入は定期かつ継続的な買付けを行う方法に限定されます」",
        "choice2": "「新ＮＩＳＡのつみたて投資枠を利用して購入した公募株式投資信託を解約した際に損失が生じた場合、その損失は、他の課税口座（特定口座など）の上場株式等の譲渡益と通算することができます」",
        "choice3": "「新ＮＩＳＡのつみたて投資枠の対象となる金融商品は、長期の積立・分散投資に適した一定の商品性を有する公募株式投資信託やＥＴＦとされ、上場株式や債券はつみたて投資枠の対象ではありません」",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 537,
        "shikenId": 16,
        "no": 7,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/537_1.png'/>",
        "question2": "Ａさんの本年分の所得税における総所得金額は、次のうちどれか。<br><img src='/img/537_2.png'/>",
        "choice1": "705 万円",
        "choice2": "725 万円",
        "choice3": "760 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 538,
        "shikenId": 16,
        "no": 8,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/537_1.png'/>",
        "question2": "Ａさんの本年分の所得税における所得控除に関する以下の文章の空欄①～ ③に入る数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/538_1.png'/><br><img src='/img/538_2.png'/>",
        "choice1": "①　   48　        ②　38　       ③　38",
        "choice2": "①　   38　        ②　13　       ③　38",
        "choice3": "①　 103　        ②　26　       ③　63",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 539,
        "shikenId": 16,
        "no": 9,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/537_1.png'/>",
        "question2": "Ａさんの本年分の所得税における医療費控除に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「Ａさんが本年中に支払った医療費の金額の合計額が20万円を超えていない場合、医療費控除額は算出されません」",
        "choice2": "「Ａさんは、本年中に支払った医療費の領収書を勤務先に提出することで、年末調整において医療費控除の適用を受けることができます」",
        "choice3": "「生命保険契約から支払われた入院給付金や健康保険から支給を受けた高額療養費がある場合は、支払った医療費の総額からそれらの金額を控除する必要があります」",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 540,
        "shikenId": 16,
        "no": 10,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 4,
        "question1": "<img src='/img/540_1.png'/>",
        "question2": "甲土地に賃貸マンション（耐火建築物）を建築する場合の①建蔽率の上限となる建築面積と②容積率の上限となる延べ面積の組合せとして、次のうち最も適切なものはどれか。",
        "choice1": "①　1,120㎡　　　②　4,800㎡",
        "choice2": "①　1,120㎡　　　②　3,840㎡",
        "choice3": "①　1,280㎡　　　②　3,840㎡",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 541,
        "shikenId": 16,
        "no": 11,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/540_1.png'/>",
        "question2": "「被相続人の居住用財産（空き家）に係る譲渡所得の特別控除の特例」（以下、「本特例」という）に関する以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/541_1.png'/>",
        "choice1": "①　1,000　　　②　1971（昭和 46 ）　            ③　１億",
        "choice2": "①　3,000　　　②　1981（昭和 56 ）　            ③　１億",
        "choice3": "①　3,000　　　②　1991（平成３）　             ③　１億 6,000 万",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 542,
        "shikenId": 16,
        "no": 12,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/540_1.png'/>",
        "question2": "甲土地の有効活用に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「建設協力金方式により、甲土地上に建築した店舗をテナントに貸し出す手法が考えられます。契約期間満了後、借主であるテナントが建物を撤去し、甲土地は更地で返還されます」",
        "choice2": "「事業用定期借地権方式により、甲土地を一定期間賃貸する手法が考えられます。甲土地を手放さず、資金も不要で安定した地代収入を得ることができます」",
        "choice3": "「等価交換方式により、マンションを建築する手法が考えられます。Ａさんとしては、自己資金を使わず、マンション住戸を取得することができます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 543,
        "shikenId": 16,
        "no": 13,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/543_1.png'/>",
        "question2": "Ａさんの相続に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「本年分の所得税および復興特別所得税についてＡさんが確定申告書を提出しなければならない場合に該当するとき、相続人は、原則として、相続の開始があったことを知った日の翌日から４カ月以内に準確定申告書を提出しなければなりません」",
        "choice2": "「自宅に保管されていたＡさんの自筆証書遺言を相続人が発見した場合、相続人は、遅滞なく、自筆証書遺言を法務局に提出して、その検認を請求しなければなりません」",
        "choice3": "「相続税の申告書は、原則として、相続の開始があったことを知った日の翌日から10カ月以内に被相続人であるＡさんの死亡時の住所地を所轄する税務署長に提出しなければなりません」",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 544,
        "shikenId": 16,
        "no": 14,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/543_1.png'/>",
        "question2": "Ａさんの相続に関する以下の文章の空欄①～③に入る数値の組合せとして、 次のうち最も適切なものはどれか。<br><img src='/img/544_1.png'/>",
        "choice1": "①　4,200　　　②　1,500　　　③　4,800",
        "choice2": "①　4,800　　　②　3,500　　　③　1,200",
        "choice3": "①　4,800　　　②　1,500　　　③　1,200",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 545,
        "shikenId": 16,
        "no": 15,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/543_1.png'/>",
        "question2": "Ａさんの相続に係る課税遺産総額（「課税価格の合計額－遺産に係る基礎控除額」）が２億4,000万円であった場合の相続税の総額は、次のうちどれか。<br><img src='/img/545_1.png' style='width:500px'/>",
        "choice1": "5,100万円",
        "choice2": "5,300万円",
        "choice3": "8,100万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 546,
        "shikenId": 17,
        "no": 1,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/546_1.png'/>",
        "question2": "まず、Ｍさんは、国民年金の学生納付特例制度（以下、「本制度」という）について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/546_2.png'/>",
        "choice1": "①世帯主および学生本人　②受給資格期間に算入　③10",
        "choice2": "①学生　　②受給資格期間に算入　　③10",
        "choice3": "①世帯主　　②年金額に反映　　③２",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 547,
        "shikenId": 17,
        "no": 2,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 4,
        "question1": "<img src='/img/546_1.png'/>",
        "question2": "次に、Ｍさんは、老後の年金収入を増やすための各種方法について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/547_1.png'/>",
        "choice1": "①　70,000円　　　②　48,000円　　　③　一時所得",
        "choice2": "①　68,000円　　　②　48,000円　　　③　退職所得",
        "choice3": "①　68,000円　　　②　24,000円　　　③　退職所得",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 548,
        "shikenId": 17,
        "no": 3,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/546_1.png'/>",
        "question2": "最後に、Ｍさんは、確定拠出年金の個人型年金（以下、「個人型年金」という）について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「個人型年金のメリットとして、税制の優遇措置が挙げられます。加入者が拠出する掛金は、その全額が個人年金保険料控除として所得控除の対象となります」",
        "choice2": "「Ａさんのような国民年金第１号被保険者は個人型年金に年額276,000円まで掛金を支払うことができます」",
        "choice3": "「個人型年金の留意点として、加入時や運用期間中に各種の手数料がかかることや、年金資産の運用リスクは加入者が負うことなどが挙げられます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 549,
        "shikenId": 17,
        "no": 4,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/549_1.png'/><br><img src='/img/549_2.png'/>",
        "question2": "はじめに、Ｍさんは、必要保障額の考え方について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「Ａさんの必要保障額の計算上、住宅ローンの残債務は遺族に必要な生活資金等の支出の総額に含める必要があります」",
        "choice2": "「Ａさんのような会社員と個人事業主とでは、遺族が受け取る公的年金等の総額や、死亡退職金の有無など、必要保障額を計算する際の条件が異なります。他の条件が同じ場合、一般に、会社員よりも個人事業主のほうが、遺族年金の総額が少ないケースが多くなっています」",
        "choice3": "「必要保障額は、通常、末子誕生時が最大となり、その後、子どもの成長とともに逓減していきますので、期間の経過に伴って年金受取総額が逓減していく収入保障保険で死亡保障を準備することも１つの方法です」",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 550,
        "shikenId": 17,
        "no": 5,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/549_1.png'/><br><img src='/img/549_2.png'/>",
        "question2": "次に、Ｍさんは、生命保険の見直しについて説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「提案を受けた終身介護保障保険の保険料払込期間を有期払込にすることで、毎月の保険料負担は減少し、保険料の払込総額も少なくなります。月々の保険料負担を軽減するために有期払込を選択することをお勧めします」",
        "choice2": "「契約転換制度を活用して現在加入している定期保険特約付終身保険を転換し、介護保障を準備することもできます。転換後の保険料は転換時点の年齢・保険料率で計算されますが、転換することで、新規に加入する場合と比較し、保険料負担を抑えることができます」",
        "choice3": "「契約転換制度利用時には、告知や医師の診査等が不要のため、健康状態にかかわらず、保障内容を見直すことができます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 551,
        "shikenId": 17,
        "no": 6,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/549_1.png'/><br><img src='/img/549_2.png'/>",
        "question2": "最後に、Ｍさんは、Ａさんが提案を受けた生命保険の課税関係について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「提案を受けた生命保険の支払保険料は、生命保険料控除の一つである介護医療保険料控除の対象となります。介護医療保険料控除の控除限度額は、所得税で40,000円、住民税で28,000円です」",
        "choice2": "「Ａさんが介護一時金を請求できない特別な事情がある場合、指定代理請求人である妻ＢさんがＡさんに代わって請求することができます。妻Ｂさんが受け取る当該一時金は、一時所得として総合課税の対象となります」",
        "choice3": "「Ａさんが当該生命保険から介護終身年金を受け取った場合、Ａさんは所得税の確定申告をしなければなりません」",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 552,
        "shikenId": 17,
        "no": 7,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/552_1.png'/>",
        "question2": "仮に、将来Ｘ社がＡさんに役員退職金4,000万円を支給した場合、Ａさんが受け取る役員退職金に係る退職所得の金額として、次のうち最も適切なものはどれか。なお、Ａさんの役員在任期間（勤続年数）を36年とし、これ以外に退職手当等の収入はなく、障害者になったことが退職の直接の原因ではないものとする。",
        "choice1": "740万円",
        "choice2": "1,040万円",
        "choice3": "1,920万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 553,
        "shikenId": 17,
        "no": 8,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 4,
        "question1": "<img src='/img/552_1.png'/>",
        "question2": "Ｍさんは＜資料１＞の生命保険について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「当該生命保険の第１回保険料払込時の支払保険料90万円のうち、前払保険料36万円を資産に計上し、残りの54万円は定期保険料として損金の額に算入することができます」",
        "choice2": "「当該生命保険から死亡保険金をＸ社が受け取った場合、資産計上額との差額を雑収入として益金の額に算入します」",
        "choice3": "「当該生命保険の単純返戻率（解約返戻金額÷払込保険料累計額）は、保険始期から徐々に上昇し、保険期間満了直前にピークを迎え、保険期間終了時には急激に減少し、ゼロとなります」",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 554,
        "shikenId": 17,
        "no": 9,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/552_1.png'/>",
        "question2": "Ｍさんは＜資料２＞の医療保険について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「Ａさんが入院し、Ｘ社が受け取った入院給付金は、その全額が非課税となります」",
        "choice2": "「Ａさんが入院中に公的医療保険制度の手術料の算定対象となる所定の手術を受けた場合には20万円、所定の外来手術を受けた場合には５万円の手術給付金が支払われます」",
        "choice3": "「当該生命保険の支払保険料は、その全額を損金に算入することができます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 555,
        "shikenId": 17,
        "no": 10,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/555_1.png'/>",
        "question2": "Ａさんの本年分の所得税における総所得金額は、次のうちどれか。<br><img src='/img/555_2.png'/>",
        "choice1": "550 万円",
        "choice2": "575 万円",
        "choice3": "580 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 556,
        "shikenId": 17,
        "no": 11,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/555_1.png'/>",
        "question2": "Ａさんの本年分の所得税における所得控除の控除額に関する以下の文章の 空欄①～③に入る数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/556_1.png'/>",
        "choice1": "①　48　　　②　38　　　③　48",
        "choice2": "①　48　　　②　48　　　③　38",
        "choice3": "①　38　　　②　38　　　③　48",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 557,
        "shikenId": 17,
        "no": 12,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/555_1.png'/>",
        "question2": "Ａさんの本年分の所得税の課税等に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「総所得金額に算入される一時所得の金額が20万円を超えるため、Ａさんは所得税の確定申告が必要です」",
        "choice2": "「Ａさんは、所得税の確定申告をすると、ふるさと納税で寄附した12万円の全額について、本年分の所得税額から控除されます」",
        "choice3": "「Ａさんが生命保険料控除として総所得金額から控除することができる金額は５万円です」",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 558,
        "shikenId": 17,
        "no": 13,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/558_1.png'/>",
        "question2": "本年中の生前贈与に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「Ａさんが長女Ｃさんに現金を贈与し、長女Ｃさんが暦年課税を選択した場合、その年にＡさんから長女Ｃさんへ贈与した財産の価額が贈与税の基礎控除額を超えるときは、贈与したＡさんが贈与税の申告書を提出しなければなりません」",
        "choice2": "「Ａさんが長女Ｃさんに現金を贈与し、長女Ｃさんが相続時精算課税制度を選択した場合、その選択をした年分以降にＡさんから長女Ｃさんへ贈与する財産について、暦年課税へ変更することはできません」",
        "choice3": "「Ａさんが長女Ｃさんに現金を贈与し、長女Ｃさんが相続時精算課税制度を選択した場合、特別控除額として累計で2,000万円までの贈与について贈与税は課されません」",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 559,
        "shikenId": 17,
        "no": 14,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/558_1.png'/>",
        "question2": "Ａさんの相続等に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「仮にＡさんに相続が発生し、Ａさんの自宅（実家）を相続により取得した長女Ｃさんまたは長男Ｄさんが、『被相続人の居住用財産（空き家）に係る譲渡所得の特別控除の特例』の適用を受けて、その財産を譲渡した場合、最高1,000万円の特別控除の適用を受けることができます」",
        "choice2": "「契約者（＝保険料負担者）および被保険者をＡさん、死亡保険金受取人を推定相続人とする終身保険に加入することをお勧めします。死亡保険金受取人が受け取る死亡保険金は、『500万円×法定相続人の数』を限度として、死亡保険金の非課税金額の規定の適用を受けることができます」",
        "choice3": "「遺産分割を円滑に行うために遺言書の作成をお勧めします。自筆証書遺言は、その遺言の全文および財産目録をパソコンで作成し、日付および氏名を自書して押印することで作成することができます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 560,
        "shikenId": 17,
        "no": 15,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/558_1.png'/>",
        "question2": "仮に、長男Ｄさんが暦年課税（各種非課税制度の適用はない）により、本年中にＡさんから現金500万円の贈与を受けた場合の贈与税額は、次のうちどれか。<br><img src='/img/560_1.png'/>",
        "choice1": "48.5 万円",
        "choice2": "68.5 万円",
        "choice3": "70 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    }
]